.addDateWrapper {
  position: relative;

  :global {
    .ant-picker-content td,
    .ant-picker-content th {
      text-align: center;
    }

    .ant-picker-content tr:nth-child(odd) {
      background: none;
    }
  }
}

.datePicker {
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
  border: none !important;
  box-shadow: none !important;

  :global {
    .ant-picker-suffix {
      visibility: hidden;
    }
  }

  input {
    min-width: unset;
    width: 0;
  }
}
