.table-row-field {
  margin-top: 40px;
  position: relative;
  &__title {
    font-weight: 600;
    line-height: 1.25em;
    color: #666b79;
    &--bold {
      font-weight: bold;
      font-size: 24px;
      line-height: 2em;
      color: #333547;
    }
  }
  &__details {
    font-size: 16px;
    line-height: 1.75em;
    color: #13162e;
    &--header {
      font-size: 18px;
      color: #888a95;
    }
  }
  &__action {
    color: #f65731;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 1em);
    right: 0px;
    &:hover {
      opacity: 0.7;
    }
  }
}
