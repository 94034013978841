.sp-radio-group {
  & .ant-radio-wrapper {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: $dark-gray;
    & .ant-radio-inner {
      width: 22px;
      height: 22px;
      border-color: $light-gray;
      &::after {
        width: 14px;
        height: 14px;
        background-color: $orange;
      }
    }
    & .ant-radio-disabled {
      cursor: unset;
      & .ant-radio-inner:after {
        background-color: $light-gray;
      }
    }
    & .ant-radio-checked {
      & .ant-radio-inner {
        border-color: $orange;
      }
    }
  }
  & .ant-radio-wrapper-disabled {
    cursor: unset;
  }
}
