.recipientsTableLoader {
  margin-top: 1rem;

  .shimmer {
    li {
      border-radius: 0.25rem;
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }

  :global {
    .data-table-content {
      margin-top: 0.625rem;
    }

    .ant-table-tbody > tr:hover {
      th,
      td {
        cursor: auto;
      }

      td {
        background-color: initial !important;
      }
    }
  }
}
