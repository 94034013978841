@import "src/assets/scss/variables";

.flow-canvas {
  display: flex;
  position: relative;
  align-items: flex-start;

  .flow-canvas__add-step-btn {
    height: auto;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    color: $orange;
    background: $peach;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    border: none;
    &:disabled {
      background: $light-gray;
      color: $white;
    }
  }
}

.flow-canvas__delete-notif {
  display: flex;
  align-items: center;
  gap: 16px;
  &.clicked {
    display: none;
  }
  .flow-canvas__delete-notif__undo-btn {
    border: 1px solid $black;
    border-radius: 16px;
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
