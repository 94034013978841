@import "src/assets/v1.1/scss/spectrum";

@mixin chip-container {
  column-gap: 0.5rem;
  padding: 0.125rem 0.75rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;

  white-space: nowrap;

  display: inline-flex;
  align-content: center;
  align-items: center;

  border-style: solid;
  border-radius: 1rem;
  border-width: 0.063rem;

  cursor: default;
}

@mixin failed {
  color: $red-80;
  background: $red-20;
  border-color: $red-40;
}

@mixin attention {
  color: $yellow;
  background: $yellow-20;
  border-color: $yellow-40;
}

@mixin success {
  color: $green;
  background: $green-20;
  border-color: $green-40;
}

@mixin info {
  color: $blue;
  background: $blue-20;
  border-color: $blue-40;
}

@mixin neutral {
  color: $black-90;
  background: $white;
  border-color: $black-20;
}

@mixin strong {
  color: $blue-10;
  background: $blue-80;
}
