@import "src/assets/v1.1/scss/spectrum";

.country-select,
.country-select__dropdown {
  background: $white;
  .country-select__option {
    display: flex;
    .country-select__flag {
      width: 18px;
      margin-right: 8px;
      img {
        box-shadow: 0px 0px 2px 0px $opaque-shadow;
      }
    }
  }
  .ant-select-item-group {
    background-color: $blue-10;
    font-size: 0.75rem;
    color: $black-70;
  }
  .ant-empty-description {
    color: $black-90;
    font-size: 0.875rem;
  }
}
