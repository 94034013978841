.selected-avatar {
  width: fit-content;
  .letter-avatar {
    margin-top: 10px;
  }
  &--icon {
    position: relative;
    width: fit-content;
    .close {
      position: absolute;
      left: 18px;
      bottom: 25px;
      padding: 0 3px;
      cursor: pointer;
    }
  }
  &--name {
    font-size: 12px;
    line-height: 20px;
    color: #9599a4;
    text-align: center;
  }
}
