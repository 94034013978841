@import "src/assets/v1.1/scss/spectrum.scss";

.team-create {
  &-wrapper {
    padding: 48px 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__interval {
      float: left;
      padding: 15px 15px;
      border-radius: 12px;
      color: #131a2e;
      cursor: pointer;
      border: none;
    }

    .active {
      background-color: #e6f4ff;
      color: #1e97ee;
    }

    h1 {
      font-weight: bold;
      font-size: 28px;
      line-height: 2.125em;
      color: #000000;
    }

    h2 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }

    &__title-area {
      h1 {
        margin-top: 30px;
      }
    }

    &-name-field {
      display: flex;
      align-items: center;

      .team-profile-save-btn {
        margin-left: 30px;
      }
    }

    .team-name__input {
      min-width: 300px;
      margin-top: 16px;
      border: none;
    }

    .team-limit__input {
      width: 320px;
      height: 55px;
      border: none;
    }

    &__name {
      display: flex;
      margin-bottom: 60px;
      margin-top: 15px;
    }

    &__name-text-field {
      margin-left: 40px;
      margin-top: 10px;

      > div {
        margin-bottom: 0;
      }
    }

    .ant-tabs-nav {
      color: #c5c7cd;

      .ant-tabs-tab-active {
        color: #f65731;
      }
    }

    &--back-teams {
      font-weight: 600;
      color: #f65731;
      background-color: transparent;
      text-align: left;
      padding: 0;

      &:hover {
        color: #40a9ff;
      }
    }

    .create-form-container {
      display: flex;
    }

    .interval-container {
      width: 190px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }

    .create-form-card {
      width: 550px;
      height: 490px;
      background-color: #f8faff;
      border-radius: 10px;
      padding: 25px;
    }

    .manager-select-card {
      overflow-y: hidden;
      margin-left: 90px;
    }

    .create-team-btn {
      align-self: flex-end;
      margin-top: 40px;
      margin-right: var(--spacing-032);
    }

    .info-text {
      margin-top: 10px;
      color: #333744;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.24px;
    }

    .info-text-footer {
      color: #333744;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: -0.24px;
      margin-top: 12px;
    }

    .currency-section {
      display: inline-flex;
      margin-top: 16px;
    }

    .currency-label {
      width: 100px;
      height: 55px;
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 6px;
      margin-right: 10px;
      display: flex;
      padding: 15px;
      align-items: center;
    }

    .letter-avatar--big-square {
      width: 100px;
      height: 100px;
    }

    .header-text {
      color: #131a2e;
      margin-left: 25px;
      font-weight: 700;
      font-size: 1rem;
    }

    .manager-select-card .header-text {
      margin-top: 25px;
    }

    .header-sub-text {
      font-size: 0.875rem;
      margin: 0.313rem 0 0 1.563rem;
      font-weight: 200;
      line-height: 1.25rem;
      color: $black-80;
    }

    .select-members {
      padding-top: 0px;

      &__members {
        max-height: 300px;
      }
    }

    .select-members .select-members__search .search {
      max-width: none;
      background: #ffffff;
    }

    .select-members .select-members__search .search .search__input-field {
      background: #ffffff;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  &-checkbox-wrapper {
    width: 48%;
    margin-top: var(--spacing-024);
    margin-left: auto;

    label {
      align-items: flex-start;
    }
  }

  &-checkbox-label {
    color: var(--text-caption-default);
    width: 44%;
  }

  &-link {
    color: var(--text-link-default);
  }
}
