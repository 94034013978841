// @import "src/assets/scss/fonts.scss";
@import "src/Splice/scss/variables";

@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf woff woff2) {
  $src: null;
  $formats: (
    ttf: "truetype",
    woff: "woff",
    "woff2": "woff2",
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote("./" + $path + "." + $ext)) format(quote($format)), comma);
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

/* Font Montserrat */
@include font-face($font-family-montserrat, Montserrat/unquote($montserrat-light), $font-weight-light, normal, ttf);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-light-italic),
  $font-weight-light,
  italic,
  ttf
);
@include font-face($font-family-montserrat, Montserrat/unquote($montserrat-regular), $font-weight-regular, normal, ttf);
@include font-face($font-family-montserrat, Montserrat/unquote($montserrat-italic), $font-weight-regular, italic, ttf);
@include font-face($font-family-montserrat, Montserrat/unquote($montserrat-medium), $font-weight-medium, normal, ttf);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-medium-italic),
  $font-weight-medium,
  italic,
  ttf
);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-semi-bold),
  $font-weight-semi-bold,
  normal,
  ttf
);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-semi-bold-italic),
  $font-weight-semi-bold,
  italic,
  ttf
);
@include font-face($font-family-montserrat, Montserrat/unquote($montserrat-bold), $font-weight-bold, normal, ttf);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-bold-italic),
  $font-weight-bold,
  italic,
  ttf
);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-extra-bold),
  $font-weight-extra-bold,
  normal,
  ttf
);
@include font-face(
  $font-family-montserrat,
  Montserrat/unquote($montserrat-extra-bold-italic),
  $font-weight-extra-bold,
  italic,
  ttf
);

/* Font Noto Sans */

@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-light), $font-weight-light, normal, ttf);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-light-italic), $font-weight-light, italic, ttf);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-regular), $font-weight-regular, normal, ttf);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-italic), $font-weight-regular, italic, ttf);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-medium), $font-weight-medium, normal, ttf);
@include font-face(
  $font-family-noto-sans,
  NotoSans/unquote($noto-sans-medium-italic),
  $font-weight-medium,
  italic,
  ttf
);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-semi-bold), $font-weight-semi-bold, normal, ttf);
@include font-face(
  $font-family-noto-sans,
  NotoSans/unquote($noto-sans-semi-bold-italic),
  $font-weight-semi-bold,
  italic,
  ttf
);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-bold), $font-weight-bold, normal, ttf);
@include font-face($font-family-noto-sans, NotoSans/unquote($noto-sans-bold-italic), $font-weight-bold, italic, ttf);
@include font-face(
  $font-family-noto-sans,
  NotoSans/unquote($noto-sans-extra-bold),
  $font-weight-extra-bold,
  normal,
  ttf
);
@include font-face(
  $font-family-noto-sans,
  NotoSans/unquote($noto-sans-extra-bold-italic),
  $font-weight-extra-bold,
  italic,
  ttf
);

/* Font Noto Sans JP */
@include font-face($font-family-noto-sans-jp, NotoSansJP/unquote($noto-sans-jp-light), $font-weight-light, normal, otf);
@include font-face(
  $font-family-noto-sans-jp,
  NotoSansJP/unquote($noto-sans-jp-regular),
  $font-weight-regular,
  normal,
  otf
);
@include font-face($font-family-noto-sans-jp, NotoSansJP/unquote($noto-sans-jp-thin), $font-weight-thin, normal, otf);
@include font-face(
  $font-family-noto-sans-jp,
  NotoSansJP/unquote($noto-sans-jp-medium),
  $font-weight-medium,
  normal,
  otf
);
@include font-face($font-family-noto-sans-jp, NotoSansJP/unquote($noto-sans-jp-bold), $font-weight-bold, normal, otf);
@include font-face($font-family-noto-sans-jp, NotoSansJP/unquote($noto-sans-jp-black), $font-weight-black, normal, otf);
