@import "../../../assets/scss/variables.scss";

.change-phone-number {
  text-align: left;
  & > .button {
    color: $grey;
    padding-bottom: 60px;
    & > .button__icon {
      padding-top: 4px;
    }
  }
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .change-phone-number-btn {
    text-align: center;
    padding: 38px 0 40px 0;
    .button {
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      padding: 7px 35px;
    }
  }
  .otp-input-error {
    text-align: center;
  }
}

.change-phone-number_form--container {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  .phone-code {
    .ant-select {
      max-width: 108px;
      width: unset;
      background: $white;
    }
  }
  .phone-number {
    min-width: 215px;
  }
}
