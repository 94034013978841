@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

// Fix width overflow issue
.ant-notification {
  width: initial;
}

.ant-notification-bottomLeft {
  // Not available on splice to match spacing with the new sidebar
  left: 9rem !important;
}

.app-notification {
  display: flex;
  column-gap: 0.75rem;
  padding: 0.6875rem 0.9375rem;
  border: 1px solid;
  box-shadow: 0px 0px 10px 4px rgba(51, 55, 68, 0.08);
  border-radius: 8px;
  width: 50vw;
  text-align: left;

  .ant-notification-notice-with-icon {
    display: flex;
    column-gap: 0.75rem;
    height: 100%;
  }

  .ant-notification-notice-icon {
    position: static;
    margin-left: 0;
    padding-top: 4px;
  }

  .ant-notification-notice-message {
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0;
    align-self: center;
    flex: 1 1;
    margin-left: 0;
  }

  .ant-notification-notice-description {
    display: none;
  }

  .ant-notification-notice-content {
    flex: 1 1;
    word-break: break-word;
  }

  .notification-content {
    display: flex;
    column-gap: 0.75rem;
    align-items: center;
    min-height: 2rem;
  }

  .notification-text {
    flex: 1 1;
    white-space: pre-wrap;
  }

  .notification-cta {
    min-width: 4rem;
    margin-right: 0.25rem;
    border: 2px solid;
    border-radius: 16px;
    padding: 0.1875rem 0.875rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    background-color: transparent;
  }

  .ant-notification-notice-close {
    display: block;
    position: static;
    align-self: center;
  }

  .notification-close-icon {
    height: 32px;
    padding: 4px 0;
    vertical-align: middle;
    padding-left: 15px;
    border-left: 1px solid;
  }

  &--finite-width {
    width: 500px !important;
    min-height: 64px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    left: 120px;

    .ant-notification-notice-close {
      top: 22px;
    }
  }

  &-success {
    background-color: $green-20;
    border-color: $green-40;

    .ant-notification-notice-message {
      color: $green;
    }

    .notification-cta {
      color: $green;
      border-color: $green;
    }

    .notification-icon {
      color: $green-80;
    }

    .notification-close-icon {
      color: $green;
      border-color: $green-40;
    }
  }

  &-error {
    background-color: $red-20;
    border-color: $red-40;

    .ant-notification-notice-message {
      color: $red;
    }

    .notification-cta {
      color: $red;
      border-color: $red;
    }

    .notification-icon {
      color: $red-80;

      svg {
        height: var(--size-008);
        line-height: var(--size-012);
        vertical-align: middle;
        margin: var(--spacing-004) 0;
      }
    }

    .notification-close-icon {
      color: $red;
      border-color: $red-40;
    }
  }
}
