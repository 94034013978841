@import "src/assets/scss/mixins";
@import "src/assets/v1.1/scss/spectrum";
@import "src/Splice/scss/spectrum";

.managePayout {
  padding-left: 72px;
  padding-top: 22px;
  min-height: 100vh;
  background-color: $dark-navy-0;
}

.title {
  font-size: 1.75rem;
  line-height: 2.5rem;
}

.card {
  padding: 32px;
  max-width: 50.6875rem;
  border-radius: 12px;
  margin-top: 50px;
  background-color: $white;
}

.emptyPayout {
  button {
    display: block;
    margin-left: auto;
  }
}

.payoutDetails {
  h4 {
    color: var(--text-body-info);
  }
}

.payoutNote {
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $black-80;

  span {
    font-weight: 700;
  }
}

.payoutDetail {
  margin-top: 1.5rem;
  padding: 24px;
  background-color: var(--navy-0);
  border-radius: var(--radius-xl);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 48px;

  .gridGroup {
    grid-column: 1/3;
  }

  .label {
    font-family: var(--font-noto-sans);
    font-size: var(--font-size-0);
    line-height: var(--font-lineheight-2);
    color: var(--text-body-subtle);
    margin-bottom: var(--spacing-004);
  }

  .value {
    font-family: var(--font-noto-sans);
    font-size: var(--font-size-1);
    line-height: var(--font-lineheight-3);
    color: var(--text-title-default);

    &.danger {
      color: $red-80;
    }
  }

  .payoutDetailFooter {
    grid-column: 1/3;
    margin-left: auto;
    display: flex;
    gap: var(--spacing-016);
  }
}

.payoutAlert {
  margin-top: 1.5rem;

  h4 {
    color: currentColor;
  }

  :global {
    .ant-alert {
      padding: 16px 16px 16px 48px;
      border: none;
      border-radius: 8px;

      &.ant-alert-warning {
        background-color: $yellow-10;

        .ant-alert-icon,
        .ant-alert-message,
        .ant-alert-description {
          color: $yellow;
        }
      }

      &.ant-alert-error {
        background-color: $red-20;

        .ant-alert-icon,
        .ant-alert-message,
        .ant-alert-description {
          color: $red;
        }
      }
    }

    .ant-alert-icon {
      top: 1rem;
      left: 1rem;
    }

    .ant-alert-message {
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    .ant-alert-message,
    .ant-alert-description {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.modal {
  min-width: 56.75rem;
  padding: 1.25rem 0 0 !important;

  h4 {
    font-size: 1.25rem !important;
    line-height: 2rem !important;
    letter-spacing: -1%;
  }

  .closeIcon {
    top: 1.5rem;
  }

  .modalRow {
    margin: 2rem;
  }

  .subtitle {
    margin-top: 4.0625rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $black-70;
  }

  .modalFooter {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-top: 48px;
    padding: 24px 32px;
    box-shadow: 0px 0px 10px 4px rgba(51, 55, 68, 0.08);
  }

  .deleteContainer {
    display: flex;
    align-items: center;
  }
}

.bankInfoModal {
  width: 21.875rem;
  padding: 1.25rem !important;

  p {
    margin-top: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  button {
    margin-top: 1.5rem;
    width: 100%;
  }
}

.formContainer {
  margin-top: 2rem;
  background-color: $black-30;
  padding: 24px 32px;
  border-radius: 8px;

  :global {
    .ant-select-selector {
      background-color: $white !important;
    }

    .ant-input-disabled {
      background-color: transparent;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .ant-form-item-extra {
      color: $black-70;
    }

    .ant-input,
    input {
      padding: 8px 16px;
    }

    .ant-form-item-hidden {
      display: none;
    }
  }

  .mobileNumber {
    display: grid;
    grid-template-columns: 4rem 1fr;
    column-gap: 4px;
  }
}

.selectFooter {
  box-shadow: inset 0px 1px 0px $black-40;

  button.link.info {
    padding: 0.25rem 1rem;
    text-decoration: none;
    width: 100%;
    justify-content: initial;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
    }
  }

  .footerIcon {
    color: #0c75d2;
    height: 16px;
  }
}

.formTitle {
  // overwrite Form.Item
  :global {
    .ant-form-item-label > label {
      color: $black-90 !important;
      font-weight: 600 !important;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      margin: 0;
    }
  }
}

.textCenter {
  text-align: center;
}

button {
  &.link {
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-80;
    padding: 0;

    &.disabled {
      color: $black-50;
      cursor: default;
    }

    &.info {
      color: #0c75d2;
    }

    &.danger {
      color: $red-80;
    }

    &.secondary {
      color: $brand;
    }

    &.withIcon {
      display: flex !important;
      align-items: center;
      column-gap: 0.25rem;

      .icon {
        color: currentColor;
        height: 1rem;
      }
    }
  }

  &.primary {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 8px 16px;
  }

  &.large {
    font-size: 1rem;
    line-height: 2.5em;
    font-weight: 600;
    letter-spacing: 0.015em;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.bankName {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.015rem;
  color: $black-90;
}

.swiftCode {
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.01875;
  color: $black-80;
}

.caption {
  color: var(--text-body-default);
  margin-bottom: var(--spacing-024);

  span {
    font-weight: var(--font-weight-6);
  }
}

.header {
  margin-bottom: var(--size-002);
}
