@import "../../../assets/scss/variables.scss";
.ant-popover.spm-ctx-menu__popover {
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0px !important;
  .ant-popover-inner {
    position: relative;
    top: -8px;
  }
  .ant-popover-inner-content {
    padding: 0px;
  }
  .spm-ctx-menu__popover__content {
    position: absolute;
    background: $white;
    box-shadow: 0px 0px 20px 0px rgba($pure-black, 0.06);
    width: 100%;
    border-radius: 4px;
    ul {
      max-height: 250px;
      overflow-y: scroll;
    }
    .spm-ctx-menu__popover__option {
      cursor: pointer;
      padding: 12px 16px;
      display: flex;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      & > * + * {
        margin-left: 8px;
      }
      &:hover {
        background: $spectrum-blue-20;
      }
      .spm-ctx-menu__option__highlight {
        color: $red;
      }
    }
    .spm-ctx-menu__popover__footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 8px 16px;
      box-shadow: 0px 0px 12px 0px rgba($pure-black, 0.12);
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      .spm-ctx-menu__footer__clear {
        border: none;
        background: none;
      }
      .spm-ctx-menu__footer__confirm {
        height: auto;
        padding: 6px 32px;
        border: none;
        background: $orange;
        color: $white;
        border-radius: 8px;
      }
    }
  }
}

.spm-ctx-menu__wrapper {
  position: relative;
}
