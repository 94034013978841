@import "../../assets/scss/variables.scss";

.steps-bar {
  .ant-steps-item-icon {
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    margin-left: 41px;
  }
  .ant-steps-item-finish,
  .ant-steps-item-wait:not(.ant-steps-item-disabled),
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: $orange;
      border-color: $orange;
      & > .ant-steps-icon {
        color: $white;
      }
    }
  }
  .ant-steps-item-wait:not(.ant-steps-item-disabled) {
    .ant-steps-item-content > .ant-steps-item-title {
      color: $orange;
    }
  }
  .ant-steps-item-finish,
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role="button"] {
    &:hover {
      .ant-steps-item-icon {
        background: $white;
        border: 1px solid $orange;
        & > .ant-steps-icon {
          color: $orange;
        }
      }
      .ant-steps-item-content > .ant-steps-item-title {
        color: $orange;
      }
    }
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: $light-grey-2;
    border-color: $light-grey-2;
    & > .ant-steps-icon {
      color: $light-grey;
    }
  }
  .ant-steps-item-wait,
  .ant-steps-item-finish {
    & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: $light-grey;
    }
    & .ant-steps-item-tail:after {
      background-color: $orange;
    }
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: $light-grey-2;
  }
  .ant-steps-item-tail {
    padding: 4px 15px;
    &::after {
      height: 4px;
      border-radius: unset;
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: $orange;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: $orange;
  }
  .ant-steps-item-title {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
  }
  .ant-steps-item-content {
    width: 122px;
  }
}
