@import "src/assets/scss/variables.scss";

.loading3quaters {
  width: 25px;
  height: 25px;
  border: 2px solid $pure-black;
  border-radius: 50%;
  border-right-color: transparent !important;
  animation: loader 0.6s linear infinite;
  &--white {
    border-color: $white;
  }
  &--orange {
    border-color: $orange-3;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
