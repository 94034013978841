.container {
  position: fixed;

  right: 2rem;
  bottom: 4.8rem;
}

.categories {
  position: absolute;

  bottom: 24.35rem;
  right: 24.45rem;

  &.addNew {
    bottom: 14.85rem;
    right: 14.48rem;
  }

  &.length-- {
    &1 {
      bottom: 10.35rem;
    }

    &2 {
      bottom: 13.35rem;
    }

    &3 {
      bottom: 16.35rem;
    }

    &4 {
      bottom: 19.35rem;
    }

    &5 {
      bottom: 22.35rem;
    }
  }
}

.download {
  position: absolute;

  bottom: 15.25rem;
  right: 17.85rem;
}
