@import "../../../assets/scss/variables.scss";

.spm-dropdown {
  .spm-dropdown__value-container {
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid $border-color;
    background: none;
    width: 100%;
    text-align: left;
    transition: all 0.5s;
    .spm-dropdown__value {
      font-size: 16px;
      line-height: 24px;
      color: $dark-gray-2;
      position: relative;
      cursor: pointer;
    }
    .selected__item__arrow {
      position: absolute;
      padding-left: 16px;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
      img {
        transition: transform 200ms;
        width: 24px;
      }
    }
    &:hover {
      border-color: $accent-blue !important;
      box-shadow: 0 0 0 2px $secondary-blue !important;
    }
    &:focus {
      border-color: $accent-blue !important;
      box-shadow: 0 0 0 2px $secondary-blue !important;
    }
    &.err {
      border-color: $red !important;
      box-shadow: 0 0 0 2px $red !important;
    }
  }

  .spm-dropdown__placeholder {
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }
}
