@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.approval-flow__conflict-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    background: none;
    padding: 24px;
    .ant-modal-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
    }
  }

  .ant-modal-body {
    padding: 24px;

    .conflict-modal__desc {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      margin-bottom: 24px;
      .desc__icon {
        filter: invert(41%) sepia(63%) saturate(1818%) hue-rotate(341deg) brightness(97%) contrast(98%);
      }
      .desc__text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .conflict-modal__content {
      border-radius: 8px;
      border: 12px solid $zircon;
      padding: 12px;
      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $dark-gray-3;
        margin-bottom: 12px;
      }

      .conflict-modal__current-flow {
        .current-flow__container {
          border: 1px solid $border-grey;
          border-radius: 8px;
          padding: 12px;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          .current-flow__title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            flex: 0 0 120px;
          }
          .current-flow__conditions {
            list-style: disc;
          }
        }
        margin-bottom: 12px;
        border-bottom: 1px solid $light-gray-2;
      }

      .conflict-modal__conflicting-flow {
        .conflicting-flow__list-item {
          background: $inactive-bg;
          border-radius: 8px;
          padding: 12px;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          & + .conflicting-flow__list-item {
            margin-top: 12px;
          }
          .conflicting-flow__title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            flex: 0 0 120px;
          }
          .conflicting-flow__conditions {
            list-style: disc;
          }
        }
      }
    }
    button.conlict-modal__edit-flow-button {
      background: $brand;
      border: 1px solid $brand;
      border-radius: 2.5rem;
      margin-top: 0.75rem;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -0.026rem;
      padding: 1rem;
      height: auto;
      width: 100%;
      &:hover {
        border: 1px solid $brand;
      }
    }
  }
}
