@import "src/assets/v1.1/scss/spectrum";

.formItemOCR {
  display: flex;
  .optional {
    color: $black-60;
    font-size: 0.9rem;
    margin-left: 0.313rem;
  }
  .tooltip {
    margin-left: 0.625rem;
    margin-top: 0.25rem;
  }
}
