.react-pdf__Page__svg {
  box-shadow: inset 0px 0px 4px rgb(51 55 68 / 10%);
}

.react-pdf__message.react-pdf__message--error a img {
  width: 7.5rem;
}

.react-pdf__message.react-pdf__message--error a {
  height: 25.1rem;
  width: 33.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 1.5rem;
}
