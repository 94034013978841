@import "src/assets/v1.1/scss/spectrum";

// Naming following existing typography on Figma

@mixin bold-12 {
  font-weight: 700;
  color: $black-60;
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin regular-14 {
  font-size: 0.875rem;
  color: $black-100;
  line-height: 1.375rem;
}

@mixin body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $black-90;
}

@mixin caption {
  font-size: 0.75rem;
  line-height: 1rem;
  color: $black-70;
}

@mixin medium {
  font-size: 1rem;
  line-height: 1.5rem;
  color: $black-80;
}
