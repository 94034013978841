@import "src/assets/scss/variables.scss";

.container {
  position: relative;
  padding: 10px;
  .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  .text {
    font-size: 1.2em;
  }
}
