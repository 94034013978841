@import "src/assets/v1.1/scss/spectrum";

.filter {
  flex-wrap: wrap;

  .searchFilter {
    width: 324px;
    border: 1px solid $black-50;
    background: transparent;
    height: 2.25rem;

    input {
      background: transparent;
      font-size: 0.875rem;
    }

    :global {
      .search__icon .icon__image {
        width: 1rem;
        height: 1rem;
        color: $black-100;
        opacity: 0.4;
      }
    }
  }

  .resetFilter {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $blue-80;
    cursor: pointer;
  }
}
