@import "src/assets/scss/variables.scss";

.homepage-header {
  &__container {
    padding: 2rem 3.5rem;
    background: $zircon;
    height: 7rem;
    position: relative;

    & + div {
      background: linear-gradient(to bottom, $zircon 56%, $white 40%);
    }
  }
  &__company-details-container {
    align-items: flex-start;
    max-height: 3rem;
  }
  &__username {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $dark-gray;
    text-align: center;
    display: flex;
    align-items: center;
  }
  &__username-icon {
    background-color: $peach;
    height: 3rem;
    width: 3rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: $orange-1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  &__user-role {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $orange;
    background: $peach;
    padding: 0.25rem 0.5rem;
    border-radius: 3.3125rem;
    margin-left: 0.5rem;
  }
  &__expense-container {
    margin-right: 1.5rem;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $gray;
    &--warning {
      & > p {
        color: $red;
      }
      & > .low-comp-balance {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1em;
      }
    }
    .tooltip__icon {
      vertical-align: bottom;
    }
  }
  &__expense {
    padding-top: 0.25rem;
    color: $dark-gray-2;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  &__topup_button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1.25rem;
    padding: 0.75rem 1.25rem;
    text-align: center;
    background: $orange;
    color: $white;
    font-weight: 600;
    min-width: 8.125rem;
    border-radius: 1.875rem;
    min-height: 3rem;
    &:hover {
      color: $white;
    }
  }

  &__available-credit-tooltip.ant-tooltip {
    .ant-tooltip-inner {
      color: $zircon !important;
      background-color: $gray !important;
    }
    .ant-tooltip-arrow::before {
      background-color: $gray;
    }
  }

  &__aryadana-tooltip.ant-tooltip {
    .ant-tooltip-inner {
      color: $white-2 !important;
      background-color: $dark-gray-2 !important;
      line-height: 1;
      padding: 0.5rem 0.75rem;
    }
  }

  &__label__aryadana-tooltip {
    width: 1.875rem;
  }
}
