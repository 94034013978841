@import "src/assets/v1.1/scss/spectrum.scss";

.create-team {
  &__error {
    .icon {
      display: none;
    }

    margin: 0;
    text-align: left;
  }
}

.team-profile-wrapper-name-field {
  .ant-input {
    min-width: 15.625rem;
  }

  &.with-rename-action {
    .ant-input-affix-wrapper {
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0.75rem 1rem;
    }

    .ant-input-suffix {
      display: flex;
      color: $blue-80;
      font-size: 1rem;
      font-weight: 600;
      line-height: 150%;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
