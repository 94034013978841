@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum";

.approval-flow__list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  grid-column: span 2;
  position: relative;

  .approval-flow__list-header {
    background-color: $zircon;
    color: $gray;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding: 12px 24px;
    margin-bottom: 24px;
    display: grid;
    grid-template: "left right" / 60% auto;
    gap: 12px;
  }

  .approval-flow__invalid-data {
    grid-column: span 2;

    .error-box {
      background: transparent;
      padding: 0.5rem 0;
    }
  }
}

.pagination-component {
  text-align: right;
  padding: 2rem 0 6rem 0;

  .ant-pagination-item-link,
  .ant-pagination-item {
    border: 0;
  }

  .ant-pagination-item-active a {
    color: $orange;
  }
}
