/**
 * @fileoverview This module provides styling for the main layout of the application.
 * It offers customizable grid layout options to cater to both logged in and onboarding user views.
 *
 * @author Bakshi
 * @created 24 Aug 2023
 * @lastModified 11 Sept 2023
 */

// Define custom properties for grid and content sizes.
$content-width: 63.25rem; // The width of the main content area
$sidebar-width: var(--size-110); // The width of the sidebar
$min-content-padding: var(--size-012); // Minimum padding around content


// Main layout configuration for the application.
.layout {
  // Basic styling of the main layout.
  display: inline-grid;
  width: var(--size-screen-width);
  height: var(--size-screen-height);
  background: var(--fill-neutral-default);
}

// Layout adjustments when the sidebar is visible.
.sidebar {
  grid-template-columns: $sidebar-width minmax(calc($content-width + ($min-content-padding * 2)), 1fr);
  grid-template-rows: minmax(0, 1fr);
}

// Layout adjustments during the onboarding process.
.default {
  grid-template-columns: minmax(calc($content-width + ($min-content-padding * 2)), 1fr);
  grid-template-rows: minmax(var(--size-screen-height), 1fr);
}
