@import "src/assets/v1.1/scss/spectrum";

.category-dropdown {
  &--loader {
    padding: 1rem 6em;
  }
}

.inner-error-border {
  .ant-select-selector {
    border: 0.0625rem solid $red-80 !important;
  }
}
