@import "src/assets/v1.1/scss/spectrum";

.container {
  padding: 0.625rem 1rem;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  border-top: 0.063rem solid $black-50;

  & > button {
    border: none;
    min-width: auto;
    min-height: auto;

    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 2rem;
  }

  & > .clear {
    color: $black-80;
  }

  & > .disableClear {
    cursor: not-allowed;
    user-select: none;
    color: $black-50;

    &:hover {
      color: $black-20;
    }
  }

  & > .add {
    padding: 0.25rem 1.875rem;
    border-radius: 0.5rem;
  }

  & > .disableApply {
    cursor: not-allowed;
    user-select: none;

    background-color: $black-50;
    color: $black-10;

    &:hover {
      background-color: $black-20;
      color: $black-50;
    }
  }
}
