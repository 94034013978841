@import "src/assets/v1.1/scss/spectrum";
@import "src/Modules/DS/Search/SearchMixin.module.scss";

.receipt {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  width: 33.625rem;

  & > .receiptHeader {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0.5rem 1.5rem;
    background: #f5f8fd;
    box-shadow: 0px 0px 0.25rem rgb(51 55 68 / 8%);
    border-radius: 0.5rem 0.5rem 0px 0px;

    height: 4rem;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #333744;
    }

    & > .receiptHeaderSecondary {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 0;

      & > .deleteIcon {
        width: 1.5rem;
        height: 1.5rem;

        padding: 2px;

        display: inline-flex;
        background-color: $red-80;

        mask: url("../../../../assets/img/icons/deleteIcon.svg");
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-position: center;
        -webkit-mask-position: center;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
      }

      .separator {
        width: 1px;
        background: #c5c7cd;
        height: 1rem;
      }

      & > .change {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background-color: transparent;

        color: #5ab3f3;
        white-space: nowrap;
      }
    }
  }

  & > .receiptBody {
    height: 100%;
    width: 33.625rem;
    box-shadow: inset 0px 0px 4px rgb(51 55 68 / 10%);

    display: flex;
    align-items: center;
    justify-content: center;

    &.pdfReceipt {
      cursor: pointer;
      align-items: flex-start;
      height: 25.1rem;
      overflow-y: scroll;
    }

    &.imageReceipt {
      border-radius: 0px 0px 0.5rem 0.5rem;
      cursor: pointer;
      overflow: auto;
      max-height: var(--size-240);
      display: flex;
      align-items: start;

      & > a {
        width: -webkit-fill-available;
        margin: auto;
        & > img {
          width: -webkit-fill-available;
        }
      }
    }

    &.brokenReceipt {
      border-radius: 0px 0px 0.5rem 0.5rem;
      flex-direction: column;
      gap: 1.5rem;
      cursor: pointer;

      & > a {
        display: contents;
        width: -webkit-fill-available;
        & > img {
          // width: -webkit-fill-available;
          width: 7.5rem;
          height: 7.5rem;
        }
      }
    }
  }

  & > .receiptFooter {
    background: #f5f8fd;
    padding: 1.25rem;

    box-shadow: 0px 0px 4px rgba(51, 55, 68, 0.08);
    border-radius: 0px 0px 0.5rem 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 12px;

    & > span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #333744;
    }
  }
}

.arrows {
  display: flex;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  background: #ffffff;
  border: 1px solid #eeeeee;

  box-shadow: 0px 5px 20px rgba(229, 230, 241, 0.5);
  padding: 1.063rem 1.25rem;

  align-items: center;
  justify-content: center;

  &.disabled {
    cursor: not-allowed;
    visibility: none;
    user-select: none;
    box-shadow: 0px 5px 20px rgba(229, 230, 241, 0.5);
  }
}

.prev {
  transform: rotate(90deg);
}
.next {
  transform: rotate(270deg);
}

.edge {
  cursor: not-allowed;
  opacity: 50%;
}

.slider {
  height: 100%;
}

.content {
  width: 45.625rem;
  height: 37.5rem;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}

.errorState {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 33.5rem;
  gap: 1.5rem;

  & > img {
    height: 7.5rem;
    width: 7.5rem;
  }

  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    width: 18.313rem;

    text-align: center;
    color: #535766;
  }

  & > button {
    min-width: 220px;
    min-height: 36px;
    padding: 0.5rem 1.25rem;

    background: #f66031;

    box-shadow: 0px 0px 0.5rem 1px rgba(51, 55, 68, 0.1);
    border-radius: 40px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #ffffff;
  }
}

.docWrapper {
  overflow-y: scroll;
}

.pdfPrev {
  width: 1.5rem;
  height: 1.5rem;

  padding: 2px;

  display: inline-flex;
  background-color: #333744;

  transform: rotate(90deg);
  mask: url("../../../../assets/v1.1/icons/Core/ChevronDown/line_24.svg");
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  &.pdfPrevDisabled {
    cursor: not-allowed;
    visibility: none;
    user-select: none;
    opacity: 50%;
  }
}

.pdfNext {
  width: 1.5rem;
  height: 1.5rem;

  padding: 2px;

  display: inline-flex;
  background-color: #333744;

  transform: rotate(270deg);
  mask: url("../../../../assets/v1.1/icons/Core/ChevronDown/line_24.svg");
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  &.pdfNextDisabled {
    cursor: not-allowed;
    visibility: none;
    user-select: none;
    opacity: 50%;
  }
}
