@import "src/assets/scss/variables";

.filter {
  position: relative;

  &--btn {
    width: fit-content;
    position: relative;
    cursor: pointer;

    .dot {
      position: absolute;
      right: -3px;
      top: -3px;
      z-index: 3;
      width: 10px;
      height: 10px;
      background: $orange;
      border-radius: 50%;
    }
  }

  &--wrapper {
    background: $white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 9;
    min-width: 800px;
    box-shadow: 0 0 10px -1px #ccc;

    h3 {
      font-weight: 600;
      color: $neutral-black;
      font-size: 12px;
      margin: 6px;
    }

    .ant-form-item-label > label {
      margin-top: 0;
      font-size: 14px;
    }
  }

  &--date {
    .ant-col-4 {
      margin-right: 128px;
    }
  }
  &--merchant {
    .ant-row {
      min-width: 230px;
    }
    .ant-select {
      font-size: 16px;
    }
  }

  &--toggle-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &--action {
    border-top: 1px solid #ccc;
    padding: 10px 0;
    display: flex;

    > div {
      flex: 1;
      display: flex;
    }

    .clear-btn {
      color: $red;
    }

    .cancel-btn {
      color: #a1a2ac;
    }

    .submit-btn {
      margin-left: 40px;
    }
  }
}
