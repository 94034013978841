@import "src/assets/scss/variables";

.simulation-container {
  .ant-timeline-item-tail {
    border-left: 2px solid $green;
  }
  .ant-timeline-item-head-green {
    background-color: $green;
    border-color: $green;
  }
  .ant-timeline-item-label {
    width: 140px !important;
  }
  .ant-timeline-item-tail,
  .ant-timeline-item-head {
    left: 150px !important;
  }
  .ant-timeline-item-content {
    left: 146px !important;
    width: calc(65% - 14px) !important;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.65);
    background-color: $white;
    cursor: default;
  }
}
