@import "src/assets/v1.1/scss/spectrum";

.currencyInput {
  + .currencyInput {
    padding-top: 24px;
  }

  label {
    cursor: pointer;
  }
}

.currenyInputGroup {
  display: grid;
  grid-template-columns: 6.25rem auto;
  gap: 0.25rem;

  &.withDropdown {
    grid-template-columns: 7.5rem auto;
  }

  .enableDropdown {
    grid-template-columns: 7.5rem auto;
  }

  :global {
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $white;
      color: $black-90;
      cursor: auto;
    }

    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: auto;
    }

    .icon {
      cursor: auto;
    }
  }
}

.currencyOptions {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1rem auto;

  .flag {
    width: 100%;
    filter: drop-shadow(0px 0px 2px #d9e3f9);
  }
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: $black-90;

  span {
    font-size: 0.875rem;
  }

  label {
    margin-top: 0 !important;
  }

  .spaceBetween {
    font-weight: 600;
    margin: 0.5rem 0;
  }

  .subfield {
    margin-left: 1.375rem !important;
  }
}
