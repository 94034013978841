.trxnBannerContainer {
  margin: 1rem 0 -0.25rem var(--spacing-020);
  max-width: calc(100vw - 16.875rem);
}

.link {
  color: var(--text-body-success);
  &:hover {
    color: var(--text-body-success);
  }
}
