@import "src/assets/v1.1/scss/spectrum";

.add-reimbursement {
  margin: 2.5rem 0;
  & > h3,
  & > h2,
  & > h1 {
    font-weight: 600;
  }

  input::placeholder,
  textarea::placeholder,
  .tag-input > span,
  .ant-select-selection-placeholder {
    color: var(--text-body-disabled);
    opacity: 1;

    font-family: var(--font-noto-sans);
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-4);
    line-height: var(--font-lineheight-3);
  }

  &--header {
    & > h2,
    & > h1 {
      font-size: 1.75rem;
      line-height: 2.125rem;
      color: $black-100;
      font-weight: bold;
    }
  }

  &--container {
    margin: 1.25rem 0;
    background: $white;
    padding: 2.5rem;
    border-radius: 0.625rem;

    h3 {
      font-size: 1.25rem;
      color: $black-100;
      font-weight: 600;
      padding-bottom: 1rem;
      line-height: 1.875rem;
    }

    .ant-form-item-label > label {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25em;
      color: $black-100;
      margin-top: 0;
    }

    .date-picker__date {
      border: 1px solid $black-40;
      width: 100%;
      background: $white;
    }

    .upload-image--select-card {
      width: 100px;
      height: 100px;
      background: $black-20;
      padding: 0.875rem 0;
      border-radius: 12px;
    }

    & &__notes {
      min-height: 6.5rem;
    }

    &__action-btns {
      .button {
        border-radius: 0.25rem;
        margin-right: 0.625rem;
      }
      .cancel-btn {
        background-color: transparent;
        color: $black-90;
        border: 0.0625rem solid $black-50;
        padding: 1rem 2.8125rem;
        border-radius: 0.25rem;
      }
    }
    &__upload {
      .ant-upload-list-picture-card {
        display: flex;
        width: 23.75rem;
        overflow-x: auto;
      }
    }
  }
  &--back {
    margin-bottom: 1rem;
    display: flex;
    color: $brand;
    cursor: pointer;
  }
  &--icon {
    padding-right: 0.875rem;
  }
  &--aryadana {
    width: 60%;
    margin: 2rem auto 0;
  }
  &--payout-banner {
    margin-bottom: var(--spacing-024);

    h4 {
      color: var(--text-body-info);
    }
  }
}

.tax-select {
  .ant-select-selection-item {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    display: block;
  }

  .ant-select-item-option-content {
    overflow: unset;
    white-space: unset;
  }
}
