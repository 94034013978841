@mixin customScroll($width, $trackColor, $thumbColor) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $trackColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbColor;
  }
}

@mixin activeCard {
  box-shadow: var(--elevation-level-3);
  border: var(--border-size-1) solid var(--navy-010);
}

@mixin cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-016);
  height: 100%;
  min-width: 29rem;
  overflow-y: auto;
  border-radius: var(--spacing-008);
  box-shadow: var(--elevation-level-1);
  max-height: calc(
    100vh - (12rem + 40px)
  ); // The maximum card height should be the remaining space on the page after accounting for the space occupied by filters, tabs, and the header. 40px is the total padding of Bills page and 12rem is the combined height of space for filters, tabs, header and horizontal scroll.
}

@mixin cardHeader {
  text-align: center;
  background: var(--dark-navy-005);
  border-radius: var(--radius-m) var(--radius-m) var(--radius-none)
    var(--radius-none);
  align-items: center;
  justify-content: center;
  padding: var(--spacing-016) 0;
  width: 100%;

  span {
    color: var(--text-title-default);
  }
}

@mixin cardContent {
  display: flex;
  padding: 0 var(--spacing-016) var(--spacing-016) var(--spacing-016);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-016);
  width: 100%;
}

@mixin billItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-016);
  border-radius: var(--radius-none);
  width: 100%;
}

@mixin billCheckbox {
  display: flex;
  align-items: center;
  gap: var(--spacing-008);
}

@mixin sortByVisible {
  visibility: visible !important;
}

.container {
  @include cardContainer();
  position: relative;
  border: var(--border-size-1) solid transparent;
  &:hover {
    @include activeCard;

    .sortBy {
      @include sortByVisible;
    }
  }
}

.sortByVisible {
  @include sortByVisible;
}

.dropContainer {
  width: 100%;
  overflow-y: auto;
  @include customScroll(var(--size-002), transparent, transparent);

  &:hover {
    overflow-y: auto;
    @include customScroll(
      var(--size-002),
      var(--fill-neutral-strong),
      var(--text-body-disabled)
    );
  }
}

.header {
  @include cardHeader();
  display: flex;
  flex-direction: column;
  gap: var(--spacing-008);
}

.content {
  @include cardContent();
  overflow-y: auto;
}

.billItem {
  @include billItem();

  > div {
    width: 100%;
  }

  button {
    padding: 0;
  }
}

.billCheckbox {
  @include billCheckbox();
}

.dropdown {
  &Menu {
    &::-webkit-scrollbar {
      width: var(--size-002);
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      @include customScroll(
        var(--size-004),
        var(--fill-neutral-strong),
        var(--text-body-disabled)
      );
    }

    li {
      color: var(--text-title-default);
      text-align: center;
      text-overflow: ellipsis;
      padding: var(--spacing-008) var(--spacing-012);
      width: 100%;
    }

    display: flex;
    width: 7.25rem;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: var(--spacing-004);
    box-shadow: var(--elevation-level-3);
    background: var(--fill-neutral-default);
    overflow-y: scroll;
    max-height: 8.5rem;
    @include customScroll(
      var(--size-004),
      var(--fill-neutral-strong),
      var(--text-body-disabled)
    );
  }
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  padding: var(--spacing-012) var(--spacing-016);
  background-color: var(--text-body-default-inverse);
  border-radius: var(--radius-m) var(--radius-m) 0 0;
  box-shadow: var(--elevation-level-2);
  min-height: 4.5rem;

  .totalSelectedPayout {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-004) 0 var(--spacing-004) 0;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(--spacing-004);
  }

  .selectedBillsLabel {
    color: var(--text-body-default);
  }

  button {
    min-height: 1.75rem;
    max-height: 1.75rem;
    padding: var(--spacing-004) var(--spacing-012);
  }
}

.paySelected {
  justify-content: space-between;
}

.assignTo {
  justify-content: center;
}

.noBills {
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-004);
    align-items: center;
    width: 18.75rem;
    padding-bottom: var(--spacing-024);
  }
}

.fixedFooter {
  padding-bottom: 5.5rem;
}

.sortBy {
  position: absolute;
  visibility: hidden;
  right: var(--size-012);
  margin-top: var(--spacing-016);
}

.observerWrapper {
  width: 100%;
}

.highlight {
  font-weight: var(--font-weight-6);
}

.activeCard {
  @include activeCard;
}

.totalPayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bodyColor {
  color: var(--text-body-default);
}
.latePaymentPreference {
  display: flex;
  gap: var(--spacing-008);
}
