@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/v1.1/scss/typography";

@mixin getBtn {
  background: $brand-20;
  padding: 0.125rem 1rem;
  border-radius: 3.5rem;

  font-weight: 600;
  font-size: 0.875rem;
  line-height: 2rem;
  color: $brand;
}
.shimmer {
  margin-right: 2rem;

  min-width: 6.5rem;
  border-radius: 3.5rem;
}
.container {
  display: flex;
  padding: var(--spacing-004) var(--spacing-016);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-004);
  border-radius: var(--radius-s);
  background: var(--fill-brand-orange);
  font-size: var(--font-size-0);
  color: var(--text-body-brand);
  line-height: var(--font-lineheight-2);
  &:hover {
    cursor: pointer;
  }
}
