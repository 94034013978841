@import "../../assets/scss/variables.scss";

.file-upload {
  pointer-events: none;
  &.ant-upload.ant-upload-drag {
    background: $light-grey-2;
    border-radius: 8px;
    padding: 24px;
    border: none;
    margin-top: 32px;
    width: 602px;
    min-height: 412px;
  }
  &__title {
    text-align: left;
    font-weight: 600;
    font-size: 22px;
    color: $orange;
    line-height: 20px;
  }
  &__content {
    background: $white;
    border: 1px dashed $light-grey;
    border-radius: 8px;
    height: -webkit-fill-available;
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 340px;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: $dark-grey-2;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $dark-grey;
    }
    .upload-icon {
      font-size: 16px;
      line-height: 24px;
      color: $red;
      margin-bottom: 5px;
    }
    .button {
      padding: 16px 80px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      pointer-events: all;
    }
    .bulk-error-msg {
      font-size: 14px;
      color: $red;
      margin-bottom: 5px;
    }
    .upload-message {
      &__container {
        &__progress-bar {
          width: 260px;
        }
        .button {
          color: $silver;
        }
      }
    }
    .message {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $light-grey;
      padding-bottom: 30px;
      &--error {
        color: $red;
      }
    }
  }
  .upload-step {
    &__title {
      color: $orange;
      text-align: left;
      font-weight: 600;
      font-size: 22px;
      line-height: 20px;
    }
  }

  &__loading {
    width: 280px;
    margin: 0 auto;

    .ant-progress-inner .ant-progress-bg {
      width: 500px;
      background-color: #1fce8f;
    }
    .ant-progress-text {
      color: #1fce8f;
    }
  }

  &__success-container {
    background: $zircon;
    padding: 16px 32px;
    border-radius: 8px;
    margin: 0 24px 24px;
    max-width: 500px;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: $black;
    }
  }
}
