@import "src/assets/v1.1/scss/spectrum";

.asyncImageCarousel {
  min-width: 41.56rem;
  max-width: 100%;
  padding: 2.5rem;
  max-height: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
  .icon {
    display: flex;
    align-items: center;
    margin: 0.25rem 2.5rem 0 2.5rem;
    position: absolute;
    :global(.icon__title) {
      width: fit-content;
      padding: 0 0 0 0.375rem;
      font-size: 0.875rem;
      color: $black-90;
    }
  }

  :global(.carousel--content) {
    flex-direction: column;
  }

  .loadingWrapper {
    > div {
      margin: auto;
    }
  }
}

.modalPreview {
  :global(.react-pdf__Document) {
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    height: 40rem;
  }
}
