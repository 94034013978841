$brand: #f66031;
$brand-80: #ff7917;
$brand-20: #fff5ed;

$yellow: #752005;
$yellow-80: #ffda57;
$yellow-40: #fcf1c5;
$yellow-20: #fff7dc;
$yellow-10: #fffbee;

$green: #0d8258;
$green-80: #1fce8f;
$green-75: #51e5b0;
$green-40: #a6f2d7;
$green-20: #e9fcf5;

$blue: #084672;
$blue-80: #5ab3f3;
$blue-40: #cdeeff;
$blue-20: #e5f4ff;
$blue-10: #f0f9ff;

$red: #c2190a;
$red-80: #df1d0b;
$red-40: #fdd2ce;
$red-30: #ffebed;
$red-20: #fff5f6;

$white: #ffffff;

$black-100: #131a2e;
$black-90: #333744;
$black-80: #535766;
$black-70: #676b78;
$black-60: #9599a4;
$black-50: #c5c7cd;
$black-40: #e4e5e7;
$black-30: #f5f8fd;
$black-20: #f4f6f9;
$black-10: #fafafa;
$black-0: $white;

$light-orange-05: #fff4e6;

$opaque-shadow: #d9e3f9;

$linearGradientBackground: (
  "light": linear-gradient(270deg, $brand-80 0%, #fcca4c 100%),
  "dark": linear-gradient(270deg, #ee3e26 0%, #ff8429 100%),
);
$linearGradientCard: linear-gradient(35.71deg, #02051b 9.6%, #35545d 79.82%);
