@import "src/assets/scss/variables.scss";

.update-card-activation {
  height: 100%;
  background: $white;
  &--wrapper {
    padding: 68px;
  }
  .error-message {
    color: $red;
  }
  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #131a2e;
    padding-bottom: 8px;
  }
  .sub-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #333744;
    padding-bottom: 14px;
  }
  .ant-form .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #131a2e;
  }
  .ant-input {
    width: 494px;
    height: 48px;
    border-radius: 6px;
    background: transparent;
    &-disabled {
      background: $hovered-row;
      color: $dark-grey-1;
    }
    &::placeholder {
      color: #131a2e;
      opacity: 0.5;
    }
  }
  .ant-form-item {
    margin: 0;
  }
  .phone-details {
    .ant-form-item-control-input-content {
      display: flex;
    }
    .phone-code-dropdown > .ant-select-selector {
      padding: 9.7px 16px;
    }
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background: $hovered-row;
      color: $dark-grey-1;
    }
    .phone-code-dropdown {
      height: fit-content;
    }
    .phone-number-field {
      width: 402px;
    }
  }
  .update-profile-btn {
    width: 248px;
    margin-top: 32px;
    height: 56px;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
  }
  .loading-modal__content__message {
    width: 239px;
    margin: 0 auto;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
