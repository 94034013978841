@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/scss/variables";

.approval-flow__list__item__container {
  box-shadow: 0px 1px 4px 0px $box-shadow;
  padding: 1.5rem;

  &__inactive {
    border: 1px solid $red;
    border-radius: 0.75rem;
  }

  & + .approval-flow__list__item__container {
    margin-top: 12px;
  }

  &:hover {
    background: $peach;

    .list_item_actions {
      display: flex;
    }
  }
}

.approval-flow__list__item {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  display: grid;
  grid-template: "title title title" auto "desc app act" auto / 60% auto 78px;
  gap: 12px;
  transition: all 0.5s;

  cursor: pointer;

  .list__item__title {
    grid-area: title;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $black;
  }

  .list__item__detail {
    .detail__desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $gray;
      margin-bottom: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      .error-text {
        color: $red;
      }
    }

    .detail__pub {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $gray;
      margin-bottom: 8px;
    }
  }

  .list__item__approver {
    display: flex;
    align-items: flex-start;

    .approver__item {
      display: flex;
      align-items: center;

      .approver__item__ordinal {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $gray;
        background-color: $zircon;
        border-radius: 32px;
        height: 32px;
        width: 32px;
        padding: 8px;
        text-align: center;
        margin-right: 8px;

        &.approver__item__ordinal__error {
          background-color: $inactive-bg;
          color: $red;
        }
      }

      .approver__item__name {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: $dark-gray-2;
        max-width: 16.125rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.approver__item__name__error {
          color: $red;
        }
      }
    }
  }

  .list_item_actions {
    justify-content: flex-end;
    gap: 24px;
    display: none;
    height: max-content;

    .actions_edit {
      color: #1e97ee;
      margin-top: 3px;

      &:hover {
        opacity: 0.5;
      }
    }

    .actions_menu:hover {
      opacity: 0.5;
    }
  }
}

.list-item__menu__popover {
  .ant-popover-content {
    .list-item__menu__dropdown-btn {
      cursor: pointer;
      display: flex;
      font-size: 14px;
      line-height: 2rem;

      img {
        margin-right: 12px;
      }

      &:hover {
        opacity: 0.7;
      }

      span {
        flex: 1;
      }
    }
    .ant-popover-inner {
      padding: 1rem;
    }
  }
}

.approval-flow-modal {
  width: 600px !important;
  max-width: 100%;

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-close {
    top: 18px;
    right: 16px;
  }

  .ant-modal-close-x {
    svg {
      width: 20px;
      height: 20px;
      color: $dark-gray;
    }
  }

  .ant-modal-body {
    padding: 24px;

    .ant-alert-with-description {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .ant-alert-description {
      font-size: 16px;
    }

    .ant-alert-warning {
      background: #fff8e7;
      color: #f65731;
      font-size: 16px;
      border: 0;

      .ant-alert-icon {
        color: #f65731;
        margin-top: 3px;
      }

      .ant-alert-message {
        display: none;
      }
    }
  }

  .ant-modal-header {
    padding: 24px 24px 0px 24px;
    border-bottom: 0;
    background: none;

    .ant-modal-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .ant-modal-footer {
    padding: 24px;
  }

  .approval-flow-modal__action-area {
    input {
      border-color: #e4e5e7;
      margin-top: 8px;

      &:focus {
        outline: 2px solid #1e97ee;
      }
    }

    .approval-flow-modal__error {
      color: #dd1c0b;
      font-size: 12px;
      letter-spacing: -0.8px;
      margin-top: 8px;
    }
  }
}

.approval-flow-modal__content {
  font-weight: 400;

  .approval-flow-modal__text {
    color: #333744;
    font-size: 16px;
    margin-top: 15px;
    line-height: 24px;
    letter-spacing: -0.41px;

    .approval-flow-modal__text__title {
      font-weight: 600;
    }

    .approval-flow-modal__text__title::before {
      content: "\201C";
    }

    .approval-flow-modal__text__title::after {
      content: "\201D";
    }
  }

  .approval-flow-modal__content__flow-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.mt-15 {
  margin-top: 15px;
}

.separator-one {
  border-color: #c5c7cd;
  margin: 15px 0;
  opacity: 0.2;
}

ul.list-style-1 {
  list-style: disc;
  padding: 0 0 0 20px;
  margin-left: 10px px;
}

.ant-btn.approval-flow-modal__action-button--delete[disabled] {
  background: #c5c7cd;
}

.ant-btn.approval-flow-modal__action-button--delete,
.ant-btn.approval-flow-modal__action-button--update {
  background-color: #e5304c;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  height: auto;
  min-width: 204px;
  padding: 10px 30px;
  border-radius: 8px;
}

.align-right {
  text-align: right;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;

  .loading {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
}

.flow-approver__inactive {
  color: $red-80;
}
