@import "src/assets/v1.1/scss/spectrum.scss";

.bulkActionContainer {
  height: 4.75rem;
}

.marginAuto {
  margin: auto;
}

.bulk-actions {
  height: 4.75rem;
  background: $white;
  box-shadow: 0px 0px 0.5rem 0.063rem rgba(51, 55, 68, 0.1);
  border-radius: 0.75rem 0.75rem 0px 0px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 13.75rem - 3.5rem);
  padding: 1.25rem 1.5rem;

  :global(.ant-row) {
    column-gap: 1.5rem;
  }

  &__selection-control {
    line-height: 1.25rem;
    font-size: 0.875rem;
    display: flex;
    column-gap: 0.5rem;

    &__text {
      color: $black-90;
      font-weight: 600;
    }
    &__hint {
      color: $black-70;
      font-weight: 400;
    }
  }
  &__clear-selection {
    color: $blue-80;
    letter-spacing: 0.02em;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  &__supporting-info-container {
    flex: 10;
    display: flex;
    justify-content: flex-end;
  }
  &__supporting-info-content {
    display: flex;
    justify-content: flex-end;
  }
  &__action-control {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  &__action-control-link {
    max-width: 30rem;
  }
}
