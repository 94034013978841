@import "src/assets/scss/variables.scss";

.custom-carousel {
  position: relative;
  height: 100%;
  &:hover {
    .custom-carousel__arrows {
      display: flex;
    }
  }

  &__container {
    position: relative;
    height: 100%;
    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: static;
      li {
        width: 8px;
        height: 8px;
        &::before {
          display: none;
          content: "";
        }
        button {
          outline: none;
          border: none;
          border-radius: 50%;
          background: $light-gray;
          width: 8px;
          height: 8px;
          margin: 0px 4px;
          padding: 4px;
          &::before {
            content: "";
            display: none;
          }
        }
        &.slick-active {
          width: 22px;
          button {
            background-color: $blue;
            border-radius: 23px;
            width: 22px;
            animation: dot-animation 200ms 1;
            @keyframes dot-animation {
              from {
                width: 4px;
              }
              to {
                width: 22px;
              }
            }
          }
        }
      }
    }
  }
  &__arrows {
    height: 48px;
    width: 48px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0px 5px 20px rgba(229, 230, 241, 0.5);
    align-self: center;
    outline: none;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    &--previous {
      left: -30px;
      top: 45%;
      transform: translateY(-50%);
    }
    &--next {
      right: 68px;
      top: 45%;
      transform: translateY(-50%);
    }

    &--disable {
      opacity: 0;
      visibility: hidden;
      display: flex;
      transition: visibility 300ms, opacity 200ms ease-out;
      cursor: none;
      pointer-events: none;
    }
  }
}
