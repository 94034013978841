@import "src/assets/scss/variables.scss";

.repeat-form {
  h4 {
    margin: 24px 0px 10px;
    font-size: 14px;
    font-weight: 600;
  }

  .repeat-num {
    margin-right: 24px;
  }

  .repeat-end-num {
    margin: 0px 16px;
  }

  .repeat-end {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number {
    width: 36px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid $light-gray-4;
  }

  .ant-input-number-input {
    text-align: center;
    font-size: 16px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-radio-button-wrapper {
    width: 80px;
    padding: 6px;
    height: 40px;
    text-align: center;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 6px 0px 0px 6px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 6px 6px 0px;
  }
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper:focus,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: initial;
    border-color: $orange !important;
    color: $orange;
    box-shadow: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $orange !important;
    opacity: 1;
  }

  span.ant-radio + * {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ant-radio-wrapper {
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
  }

  .ant-radio-wrapper + .ant-radio-wrapper {
    margin-top: 8px;
  }

  .ant-radio {
    vertical-align: middle;
  }

  .ant-radio-input:hover + .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border-color: $orange;
  }

  .ant-radio-inner:after {
    background-color: $orange;
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }
}
