.preview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-032);

  &Section {
    border: 1px solid var(--fill-neutral-strong);
    border-radius: var(--radius-s);

    .sectionHeader {
      display: flex;
      justify-content: space-between;
      padding: var(--spacing-016);
      background-color: var(--fill-neutral-strong);
    }

    .title {
      color: var(--text-title-default);
    }
  }

  &Content {
    padding: var(--size-012);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-024);
  }

  &Item {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-016);
    line-height: var(--size-012);
    color: var(--text-body-default);

    & > div:first-child {
      font-weight: 600;
      color: var(--black-100);
      width: 14.125rem;
      flex-shrink: 0;
    }

    & > div:last-child {
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      column-gap: var(--spacing-004);
      word-break: break-word;
    }
  }

  &Label {
    display: flex;
    align-items: center;
    gap: var(--spacing-024);

    & > div {
      display: flex;
      align-items: center;
    }

    .previewColor {
      width: var(--size-008);
      height: var(--size-008);
      border-radius: var(--radius-s);
      display: inline-block;
      margin-right: var(--spacing-008);
      background-color: var(--black-080);
    }

    .previewHighlight {
      @extend .previewColor;
      background-color: var(--chart-green-default);
    }
  }

  .newValue {
    color: var(--green-050);
  }

  .oldValue {
    color: var(--text-body-disabled);
    text-decoration: line-through;
  }
}
