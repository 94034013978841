.categories {
  &__fields {
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5em;
      color: #131a2e;
    }
  }
  .side-section {
    height: 100%;
    position: fixed;
    right: 0;
    width: 460px;
    bottom: 0;
    z-index: 10;
    background: #333744;
  }
}

.text-with-new-wrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
