@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  height: 22.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  &__no-result-icon,
  &__no-result-supporting-info {
    width: auto;

    &:hover {
      cursor: default;
    }
  }

  &:hover {
    cursor: default;
    background-color: $white;
  }
}
