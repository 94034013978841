.recursive-table {
  display: flex;
  width: 100%;
  min-height: 600px;
  &-tabs {
    margin-bottom: 1rem;
  }
  .tab-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #f65731;
    display: inline-block;
    margin-left: 6px;
    margin-top: -2px;
    vertical-align: middle;
  }
  .ant-table-row {
    cursor: pointer;
  }
  &__container {
    padding: 48px 68px;
    width: 100%;
    &__header {
      padding-bottom: 30px;
      & > h1 {
        font-weight: 600;
        font-size: 28px;
        line-height: 2.125em;
        color: #000000;
      }
      & > .button {
        display: flex;
        margin-left: auto;
        justify-content: center;
      }
    }
    &__btn-link {
      text-align: end;
    }
    &__action-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .subtab-count {
        background: #f65731;
        padding: 4px;
        color: #ffffff;
        border-radius: 2px;
        margin-left: 4px;
      }
      .ant-radio-button-wrapper {
        padding: 8px 16px;
        border-radius: 4px;
        margin-right: 8px;
        border: none;
        height: auto;
        color: #666b79;
        background: none;
        &:not(:first-child):before {
          display: none;
        }
        &:hover {
          color: #fff5ed;
          background: #f65731;
        }
        &-checked:not(.ant-radio-button-wrapper-disabled) {
          z-index: 0;
          color: #f65731;
          background: #fff5ed;
          &:hover {
            color: #fff5ed;
            background: #f65731;
          }
        }
      }
    }
  }
  .row-select-btn {
    margin: 0 12px;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    margin-left: 3px;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff5ed;
  }
  .table-row-field {
    &__details,
    &__title--bold {
      color: #d5d4d4;
    }
    &__title {
      color: #abb4ce;
    }
  }
  .row-select-warning {
    color: #f65731;
  }
  .action-btns {
    width: 100%;
    &--wrapper {
      flex-wrap: wrap;
      width: calc(100% - 460px);
    }
  }
  .main-export-btn {
    font-size: 14px;
    display: flex;
    margin-left: auto;
    justify-content: center;
    line-height: 20px;
    padding: 12px 20px;
    text-align: center;
    background: #f65731;
    color: #fff;
    border-radius: 8px;
    font-weight: 600;
    min-width: 130px;
    position: relative;
    min-height: 48px;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background: #f65731;
      color: #fff;
      border: none;
    }
  }
  .preview-modal {
    max-width: 80%;
    max-height: 80%;
  }
}
