@import "src/assets/v1.1/scss/spectrum.scss";

.scheduled-details {
  --scrollbar-width: calc(100vw - 100%);
  position: relative;
  padding-bottom: 6rem;
  .scheduled-details__receiver {
    font-weight: 600;
    font-size: 1.25rem;
    color: $black-100;
    margin-bottom: 0.625rem;
  }
  .scheduled-details__amount {
    font-weight: 700;
    font-size: 1.5rem;
    color: $black-100;
    margin-bottom: 0.625rem;
  }
  .scheduled-details__date {
    font-weight: 600;
    font-size: 0.875rem;
    color: $black-100;
  }
  h3 {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $black-100;
    padding-top: 1.75rem;
  }
  .table-row-field__title {
    color: $blue-40;
  }
  .table-row-field__details {
    color: $black-100;
  }
  .scheduled-details__actions {
    position: fixed;
    bottom: 0;
    right: var(--scrollbar-width);
    width: calc(28.75rem - var(--scrollbar-width));
    padding: 1rem 2.5rem;
    background: $black-10;
    button {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding: 0.625rem;
      border-radius: 1.875rem;
    }
    .action-buttons__button--green {
      background-color: $blue;
      border-color: $blue;
      &:hover {
        background-color: $black-100;
        color: $blue;
      }
    }
  }
}
