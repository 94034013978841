.container {
  width: 100%;
  user-select: none;
  .item {
    text-align: left;
    margin-right: 0.625rem;
    font-size: 0.75rem;
    line-height: 1rem;
    display: flex;
    img {
      margin-right: 0.625rem;
    }
  }
}
.type1 {
  display: flex;
  justify-content: space-between;
  .item {
    width: calc(100% / 3);
  }
}
.type2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .item {
    width: 220px;
    margin: 0 1.5rem 1.5rem 0;
  }
}
@media screen and (min-width: 1440px) {
  .type2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .item {
      width: calc(100% / 3);
    }
  }
}
.type3 {
  .item {
    padding-bottom: 1.5rem;
    width: 100%;
  }
}
