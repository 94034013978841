@import "src/assets/scss/variables.scss";

.add-employee-form {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: $orange;
    padding-top: 40px;
    &::before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: 0 8px 3px 0;
      display: inline-block;
      background-color: $orange;
      vertical-align: middle;
    }
  }
  .country-code-field {
    padding-right: 0;
    max-width: 7.3rem;
    flex: 0 0 12.5%;
    display: block;
    padding-left: 12px;
  }
  .phone-number-field {
    .ant-input {
      width: 14rem;
    }
  }
  .employee_details {
    &--header {
      display: flex;
      align-items: baseline;
      width: 82%;
      justify-content: space-between;
    }
  }
  &__delete-btn {
    display: flex;
    align-items: end;
    width: fit-content;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $red;
    .icon {
      padding-right: 8px;
    }
  }
  .role-label {
    & > .ant-form-item-label label {
      display: flex;
      align-items: flex-start;
    }
    .icon {
      padding-left: 16px;
    }
  }
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
  }
  .ant-radio-button-wrapper {
    &-checked:not(.ant-radio-button-wrapper-disabled),
    &-checked:not(.ant-radio-button-wrapper-disabled)::before,
    &:active {
      border-color: $orange;
      color: $orange;
    }
    &-checked:not(.ant-radio-button-wrapper-disabled):before {
      background-color: $orange;
    }
    &-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
      border-right-color: $orange;
    }
    &:hover {
      color: $orange;
    }
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    height: 44px;
    padding: 0 30px;
    line-height: 40px;
    font-weight: 600;
  }
  .add-employee-btn {
    font-size: 16px;
    line-height: 40px;
    margin: 28px 0 0 0;
    padding: 10px 16px;
  }
  &__date-picker {
    background-color: $white;
    border-radius: 0.5rem;
    font-size: 1rem;
    border: 1px solid $border-color;
    width: 100%;
    &:hover,
    &:focus,
    &.ant-picker-focused {
      border-color: $orange-2;
      box-shadow: 0 0 0 2px rgb(255, 103, 24, 0.2);
    }
    input {
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
  &__optional-text {
    color: $light-gray-1;
    font-weight: 400;
  }
}

.role-guidelines-modal {
  padding: 25px 32px 40px 32px;
  width: 520px;
  .modal__title {
    color: $orange;
  }
  .table-row-field {
    margin-top: 24px;
    &__title {
      line-height: 28px;
      font-size: 20px;
      color: $dark-grey;
    }
    &__details {
      padding-top: 4px;
      line-height: 24px;
      font-weight: normal;
    }
  }
}

.onboarding-phone-code-menu {
  max-height: 500px;
  overflow: scroll;
  background: $black;
  opacity: 0.8;
  .ant-dropdown-menu-item {
    color: $light-gray-4;
    &:hover {
      color: $white;
      background: none;
    }
  }
}
