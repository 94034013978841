@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spaces.scss";

.teamName {
  display: flex;
  gap: $space-12;
  align-items: center;
}

.avatar {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  background: $brand-20;

  line-height: 2.25rem;
  text-align: center;
  font-weight: 600;
  color: $brand;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 8px;
}

.totalBreakup {
  display: flex;
  flex-flow: row wrap;
  column-gap: $space-16;
  justify-content: flex-start;
  align-items: center;

  & > div {
    display: flex;
    column-gap: $space-8;
    align-items: center;

    span {
      display: block;
      width: 0.6875rem;
      height: 0.25rem;

      border-radius: 0.25rem;
    }
  }
}

.barChartContainer {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}
