@import "src/assets/v1.1/scss/spectrum";

.title {
  background: $black-20;
  font-weight: 600;
  font-size: 0.825rem;
  line-height: 1.5rem;

  color: $black-80;

  padding: 0.75rem 1rem;

  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.titleShimmer {
  border-radius: 0.25rem;
}
