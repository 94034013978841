.teamConatinerShimmer {
  .paragraph {
    display: flex;
  }

  .avatar {
    width: 24px !important;
    height: 24px !important;
    align-self: center;

    span {
      background: #f4f6f9;
      border-radius: 24px !important;
    }
  }

  .ant-skeleton .contentBtn {
    width: 95%;
    background: linear-gradient(90deg, #f4f6f9 0%, #e6e8ee 100%) !important;
    border-radius: 4px !important;
    height: 16px !important;
    align-self: center;
    margin-left: 20px !important;
  }

  .divider {
    border-bottom: 1px solid #eeeeee;
  }
}
