@import "src/assets/v1.1/scss/spectrum.scss";

.faq-button__container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 0.625rem 1.5rem;
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 1.563rem;
  box-shadow: 0 0 0.625rem 0.188rem rgb(0 0 0 / 12%);
  cursor: pointer;

  .icon {
    margin-right: 0.5rem;
  }
}
