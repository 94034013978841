.spm-menu-line-item-title {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shimmer {
  border-radius: 0.25rem;
}

.menuLineItemSmall {
  color: var(--text-title-default);
  font-size: var(--font-size-00);
}
