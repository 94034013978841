@import "src/assets/v1.1/scss/spectrum";

.select {
  :global {
    .ant-select-selection-item {
      display: block;
      line-height: 1.875rem !important;
    }

    .ant-select-selection-item > div:not(:first-child) {
      display: none;
    }
  }
}

.dropdown {
  .divider {
    margin: 0 !important;
  }

  .dropdownAdd {
    border: 0;
    font-size: 0.875rem;
    width: 100%;
    text-align: left;
    box-shadow: none;
    height: 2.875rem;
    color: $blue-80;
    cursor: pointer;
  }

  .clearIcon {
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
  }

  .break {
    flex-basis: 100%;
    width: 0;
  }

  .mainOption {
    font-weight: 600;
    font-size: 0.875rem;
  }

  .subOption {
    font-size: 0.625rem;
  }

  .highlight {
    color: $brand;
    font-weight: 600;
  }

  :global {
    .ant-select-item-option-content {
      flex-wrap: wrap;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}

.hideNotFountContent {
  :global {
    .ant-select-item-empty {
      display: none;
    }
  }
}
