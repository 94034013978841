@import "src/assets/scss/variables";
@import "src/Splice/scss/spectrum";

.container {
  width: 129rem;
  .col {
    height: 100vh;
  }
  .left {
    background-color: $ghost-white;
    text-align: center;
    padding: 0 48px;
    .icon {
      display: inline-block;
      margin-bottom: 48px;
    }
    .title {
      margin: 96px auto 48px;
    }
    .subtitle {
      margin-bottom: 48px;
    }
    .file-name {
      margin-bottom: 24px;
    }
    .change-file {
      margin: 0 auto 36px;
      cursor: pointer;
      user-select: none;
      display: block;
    }
    .download-file:link {
      color: $blue;
    }
  }
  .right {
    .total-amount {
      margin-bottom: 8px;
      color: $pure-black;
      .color-black {
        font-weight: 700;
        color: $pure-black;
      }
    }
    .total-fee {
      margin-bottom: 24px;
      color: $pure-black;
      line-height: 1.375rem;
      .color-black {
        font-weight: 700;
      }
      img {
        vertical-align: middle;
      }
      p {
        margin-top: 0.25rem;
      }
    }
    .error-notif {
      color: $error-warning;
    }
    position: relative;
    .overflow {
      background-color: $white;
      padding: 48px 0;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      height: 100vh;
      .header {
        position: relative;
        overflow-x: hidden;
        width: 100%;
        padding: 0 48px;
        .show-info {
          position: absolute;
          right: 48px;
          bottom: 0;
        }
      }
      .body {
        user-select: none;
        padding-left: 48px;
        overflow-x: auto;
      }
    }
    .title {
      margin-top: 48px;
    }
    .title,
    .pay-from {
      margin-bottom: 36px;
    }
    .subtitle {
      margin-bottom: 24px;
      .color-black {
        color: $pure-black;
      }
      .error-warning {
        color: $orange-1;
        margin-left: 6px;
        user-select: none;
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 8px;
        border: $orange-1 1px solid;
        .icon {
          display: inline-block;
          margin-right: 8px;
          margin-top: -5px;
          vertical-align: middle;
        }
      }
    }
    .table-row-invalid {
      background: $invalid-form-light !important;
      border-radius: 8px;
    }
    .table-row-invalid:hover > td {
      background: $invalid-form-light !important;
    }
    .incorrect-row-data {
      color: $invalid-form;
    }
    .approval-button {
      position: fixed;
      bottom: 24px;
      right: 24px;
    }
    .table-column {
      min-width: 250px;
    }
    .table-row {
      border-radius: 8px;
    }
    .table-column-error-count {
      min-width: 50px;
    }
    .table-column-row {
      width: 96px;
    }
    .error-count {
      display: inline-block;
      font-size: 12px;
      border-radius: 50%;
      background-color: $dark-yellow;
      color: $white;
      padding: 3px;
      margin: 0 4px;
      min-width: 24px;
      height: 24px;
      line-height: 20px;
      text-align: center;
      user-select: none;
    }
    .smaller-font {
      font-size: 10px;
    }
    .incorrect-row-data-with-tooltip {
      color: $invalid-form;
      text-decoration: underline;
      user-select: none;
      cursor: pointer;
    }
    .wallet-disabled-alert {
      padding: 12px 0;
      text-align: left;
    }
    .insight {
      display: flex;
      justify-content: space-between;

      .successIcon {
        color: $green;
      }
      .suggestionIcon {
        color: $spectrum-blue-1;
      }
    }
  }
  .payFull {
    background: $blue-0;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;

    p {
      line-height: 1.6;
      color: $black-030;

      &:first-child {
        color: $pure-black;
      }
    }

    :global {
      .spm-checkbox {
        cursor: pointer;

        &:not(.checked) {
          background-color: $white-0;
        }
      }
    }
  }
}
.aryadana {
  width: 100%;
  padding: 1.875rem;
  margin-top: 1.875rem;
}
.flex {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
