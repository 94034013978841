@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum";

.flow-approver {
  width: 380px;
  .flow-approver__add-btn {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    width: 100%;
    color: $orange;
    background: $peach;
    text-align: center;
    padding: 8px;
    border: 0px;
    border-radius: 8px;
    height: auto;

    &:disabled {
      background: $light-gray;
      color: $white;
    }
  }
  .flow-approver__warning {
    background: $zircon;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    color: $gray;
    margin-top: 8px;
    .flow-approver__warning__icon {
      filter: brightness(50%);
    }
    .flow-approver__warning__message {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }
  .approver-reminder {
    color: $blue;
    background: $blue-20;
    .kb-article-link {
      color: $blue-80;
      cursor: pointer;
    }
  }
  .manager__warning {
    color: $red;
    background: $red-20;
    .warning-heading {
      .warning-text {
        span {
          color: $blue-80;
          cursor: pointer;
        }
      }
    }
  }
  .flow-approver__warning {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    line-height: 1.313rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    img {
      margin-right: 0.5rem;
    }
  }
}

.flow-approver__select__option {
  display: flex;
  align-items: center;
  gap: 12px;
  .flow-approver__option__icon {
    background: $peach;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: $orange;
    font-size: 14;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flow-approver__option__label__inactive {
    color: $red-80;
  }
  &--warning-text {
    padding-top: var(--spacing-002);
    color: var(--red-050);
  }
}
