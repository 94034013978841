@import "src/assets/v1.1/scss/spectrum";

.recipientFormModal {
  padding: 1.5rem 1rem !important;
  width: 52.25rem;

  &Content {
    padding: 1rem 2rem 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
    max-height: calc(100vh - 14rem);
    overflow-y: auto;
  }

  &Footer {
    border-top: 0.063rem solid $black-20;
    padding: 1.5rem 2rem 0;
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 1rem;
    }
  }

  .hide {
    display: none;
  }

  .recipientForm {
    background-color: $black-30;
    padding: 1rem;
    border-radius: 0.25rem;

    &Note {
      margin-top: 2rem;
      margin-bottom: 2rem;
      color: $black-70;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    &Actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row-reverse;
    }

    :global {
      .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        background: $white;
      }

      ::placeholder {
        color: #c6c6c6;
      }

      .ant-select {
        font-size: 1rem;

        input {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .ant-input-affix-wrapper {
        padding: 0;
      }

      .ant-input-suffix {
        margin-right: 1rem;
      }

      input.ant-input {
        height: 3rem;
        padding: 0 1rem;
      }

      .ant-input {
        border-radius: 0.25rem;
      }

      .tag-input .ant-select-selector {
        border-radius: 0.25rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .ant-select-selection-placeholder {
          left: 1rem;
          right: 1rem;
        }

        input {
          padding-left: 0;
          padding-right: 0;
          margin-left: 0.125rem;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-label > label {
        margin-top: 32px;
        font-size: 0.875rem;
        color: $black-90;

        &[for="vendorName"] {
          margin-top: 0 !important;
        }
      }
    }
  }

  .linkButton {
    color: $blue-80;
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;

    &Disabled {
      color: $red-40;
      display: flex;
      gap: 0.625rem;
      cursor: auto;
    }
  }

  .lastModifiedAndCreatedBy {
    display: flex;
    padding: 1.5rem;
    margin-bottom: 2rem;
    background: $black-10;
    border-radius: 0.5rem;

    & > div {
      width: 100%;

      & > div:first-child {
        margin-bottom: 0.625rem;
      }
    }
  }

  :global {
    .ant-form-item-label label:after {
      display: none;
    }
  }
}

:global {
  .notification-text {
    word-break: break-word;
  }
}
