.search {
  border: 1px solid var(--border-neutral-default);
  // The variable here are not available on splice
  border-radius: 0.375rem;
  height: 2.5rem;
  width: 13.5rem;

  :global(.search) {
    background-color: var(--white-0);
    height: 2.375rem;
    max-width: none;

    :global(.search__close) {
      display: flex;
    }
  }

  :global(.search__input-field) {
    background-color: var(--white-0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: var(--font-size-0);
  }
}
