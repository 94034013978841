.select-members-from-team {
  min-width: 280px;
  padding: 40px 0 0 24px;
  border-right: 1px solid #f4f6f9;
  &--error {
    font-size: 12px;
    color: #ff4d4f;
  }
  &__dropdown {
    margin-top: 20px;
    width: 92%;
  }
}
