@import "../../../../assets/scss/variables.scss";

.spm-label {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: $black;
}
