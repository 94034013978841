@import "src/assets/scss/variables";

.title {
  padding: 100px 0 48px;
  text-align: center;
}
.ddl {
  background-color: $white;
}
.download {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.instruction {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-bottom: 24px;
  color: $dark-grey-2;
}
.wallet-disabled-alert {
  padding: 12px 95px 0;
  text-align: left;
}

.current-template {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.41px;
  }

  & > p {
    font-weight: 400;
  }

  & > strong {
    color: $dark-grey-2;
    margin: 0 5px;
  }

  .icon {
    display: flex;
  }
}

.error-inform {
  background-color: #fffbee;
  border-radius: 8px;
  padding: 16px;
  width: 600px;
  margin: 0 auto 24px;
  text-align: center;
  display: flex;

  .error-icon {
    padding: 4px;
    img {
      height: 18px;
      width: 18px;
    }
  }

  .error-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 10px;

    .error-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: hsl(14, 92%, 24%);
      margin: 0;
      margin-bottom: 4px;
    }

    .error-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #752005;
      margin: 0;
    }

    .error-button {
      color: #1890ff;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      min-height: unset;
    }
  }
}

.template-title {
  display: flex;
  justify-content: center;
  align-items: center;

  .new-badge {
    border: none;
    margin-left: 8px;
    background-color: $red-2;
    padding: 4px 8px;
    border-radius: 8px;
    width: 42px;
    height: 24px;
    color: $white;
    font-size: 12px;
    line-height: 16px;
  }
}

.aryadana {
  width: 46.875rem;
  padding: 1.875rem;
  margin: 1.875rem auto 0;
}
.flex {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
