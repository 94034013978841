.formItem {
  + .formItem {
    margin-top: var(--spacing-024);
  }

  .label {
    margin-bottom: var(--spacing-012);
  }

  :global {
    .ant-picker,
    .ant-select .ant-select-selector {
      border-radius: var(--radius-s);

      input {
        font-size: 0.875rem;
        line-height: var(--size-010);
        color: var(--text-title-default);

        &::placeholder {
          font-size: 0.875rem;
          line-height: var(--size-010);
          color: var(--text-body-disabled);
        }
      }
    }

    .ant-picker {
      .ant-picker-clear {
        width: 1rem;
        height: 1rem;

        .anticon-close-circle,
        svg {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}
