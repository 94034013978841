@import "src/assets/v1.1/scss/spectrum";
@import "src/Splice/scss/spectrum";

.payFull {
  margin-top: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  background-color: $blue-0;

  :global {
    .ant-checkbox-wrapper {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $black-90;
    }
  }
}

.fxNote {
  padding: 0.5rem;
  color: $black-015;
  background-color: $dark-navy-007;
  border-radius: 0.25rem;
}

.tdUnderline {
  text-decoration: underline;
}

.receiverLabel {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  font-size: 0.75rem;
  font-weight: 400;
}
