@import "src/Modules/DS/Chip/Chip.module.scss";
@import "src/assets/v1.1/scss/spectrum";

.container {
  @include chip-container;

  &.failed {
    @include failed;
  }

  &.attention {
    @include attention;
  }

  &.success {
    @include success;
  }

  &.info {
    @include info;
  }

  &.neutral {
    @include neutral;
  }

  &.strong {
    @include strong;
  }

  & > button {
    background: transparent;
    margin: 0;
    padding: 0;

    cursor: pointer;
  }

  & .remove {
    padding: 0;
    width: 1rem;
    height: 1rem;
    background: $blue;

    mask: url("../../../../assets/v1.1/icons/Navigation/Cross/line_16.svg");

    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
}
