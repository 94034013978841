@import "src/assets/v1.1/scss/spectrum.scss";

.date-quick-filter-content {
  .ant-picker.date-picker__date {
    background: none;
    width: auto;
    border: none;
    padding: 0;

    .ant-picker-focused {
      box-shadow: none;

      :hover {
        border: none;
      }
    }
  }

  .ant-picker-suffix,
  .ant-picker-clear {
    position: absolute;
    right: 0.875rem;
  }

  .date-quick-filter-range {
    display: flex;
    gap: 0.75rem;

    padding: 0 2rem 1rem;
    align-items: center;
    justify-content: space-evenly;

    input {
      font-size: 0.875rem;
      padding: 0.3125rem 1rem;
      height: 2.625rem;
      border-radius: 0.25rem;
      border: 0.063rem solid $black-50;

      &:focus {
        border: 0.063rem solid $blue-80;
        box-shadow: 0 0 0 0.125rem rgba(30, 151, 238, 0.2);
      }

      &:hover {
        border: 0.063rem solid $blue-80;
      }
    }

    ::placeholder {
      color: $black-50;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control-input-content {
      display: flex;
      gap: 0.75rem;

      .ant-input-number-input {
        font-size: 0.875rem;
        padding: 1.25rem 1rem;
      }
    }
  }

  .error {
    margin: 0 0 1rem;
    padding: 0 2.7rem;
    display: flex;
    color: $red-80;
  }

  & .date-filter-actions {
    margin: 1rem 0 0;
  }
}
