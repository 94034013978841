@mixin transition {
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex_align_center($gap: 0) {
  display: flex;
  align-items: center;

  @if $gap != 0 {
    gap: $gap;
  }
}

@mixin flex_column($gap: 0) {
  display: flex;
  flex-direction: column;

  @if $gap != 0 {
    gap: $gap;
  }
}

@mixin size_008 {
  width: var(--size-008);
  height: var(--size-008);
}

@mixin border {
  border: 1px solid var(--border-neutral-default);
  border-radius: var(--radius-s);
}

@mixin border_hover {
  border-color: var(--text-link-default);
  box-shadow: none;
}

// Keeping this change local to the splice component in order to distinguish between the content and header differences.
.form {
  :global(.sp-sidepanel-header) {
    background-color: var(--fill-brand-navy);
  }
}
