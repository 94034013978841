@import "src/Modules/DS/Chip/Chip.module.scss";
@import "src/assets/v1.1/scss/spectrum";

.indicator {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
}

.container {
  @include chip-container;

  &.failed {
    @include failed;

    & > .indicator {
      background: $red-80;
    }
  }

  &.attention {
    @include attention;

    & > .indicator {
      background: $yellow;
    }
  }

  &.success {
    @include success;

    & > .indicator {
      background: $green;
    }
  }

  &.info {
    @include info;

    & > .indicator {
      background: $blue;
    }
  }

  &.neutral {
    @include neutral;

    & > .indicator {
      background: $black-90;
    }
  }

  &.secondary {
    background: transparent;
    border: none;
  }
}
