@import "src/assets/v1.1/scss/spectrum";
@import "src/Splice/scss/spectrum";

.kyxLoader {
  padding: 0 !important;
  background: none !important;
  font-family: "Public Sans", sans-serif;

  .dialog {
    background-color: $white;
    border-radius: 1.5rem;
    width: 18.3125rem;
    height: 15.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > div {
      width: 3rem;
      height: 3rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }

    .title {
      color: $black-100;
      font-weight: 600;
      margin-bottom: 0.25rem;
      margin-top: 1.75rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .subtitle {
      color: #666b79;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
}
