@import "src/assets/v1.1/scss/spectrum";

.container {
  text-align: center;

  white-space: pre-line;

  line-height: 1.375rem;
  color: $black-70;
}
