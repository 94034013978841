@import "src/assets/v1.1/scss/spectrum";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  &-vertical {
    display: block;
  }
}

.label {
  display: flex !important;
  align-items: center;

  // tooltip icon
  & > div:last-child {
    line-height: 0;
    margin-left: 0.375rem;
  }
}

.value {
  color: $black-90;

  .loading {
    height: 0.5rem;
    width: 8.75rem;
    overflow: hidden;
    border-radius: 0.25rem;
  }
}

.hide {
  display: none;
}

.message {
  font-size: 0.75rem !important;
  line-height: 1rem;
  margin-top: 0.5rem;
  color: $black-70;
}
