.Steps {
  .Step {
    font-size: 0.875rem;
    font-weight: 600;
    margin-top: -0.313rem;
    width: 2rem;
    height: 2rem;
    color: var(--dark-orange-050);
    background-color: var(--border-neutral-subtle);
    border-color: var(--border-neutral-subtle);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global {
    .ant-steps-item-container > .ant-steps-item-tail {
      padding: 3.5px 0px;
      margin-left: 4.125rem;

      &::after {
        background: var(--white-060) !important;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-icon {
        & > div {
          color: var(--white-070);
        }
      }
    }

    .ant-steps-item-title {
      color: var(--black-015) !important;
    }

    .ant-steps-item-process {
      .ant-steps-item-title {
        font-weight: 600;
      }
    }
  }
}
