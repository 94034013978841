.modal {
  width: 32.5rem;
}

.image {
  margin: var(--spacing-016) auto var(--spacing-024) auto;
  width: 100%;
  height: 7.5rem;
}

.text {
  text-align: center;
}

.buttonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--spacing-016);
  margin-top: var(--spacing-032);
}
