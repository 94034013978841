.team-profile {
  .members_table {
    padding: 0;
  }
  & &__policies {
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 2.125em;
      color: #000000;
      margin-top: 20px;
      margin-left: 0;
    }
    .ant-form-item-label > label {
      font-weight: 600;
      line-height: 1.25em;
    }
    .ant-input {
      max-height: 52px;
      border-radius: 4px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__members-add {
    margin-top: 30px;
    border: 1px dashed #c5c7cd;
    border-radius: 4px;
    color: #c5c7cd;
    font-size: 18px;
    font-weight: normal;
    padding: 30px;
  }
  &__form-col {
    float: left;
    width: 49%;
    margin-right: 1%;
    &:nth-of-type(even) {
      margin-left: 1%;
      margin-right: 0;
    }
  }

  &__button-row {
    margin-top: 30px;
    .button {
      &:first-child {
        min-width: 180px;
        font-size: 14px;
      }
    }
    .ant-btn {
      border: 0;
      color: #666b79;
      &:hover {
        color: #e5304c;
      }
    }
  }
  &--delete-btn {
    font-weight: bold;
    color: #e5304c;
    margin-left: 30px;
    font-size: 16px;
    background: none;
    span {
      margin-right: 10px;
    }
  }
  &--delete-btn:hover {
    background-color: transparent;
  }
  .ant-picker {
    margin-top: 8px;
    margin-bottom: 12px;
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 7px 16px;
    font-size: 16px;
    height: auto;
  }
  .ant-form-horizontal {
    display: flex;
    flex-direction: column;
  }
}
