@import "src/assets/scss/variables.scss";

.filter-popover {
  position: relative;
  min-width: 240px;

  &.team-pop-over {
    min-width: 304px;
  }
}

.filter-popover--has-action {
  padding-bottom: 49px;

  &.multi-selection-option {
    padding: 0;
    max-height: 268px;
    height: unset;
    overflow: auto;
    .filter-popover__list {
      overflow: unset;
      max-height: unset;
    }
  }
}

.filter-popover__search {
  background: none;
  margin-bottom: 4px;
  * {
    background: none;
  }
}

.search__icon .icon__image {
  width: 12px;
  height: 12px;
}

.filter-popover__list {
  padding: 4px 0;
  max-height: 268px;
  overflow-y: auto;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .ant-checkbox-wrapper {
    font-size: 14px;
    padding: 12px 16px;
    &:hover {
      background-color: $alice-blue;
    }
  }
  .ant-checkbox-inner::after {
    background: $orange;
  }

  li {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    &:hover,
    &.selected {
      background-color: $alice-blue;
    }
  }
}
.filter-popover__action {
  position: sticky;
  padding: 0 16px;
  background: $white;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $solitude;
}

.popout__overlay .ant-popover-inner-content {
  padding: 0;
}

.filter-popover__action__button--submit {
  white-space: nowrap;
  padding: 0;
  width: 79px;
  height: 32px;
  min-width: unset;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  &:hover,
  &:focus {
    border: 1px solid $orange;
    background: none;
    color: $orange;
  }
}

.filter-popover__action__button--clear {
  color: $gray;
}

.ant-popover {
  transform: none;
}
.ant-popover-inner {
  border-radius: 4px;
}
.ant-popover-arrow {
  display: none;
}

.pop-over__team-list {
  width: 304px;
}
