.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  min-height: 90vh;
  & > h4 {
    font-size: 20px;
    line-height: 150%;
    font-weight: 600;
    color: #13162e;
  }
  .img-comingsoon {
    margin-bottom: 5%;
  }
  & &__back-btn {
    background-color: #f65731;
    padding: 15px 50px;
    border-radius: 30px;
    color: #fff;
    font-weight: 600;
    margin-top: 40px;
    &:hover {
      background-color: #f53a0e;
      transition: background-color 1s;
    }
  }
  &--comingsoon {
    height: calc(100% - 72px);
  }
}
