@import "src/assets/scss/variables";

.container {
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    > div {
      position: fixed;
      top: 50%;
      right: 22.5%;
      transform: translateX(-50%);
      border: 2px solid $orange-3;
    }
  }
  .boxed {
    background: $zircon;
    border-radius: 4px;
    padding: 16px;
    margin-top: 32px;
  }
  .amount {
    width: 100%;
  }
  .wallet,
  .date-picker {
    width: 100%;
    border-radius: 8px;
  }
  .optional {
    color: $light-gray-3;
    font-size: 0.9rem;
    margin-left: 0.313rem;
  }
  .spacing {
    height: 48px;
    display: block;
  }
  .categorization {
    font-size: 14px;
  }
  .invalid {
    border-color: $invalid-form;
  }
  .wallet-disabled-alert {
    padding-top: 8px;
  }
  .slaLabel {
    color: $dark-gray-2;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .slaValue {
    font-size: 1rem;
  }
  .youPaySection {
    color: $black;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;

    .youPayLabel {
      display: flex;
      align-items: flex-end;
      gap: 0.25rem;

      > div {
        height: 1.1rem;
      }
    }
    .youPayAmount {
      padding-right: 1rem;
    }
  }

  .form-item-ocr {
    display: flex;
  }
  .tooltip {
    margin-left: 0.625rem;
    margin-top: 0.25rem;
    overflow: visible;
  }

  :global(.custom-loader) {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.213rem solid $orange-3;
    border-right-color: transparent;
  }
}
