@import "../../assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum";

.search {
  max-width: 346px;
  height: 48px;
  background: $light-gray-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 0 12px;

  &:hover,
  &:focus-within {
    outline: 0.063rem solid $blue-80;
  }
}
.search__close {
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 12px;
  }
}
.search__input-field {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0 10px;
  background: $light-gray-2;
  font-size: 16px;
  line-height: 24px;
  color: $dark-gray-2;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &::placeholder {
    color: $light-gray;
  }
}
