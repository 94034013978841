@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spaces.scss";

.tableCSS {
  thead > tr > th {
    background-color: white !important;
    padding: 0 !important;
    border: none;
    height: 2rem;

    font-weight: 400 !important;
    font-size: 0.625rem;
    line-height: 1.6 !important;
    color: $black-70 !important;
    text-align: center !important;

    &:first-child {
      text-align: left !important;
    }

    &:last-child {
      text-align: right !important;
    }
  }

  // tbody > tr:not(:first-child) > td {
  tbody > tr > td {
    border-bottom: 0.0625rem solid $black-20 !important;

    background-color: white;
    padding: 0 !important;
    border: none;
    height: 3rem;
    line-height: 3rem;
    text-align: center !important;

    font-weight: 400 !important;
    font-size: 0.75rem !important;
    color: $black-80 !important;

    &:first-child {
      text-align: left !important;

      font-weight: 600 !important;
      font-size: 0.75rem !important;
      color: $black-90 !important;
    }

    &:last-child {
      text-align: right !important;
    }
  }
}

.analyticCard {
  height: 24.75rem;
  overflow-y: hidden;
}

.analyticsTitle {
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.75rem;
    font-weight: 400;
    color: $black-70;
  }
}

.totalSpending {
  display: flex;
  align-items: center;

  & > span {
    &:first-child {
      font-size: 0.75rem;
      color: #6c6c6c;
      font-weight: 400;
      margin-right: 0.375rem;
    }

    &:last-child {
      color: #0f0f0f;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.tableHead {
  padding-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
  text-align: right;
}

.tableCell {
  display: flex;
  align-items: center;
  position: relative;

  & svg {
    margin-right: 0.25rem;
    width: fit-content;
    flex-shrink: 0;
  }
}

.tableLastCol {
  justify-content: flex-end;
}
