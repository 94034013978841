@import "src/assets/v1.1/scss/spectrum.scss";

.side-section-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $black-100, $alpha: 0.4);
  display: flex;
  flex-direction: row-reverse;
  overflow: scroll;
}

.side-section {
  flex: 0 0 28.75rem;
  padding: 0 2.5rem;
  height: -webkit-fill-available;
  background: $black-90;
  &__close {
    padding: 1.25rem 0;
    margin-left: auto;
  }
  &__content {
    display: flex;
    height: calc(100% - 4.5rem);
    flex-flow: column;
    scrollbar-gutter: stable both-edges;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    opacity: unset;
  }
  .ant-select-arrow,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .section-options__title {
    color: $black-40;
  }
}
