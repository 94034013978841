@import "src/assets/v1.1/scss/spectrum";

.textfield {
  :global {
    .ant-btn {
      font-size: 0.875rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .ant-btn-link {
      color: $blue-80;
    }
  }
}
