/**
 * @fileoverview This module provides scroll styling for the main layout of the application.
 * The layout dynamically adjusts based on CSS custom properties, which gives it flexibility
 * to adapt to different screen sizes and configurations.
 *
 * @author Bakshi
 * @created 24 Aug 2023
 * @lastModified 11 Sept 2023
 */
 @import "src/assets/scss/mixins.scss";

// Styles for containers that allow both horizontal and vertical scrolling
.scroll {
  @include scrollbar;
  @include scrollbarCorner;
  overflow-x: auto;
  overflow-y: auto;
}

// Styles specific to horizontal scrolling containers
.scrollXAxis {
  @include scrollbar;
  overflow-x: auto;
  overflow-y: hidden;
}

// Styles specific to vertical scrolling containers
.scrollYAxis {
  @include scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
}
