@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  padding: 1rem;

  box-shadow: 0rem 0rem 0.25rem rgba(51, 55, 68, 0.08);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > .title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: -0.01em;
    color: $dark-gray-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > .content {
    flex: 1;
  }

  & > .equalPartition {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }
}
