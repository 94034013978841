@import "src/assets/scss/variables.scss";

.policy-form {
  &-wrapper {
    width: 100%;
    margin-top: 15px;

    &__interval {
      float: left;
      padding: 15px 15px;
      border-radius: 12px;
      color: #131a2e;
      cursor: pointer;
      border: none;
    }

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.24px;
      color: #131a2e;
    }

    .interval-container {
      width: 190px;
      display: flex;
      justify-content: space-between;
    }

    .active {
      background-color: #e6f4ff;
      color: #1e97ee;
    }

    .team-limit__input {
      width: 300px;
      border-color: $white;

      &:focus {
        border-color: $orange-2;
      }

      &:disabled {
        background-color: $white-1;
        color: rgba(0, 0, 0, 0.25);
        border: none;
        cursor: not-allowed;
      }
    }

    .policy-header-text {
      margin-top: 10px;
      margin-bottom: 54px;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.24px;
      color: #333744;
    }

    .policy-footer-text {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.08px;
      color: #131a2e;
      margin-top: 12px;
    }

    .policy-footer-text-warning {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.08px;
      color: #e5304c;
      margin-top: 12px;
      margin-bottom: 24px;
    }

    .currency-section {
      display: inline-flex;
      margin-top: 16px;
    }

    .currency-label {
      width: 100px;
      height: 55px;
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 6px;
      margin-right: 10px;
      display: flex;
      padding: 15px;
      align-items: center;
    }

    .policy-action-btn {
      margin-top: 40px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}
