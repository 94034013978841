@import "src/assets/v1.1/scss/spectrum.scss";

.text {
  display: block;
  color: $black-90;
}
.normal {
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
}
.label {
  font-size: 0.75rem;
}
.image {
  > img {
    border-radius: 0.625rem;
    max-width: 22.5rem;
  }
}
.spacing {
  height: 6.25rem;
}
