@import "src/assets/v1.1/scss/spectrum";

.container {
  width: 33.75rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .icon {
    margin: 2.75rem 0 1rem;
    width: 7.5rem;
    height: 7.5rem;
  }
  .content > p {
    color: $black-80;
    margin-bottom: 1.875rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
  }
}
.actions {
  display: grid;
  grid: auto / 1fr 1fr;
  grid-gap: 1.25rem;
  width: 100%;

  button {
    padding: 1rem;
    border-radius: 2.5rem;
    border: 1px solid $brand;
  }
  .cancel {
    background: none;
    color: $brand;
  }

  .confirm {
    background: $brand;
    color: $white;
  }
}

.disclaimer {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.75rem;
  letter-spacing: 0.00625rem;
  margin-top: 0.75rem;
}
