.attachment {
  &:global {
    &.ant-upload.ant-upload-drag {
      padding: 0 var(--spacing-032) var(--spacing-016) var(--spacing-032);
      border-radius: var(--radius-xl);
      border: 1px dashed var(--border-neutral-default);
      background-color: var(--white-0);

      .ant-upload {
        padding: 0;
      }
    }
  }
}

.desc {
  color: var(--text-title-default);
}

.highlight {
  color: var(--bright-orange-050);
}

.caption {
  color: var(--text-caption-default);
}

.fileContainer {
  width: 19.5rem;
  margin: 0 auto;
  padding: var(--spacing-024) 0 var(--spacing-016);
}

.fileList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--spacing-008);
  row-gap: var(--spacing-016);
}

.fileItem {
  position: relative;
  min-width: 143px;
  text-align: center;

  .fileImg {
    display: block;
    width: 100%;
    box-shadow: var(--elevation-lifted);
    height: 10.875rem;
    margin-bottom: var(--spacing-008);
    border-radius: var(--radius-m);
    overflow: hidden;

    :global {
      .react-pdf__Page__canvas {
        margin: 0 auto;
        width: auto !important;
        max-width: 19.5rem;
        max-height: 10.875rem;
      }
    }
  }

  img {
    display: block;
    width: auto;
    margin: auto;
    height: inherit;
    max-width: 100%;
  }

  .fileName {
    color: var(--text-title-default);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .fileInfo {
    color: var(--text-caption-default);
  }

  .dotDivider {
    margin: 0 var(--spacing-004);
  }

  .deleteIcon {
    position: absolute;
    top: var(--spacing-008);
    right: var(--spacing-008);
    width: var(--size-016);
    height: var(--size-016);
    padding: var(--spacing-008);
    border-radius: 50%;
    box-shadow: var(--elevation-lifted);
    background-color: var(--white-0);
  }
}

.addMoreFiles {
  width: 100%;
  margin-top: var(--spacing-024);
}
