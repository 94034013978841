@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";

.transactions {
  display: flex;
  height: calc(100% - 4.5rem);

  .recursive-table__container {
    padding: 48px 1.5rem;
  }

  &--side-section {
    padding: 0;
    width: 28.75rem;
    border-left: $black-40;
    background: $black-10;
    height: 100%;
    z-index: 10;
    overflow-y: auto;
    scrollbar-gutter: stable;
    .table-row-field {
      &__details,
      &__title--bold {
        color: $black-100;
      }
      &__title {
        color: $black-70;
      }
    }
    .side-section__content {
      padding: 2.5rem;
    }
    .side-section__close {
      padding: 0;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      z-index: 10;
      img.icon__image {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
  &__fields {
    &__title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $black-100;
    }
  }
  .ant-table {
    font-size: 0.875rem;
  }
  .ant-table-tbody > tr.transactions--selected-row > td,
  .ant-table-tbody > tr.transactions--selected-row:hover > td {
    background: $selected-row !important;
  }
  .ant-table .ant-table-thead > tr > th,
  .ant-table .ant-table-tbody > tr > td {
    padding: 1.5rem 0.375rem;
    overflow-wrap: break-word;
    max-width: 15rem;

    .transaction-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .image-carousel--icon .icon__title {
      font-size: 0.875rem;
    }
  }
  .ant-select-selection-item {
    color: $black;
  }
  .recursive-table__container__header {
    padding-bottom: 0.625rem;
    & > div {
      padding: 0.625rem 0;
    }
    .button:first-child {
      margin-right: 0.75rem;
    }
  }
  & &__invoices {
    padding-left: 2.5rem;
    &__status {
      &-color {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        &--light-orange {
          background: $light-orange;
        }
        &--green {
          background: $green;
        }
        &--blue {
          background: $blue;
        }
        &--purple {
          background: $purple;
        }
        &--red {
          background: $red;
        }
      }
    }
  }
  &__actions-column.ant-table-cell {
    background: inherit !important;
    padding-left: 1rem !important;
  }
  &__actions-column.ant-table-cell::after {
    box-shadow: inset -0.625rem 0 0.5rem -0.5rem rgba($black-100, 0.06);
  }
  &.scheduled {
    .ant-pagination {
      background: none;
      li,
      button {
        background: none;
      }
    }
    .ant-table {
      background: none;
      .ant-table-thead > tr > th > .transactions-list__table-header__tooltip__container {
        display: flex;
        cursor: pointer;
      }
      .ant-table-content > table {
        border-collapse: separate;
        border-spacing: 0 0.625rem;
        .ant-checkbox-inner:after {
          display: block;
        }
        .ant-table-thead,
        .ant-table-thead tr th {
          background: none;
          border: none;
          padding-top: 0;
          padding-bottom: 0.25rem;
        }
        .ant-table-tbody {
          tr {
            background: $white;
            box-shadow: 0 0 1.25rem 0 rgba($pure-black, 0.06);
            border-radius: 0.625rem;
            td {
              background: none;
              font-size: 0.875rem;
              color: $dark-gray-2;
              &.transactions__item--big {
                font-size: 1rem;
              }
              .loading-wrapper {
                padding: 0;
                width: 12.5rem;
              }
            }
            td:first-child {
              border-top-left-radius: 0.625rem;
              border-bottom-left-radius: 0.625rem;
            }
            td:last-child {
              border-top-right-radius: 0.625rem;
              border-bottom-right-radius: 0.625rem;
            }
            &:hover {
              background: $selected-row;
            }
          }
        }
      }
      .action-buttons {
        width: 12.5rem;
        display: flex;
        button {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          border-radius: 1.875rem;
          font-size: 1rem;
        }
        .action-buttons__button--stop {
          color: $red;
        }
        .action-buttons__button--stop:hover {
          color: inherit;
        }
      }
    }
  }

  &--additional-info {
    background-color: $light-blue;
    color: $blue;
    display: inline-block;
    padding: 1rem 1rem 1rem 1.875rem;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    &::after {
      content: " ";
      margin-left: 6.25rem;
      border: solid $blue;
      border-width: 0 0.25rem 0.25rem 0;
      display: inline-block;
      padding: 0.25rem;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}

.scheduled-bulk-actions {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 13.75rem;
  background-color: $zircon;
  box-shadow: 0 0 1.25rem 0 rgba($pure-black, 0.06);
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 4.5rem;
  .loading-wrapper {
    padding: 0;
    width: 12.5rem;
  }
  .scheduled-bulk-actions__message {
    display: block;
    margin-top: 0.625rem;
    margin-right: 12.5rem;
    font-size: 1rem;
    color: $pure-black;
  }
  .action-buttons button {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }
}

.transactions .ant-table .ant-table-tbody > tr > td:first-child,
.transactions .ant-table .ant-table-thead > tr > th:first-child {
  padding-left: 1.25rem;
}

.transaction-export-csv {
  align-items: center;
  & > img {
    margin-left: 0.625rem;
  }
  &__content {
    .button {
      background: none;
      color: $black-100;
      &:hover {
        color: $brand;
      }
      & img {
        max-width: 1.25rem;
        vertical-align: middle;
        margin-right: 0.625rem;
      }
    }
  }
}

.transactions--mobile {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $black-10;
  overflow-y: auto;

  .ant-tabs .ant-tabs-tabpane {
    padding: 1.5rem;
  }
}

.transaction-description-tooltip.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $black-90;
  }
}

@media screen and (max-width: 600px) {
  .container {
    display: block;
    min-height: 0;
    min-width: 0;
  }
}

.limit-exceeded-reminder {
  padding: 1rem;
  margin: 1.25rem auto;
  width: 100%;
  background-color: $black-20;
  border-radius: 0.5rem;
  display: flex;
  &__icon {
    margin-right: 0.5rem;
  }
  &__text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $black-80;
  }
}
