.modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 30;
  cursor: default;
  text-align: initial;
  &__content {
    align-self: center;
    background: #fff;
    padding-top: 40px;
    border-radius: 7px;
    position: relative;
    max-height: 95%;
    padding-bottom: 30px;
    overflow: auto;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #131a2e;
    text-align: center;
    margin-bottom: 0;
  }
  &__subtitle {
    line-height: 22px;
    color: #666b79;
    text-align: center;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 12px;
    height: 12px;
    padding-bottom: 20px;
  }

  .send-money-modal__icons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > div {
      padding: 20px;
    }
  }
  &-money {
    min-width: 200px;
    &__action-btns {
      display: flex;
      justify-content: space-between;
      padding: 46px;
      min-width: 350px;
      & > .icon {
        padding: 0 4px;
      }
    }
  }
}
