@import "variables";

.info-unit {
  display: flex;
  flex-direction: column;
  line-height: 24px;

  &__label {
    font-size: 12px;
    font-weight: 400;
    color: $dark-gray-3;
    margin-bottom: 8px;
  }

  &__value {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    align-items: flex-start;
  }
}
