@mixin tablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-right: var(--spacing-008);
}

.pagination {
  @include tablePagination;
}
