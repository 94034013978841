@import "src/assets/scss/variables.scss";

.send-money {
  &-btn {
    border: 1px solid #ececec;
    margin: 0 10px;
  }
}

.send-money__modal {
  .modal__content--children-elements {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
    .send-money__modal__container {
      display: flex;
      margin-top: 24px;
      border-top: 1px solid $light-gray-2;
      .sk-fading-circle {
        position: absolute;
        top: 50%;
        left: 50%;
      }
      .send-money__modal__form {
        label {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          color: $black;
        }
        .amount-details__wallet-label {
          border: 1px solid $solitude;
          border-radius: 4px;
          padding: 16px;
          position: relative;
          .amount-details__wallet-label__type {
            font-weight: 600;
            font-size: 16px;
            color: $black;
          }
          .amount-details__wallet-label__name {
            font-size: 14px;
            color: $neutral-black;
            position: absolute;
            right: 16px;
          }
        }
        .amount-details__select-amount {
          padding-top: 0px;
        }
        .send-money__modal__form-container {
          padding: 40px 40px 16px;
          &__title label {
            margin: 0px;
          }
          &__loader {
            top: 0px;
            left: 0px;
          }
        }
        .send-money__modal__schedule {
          padding: 16px 40px;
          &.open {
            background: $white-smoke;
          }
          & > * + * {
            margin-top: 16px;
          }
          .sp-datepicker {
            height: 48px;
            vertical-align: bottom;
            width: 190px;
            border: none;
            margin-right: 20px;
          }
          .detail-dropdown__button {
            width: 300px;
            vertical-align: bottom;
            height: 48px;
            border: none;
          }
          .schedule-retry {
            margin-left: 10px;
            margin-top: 10px;
          }
        }
        .send-money__modal__action {
          text-align: right;
          padding: 40px 40px 0px;
          button {
            background-color: $green;
            width: 250px;
            &.button--disabled {
              background-color: $light-gray;
              color: $white;
            }
          }
        }
      }
    }
  }
}
