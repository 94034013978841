@import "src/assets/v1.1/scss/spectrum.scss";

.invoice-number {
  .invoice-number-hint {
    margin-top: 0.512rem;
  }
  .banner-title {
    color: $yellow;
  }
  .banner-message {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .new-tab {
    display: inline-block;
    & > img {
      vertical-align: middle;
      padding-left: 0.375rem;
    }
  }
}
