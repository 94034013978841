/**
 * @fileoverview This module contains styles and utilities related to layout and design elements
 * for Sidebar such as containers, footers, and line separators. They provide grid configurations,
 * width settings, and reusable design patterns like line separators.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 */

/**
 * The `.container` class styles an element to follow a specific grid configuration.
 * It defines the grid column placement and style attributes for the grid, including border settings.
 */
.container {
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: end;

  border-right: var(--border-size-1) solid var(--border-neutral-subtle);

  // Grid display settings
  display: grid;
  gap: 0;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}

/**
 * `line-separator` mixin
 * This mixin provides a style pattern for elements that require a bottom border
 * to visually separate content. It defines margins and the border style using CSS variables.
 *
 * Example usage:
 * .exampleClass {
 *   @include line-separator;
 * }
 */
@mixin line-separator {
  margin: 0 var(--size-008) var(--size-008);
  border-bottom: var(--border-size-1) solid var(--border-neutral-subtle);
}
