@use "../Requirement.module.scss";

@import "src/assets/v1.1/scss/spectrum";

$_pending-item-gap: 1rem;
$_pending-item-icon-width: 1.5rem;

.action__list {
  padding: 0.75rem 1.25rem;
}

.container {
  @extend #requirement;

  .item {
    display: flex;
    flex-direction: row;
    gap: $_pending-item-gap;
    margin-bottom: 0.5rem;
    width: 100%;

    .item__icon {
      width: $_pending-item-icon-width;
      height: $_pending-item-icon-width;
      background-color: transparent;
      display: flex;

      & > img {
        padding: 0.125rem;
        cursor: default;
      }
    }

    .item__title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      line-break: anywhere;
      width: calc(100% - $_pending-item-gap - $_pending-item-icon-width);

      letter-spacing: -0.026rem;

      color: $black-100;
    }
  }

  & > .item__description {
    width: 100%;
    color: $black-100;

    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: -0.015rem;
  }

  & > .list__container {
    padding: 0.75rem 1rem;
    gap: 0.25rem;

    width: 100%;
    margin-top: 0.75rem;

    background: $white;
    border: 0.063rem solid #ededed;
    border-radius: 0.5rem;

    & > ul {
      list-style-type: disc;
      list-style-position: inside;
      width: 100%;

      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      letter-spacing: -0.005rem;

      color: $black-100;

      li::marker {
        color: $black-100;
      }

      & .action__title {
        font-weight: 700;
        margin-right: 0.25rem;
        display: inline-flex;
      }
    }

    & .view__more {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: -0.005rem;
      color: $black-70;
      margin-top: 0.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & > .action__button {
    margin: {
      top: 1rem;
      bottom: 0.75rem;
    }

    width: 100%;
    display: flex;
    justify-content: flex-end;

    & > button {
      padding: 0.625rem 1.063rem;
      min-width: auto;
      min-height: auto;
    }
  }

  & li.single__line {
    margin-bottom: 0.313rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & li.link__action > a {
    float: right;
  }

  & li.multi__line {
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    & > .subtitle {
      margin-left: 1.063rem;
    }
  }
}
