@import "src/assets/scss/variables.scss";
.otpAuthenticationModal {
  &__modal-container {
    position: fixed;
    .sp-modal__overlay-bg {
      background: rgba(0, 0, 0, 0.4);
    }
  }
  &__modal {
    position: fixed;
    left: 50%;
    top: 280px;
    width: 400px;
    min-height: 330px;
    transform: translateX(-50%);
    .sp-modal__header {
      padding: 24px 16px;
    }
    &--info-text {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-body-default);
      text-align: center;
      margin-bottom: 42px;
    }
    &--resend-info-text {
      color: $light-gray;
      margin: 24px 0px 0px;
    }
    &--deactivate-info-text {
      color: $dark-grey-2;
      margin-bottom: 16px;
    }
    &--grey-dot {
      width: 6px;
      height: 6px;
      background: $grey;
      border-radius: 50%;
      margin: 2px;
      display: inline-block;
    }
    &--deactivate-btn {
      width: 100%;
      height: 60px;
      font-size: 16px;
    }
    &--otp-input-form-error {
      margin: 12px;
      text-align: center;
      color: $red;
    }
    &--error-info-container {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      margin: 16px 0px;
      p {
        margin: 24px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
