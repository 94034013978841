@import "src/Modules/DS/Search/SearchMixin.module.scss";

.search__popover {
  @include searchBase(0.75rem 0.938rem 1rem);
  @include searchIcon(1.5rem);
  @include loader(1.5rem, 0.125rem);
  @include searchInput(1rem, 1.5rem);

  background: transparent;
}
