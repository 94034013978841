@import "src/assets/scss/variables.scss";

.activation-info {
  &__header {
    font-size: 28px;
    font-weight: bold;
  }
  &__fieldset {
    border: none;
    background: $zircon;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    margin-bottom: 32px;
    &--title {
      display: inline;
      background-color: $peach;
      border-radius: 50px;
      line-height: 34px;
      color: $orange;
      padding: 4px 20px;
      font-size: 16px;
      font-weight: bold;
    }
    &--legend {
      text-align: center;
    }
    & legend:last-of-type {
      padding: 28px 0px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    & legend {
      margin-bottom: 0px;
      border: none;
    }
    &--qr-code-container {
      min-height: 390px;
    }
  }
  &__qr-code {
    height: 270px;
    width: 270px;
    padding-bottom: 8px;
    & + p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      padding-top: 8px;
    }
  }
  &__error-message {
    font-size: 16px;
    font-weight: normal;
    color: $pure-black;
  }
  &__form {
    & .ant-form-item {
      display: flex;
      & .ant-form-item-label {
        display: flex;
        align-items: center;
        label {
          margin: 0;
          display: flex;
          font-weight: 600;
          font-size: 14px;
          color: $dark-grey;
        }
      }
      &.ant-form-item-with-help {
        margin-bottom: 16px;
      }
      & .ant-form-item-control-input {
        max-width: 180px;
        position: relative;
        & .ant-input {
          border-radius: 4px;
          height: 48px;
          border: 1px solid $solitude;
          font-weight: 600;
          font-size: 16px;
          &::placeholder {
            color: $light-gray;
            font-weight: normal;
          }
          &:hover:not(:disabled),
          &:active,
          &:focus {
            outline: 1px solid #1e97ee;
            border: 1px solid #1e97ee;
            box-shadow: none;
          }
        }
      }
      &.ant-form-item-has-error .ant-input {
        &:hover,
        &:active,
        &:focus {
          border-color: #eb5757 !important;
          outline-color: #eb5757 !important;
        }
      }
      & .ant-form-item-explain {
        position: absolute;
        bottom: -24px;
        div {
          text-align: start;
          white-space: nowrap;
          padding: 6px 0px;
          color: #eb5757;
        }
      }
    }
  }
  &__action-btn {
    & button {
      padding: 12px 28px;
      font-size: 16px;
      height: 56px;
    }
  }
}
