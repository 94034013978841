.add-team-members {
  &__modal-existing-members {
    height: 715px;
    .modal {
      &__title {
        padding-bottom: 24px;
        border-bottom: 1px solid #f4f6f9;
      }
      &__subtitle {
        padding-top: 20px;
        margin: 0 40px;
        font-size: 16px;
        line-height: 24px;
      }
      &__content--children-elements {
        overflow: hidden;
        margin-left: 40px;
        margin-right: 40px;
      }
    }
    .select-members {
      padding-left: 0;
      padding-top: 0;
      border-right: 0;
      &__search {
        padding-right: 0;
        .search {
          margin: 24px auto;
        }
      }
      &__members {
        max-height: 346px;
      }
    }
  }
  &__toaster {
    .message {
      text-align: left;
    }
  }
  &__footer {
    display: flex;
    margin: 35px auto 40px;
    justify-content: space-evenly;
    .button {
      font-size: 16px;
      line-height: 40px;
    }
  }
  &__confirm-btn {
    font-weight: 500;
    width: 240px;
  }
}
.team-members {
  .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: center;
  }
  .recursive-table {
    padding: 0 0 20px 0;
    &__container {
      padding: 0;
      max-height: 100%;
      &__header {
        padding-bottom: 0;
      }
    }
  }
  .modal__content {
    padding: 30px;
    &--children-elements {
      margin-top: 10px;
      .select-members__selected-members {
        display: none;
      }
    }
    .ant-form-item-control-input-content {
      text-align: center;
    }
    .ant-input {
      padding: 0;
      text-indent: 10px;
    }
  }
  .team-profile__members {
    text-align: right;
    & > .button {
      min-width: 160px;
    }
  }
  .member-edit-modal {
    padding: 44px;
    .modal__content--children-elements {
      display: flex;
      flex-direction: column;
      width: 150px;
      margin: auto;
      .button--secondary {
        background: none;
      }
    }
  }
  .modal__subtitle {
    margin-bottom: 15px;
    margin-top: 5px;
  }
}

.table-name-row {
  display: flex;
  align-items: center;
  .letter-avatar {
    margin-right: 16px;
  }
}
.table-action-row {
  button:hover .button:hover {
    visibility: visible;
  }
  .button {
    min-width: 0;
    min-height: 0;
    line-height: 1.5;
    padding: 8px;
  }
  .button:hover {
    border: 0;
    color: #fff5ed;
  }
  .promote-btn {
    color: #1fce8f;
    margin-right: 12px;
  }
  .promote-btn:hover {
    background-color: #1fce8f;
  }
  .promote-btn:visited {
    background-color: black;
  }
  .demote-btn {
    color: #fa8c16;
    margin-right: 12px;
    border-color: #ffd591;
  }
  .demote-btn:hover {
    background-color: #fa8c16;
  }
  .remove-btn {
    color: #eb5757;
  }
  .remove-btn:hover {
    background-color: #eb5757;
  }
  .clawback-btn:hover {
    background-color: #f65731;
  }
  .clawback-btn {
    margin-left: 10px;
  }
}
.delete-member-modal {
  border-radius: 12px;
  width: 440px;
  height: 260px;
  .modal__title {
    margin-bottom: 16px;
  }
  &__footer {
    display: flex;
    justify-content: space-evenly;
    .button {
      width: 190px;
      height: 60px;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
    }
    .cancel-btn {
      margin-right: 12px;
    }
  }
}
