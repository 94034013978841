@import "src/assets/v1.1/scss/spectrum";

.radio {
  :global {
    .ant-radio-inner:after {
      background-color: $brand;
    }

    .ant-radio {
      vertical-align: middle;

      & + span {
        font-size: 0.875rem !important;
      }
    }
  }

  &.hidden {
    display: none;
  }
}
