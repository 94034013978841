@import "src/assets/scss/variables";

.template-tooltip {
  max-width: 250px;
  .ant-tooltip-arrow:before {
    background-color: $grey;
  }

  .ant-tooltip-inner {
    background: $grey;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    font-size: 12px;
  }
}
