@import "src/assets/scss/variables.scss";

.zendesk-contact-form {
  min-width: 342px;
  min-height: 381px;
  max-width: 342px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 15rem;
  bottom: 30px;
  background: $white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  cursor: default;
  z-index: 2;
  .form-container {
    text-align: left;
    p,
    textarea {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $dark-gray-2;
    }
    & > p {
      padding: 23px 24px 0 24px;
    }
    & > .description-field {
      padding: 0 24px;
    }
    .ant-form-item-label > label {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
      color: $dark-gray-2;
    }
    textarea {
      resize: none;
      border-radius: 4px;
      min-height: 102px;
      &::placeholder {
        color: $light-gray;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .form-footer {
    padding: 15px 26px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    bottom: 0px;
    position: absolute;
    margin: 0;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
  .form-footer-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & > .button {
      background: $black;
      font-weight: normal;
      font-size: 14px;
      border-radius: 4px;
    }
  }
  .loading-wrapper {
    padding: 0 2.3rem 0 2.7rem;
    .dot-flashing,
    .dot-flashing::after,
    .dot-flashing::before {
      width: 8px;
      height: 8px;
    }
    .dot-flashing {
      &::after {
        left: 12px;
      }
      &::before {
        left: -12px;
      }
    }
  }
  &--success,
  &--error {
    padding-top: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      margin: 0 auto;
      padding: 10px 0;
      img {
        width: 180px;
        height: 100px;
        margin: 0 auto;
      }
    }
    .header {
      padding: 10px;
    }
    .button {
      color: $black;
    }
  }
}
