.loading-modal__wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 10;
  cursor: default;
  text-align: initial;
}

.loading-modal__content {
  align-self: center;
  background: #fff;
  padding: 18px 0;
  border-radius: 8px;
  position: relative;
  overflow: auto;
  min-width: 411px;
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #131a2e;
    text-align: center;
    border-bottom: 1px solid #f4f6f9;
    padding: 18px 0;
  }
  &__message {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #13162e;
    text-align: center;
    padding-bottom: 18px;
  }
}
