@import "src/assets/scss/variables";

.deleteModalContainer {
  width: 18.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .deleteModalIcon {
    margin: 1.875rem;
  }
  .deleteModalText {
    margin-bottom: 1.875rem;
  }
}
.deleteModalButton {
  border-radius: 1.875rem;
  width: 100%;
}
