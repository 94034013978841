.img {
  max-height: 42rem;
  object-fit: cover;
  object-position: center;
}

.imgWrapper {
  max-height: 42rem;
  overflow: unset;
  position: relative;
  display: flex;
  align-items: flex-start;

  > img {
    width: 100%;
  }
}

.customImg {
  width: 100%;
  position: relative;
  display: block;
  margin: auto;
}

:global {
  .async-carousel {
    .rec-slider-container {
      overflow: auto;
    }

    .rec-item-wrapper {
      overflow: unset;
    }
  }
}
