@import "src/assets/v1.1/scss/spectrum";

.info {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  text-align: center;

  color: $black-80;

  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.5rem;
}

.container {
  width: 26.875rem;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: auto;
  margin-left: auto;

  & > button {
    border-radius: 0.5rem;
    padding: 0.5rem 2rem;
    min-height: 2.313rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
  }
}

.cancel {
  background: $brand-20;

  color: $brand;
  min-width: 6.875rem;
}

.download {
  background: $brand;

  min-width: 8.125rem;
  color: $white;
}

.banner {
  background: $black-10;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
  border-radius: 0px 0px 0.625rem 0.625rem;

  & > p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    color: $black-80;
  }
}
