.text--medium {
  font-weight: 600;
}

.text--secondary {
  font-weight: 400;
  font-size: 14px;
  color: $gray;
}

.text--danger {
  color: $red;
}

.text--center {
  text-align: center;
}

.text--h4 {
  font-weight: 600;
  font-size: 16px;
  color: $black;
}

.text--pure-black {
  color: $pure-black;
}

.text--dark-grey-2 {
  color: $dark-grey-2;
}

.text--dark-gray {
  color: $dark-gray;
}
