@import "src/assets/scss/variables";

.tag-input {
  .ant-select-selector {
    border-radius: 8px;
    font-size: 16px;
  }
  .ant-select-selection-item {
    background: $light-blue;
    color: $blue;
    border: none;
    min-height: 34px;
    margin-left: 4px;
    border-radius: 32px;
    padding-right: 12px;
  }
  .ant-select-selection-item .ant-select-selection-item-content {
    padding: 6px 12px;
  }
  .ant-select-selection-item .ant-select-selection-item-remove {
    svg {
      color: $blue;
    }
  }
  .ant-select-selection-search {
    margin-left: -2px;
    min-width: 6px;
  }
  input.ant-select-selection-search-input {
    padding: 0px;
    margin: 8px;
    margin-left: 6px;
    width: 100%;
    border-radius: 0px;
  }
  input:focus {
    box-shadow: none;
  }
}

.ant-select-dropdown.tag-input__dropdown {
  display: none;
}
