@import "src/assets/scss/variables.scss";

.chat-widget {
  min-width: 245px;
  position: absolute;
  left: 15rem;
  bottom: 30px;
  padding: 12px;
  background: $dark-gray;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  cursor: default;

  &__button {
    height: 54px;
    background: $white;
    display: inherit;
    justify-content: flex-start;
    padding: 18px;
    border-radius: 4px;
    margin: 6px 0;
    cursor: pointer;

    & > p {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: $black;
      padding-left: 15px;
    }
  }
}

.shift-support {
  transform: translateX(250px);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.shift-support-exit {
  transform: translateX(0);
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
