@import "src/assets/v1.1/scss/spectrum";

.taxes {
  .side-section {
    position: absolute;
    width: 460px;
    right: 0;
    bottom: 0;
    background: #333744;
  }

  tr.alert-info:hover > td:hover {
    background: $blue-20;
  }

  tr.alert-danger:hover > td:hover {
    background: $red-20;
  }

  p.banner-message {
    color: currentColor;
    font-size: var(--font-size-1);
    line-height: var(--font-lineheight-3);
    margin-top: -0.25rem;

    .highlight {
      color: var(--text-link-default);
    }

    .pointer {
      cursor: pointer;
    }
  }

  .alert {
    text-align: center;
    padding: 0 !important;

    p {
      font-size: 12px;
      font-weight: 300;

      .highlight {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 400;
      }
    }

    &-info {
      background: $blue-20;

      p {
        color: $blue;
      }
    }

    &-danger {
      background: $red-20;

      p {
        color: $red;
      }
    }
  }

  .tax-name > span:not(:last-child) {
    margin-right: 8px;
  }

  .data-table-container .data-table-content .ant-table-wrapper .ant-table .ant-table-tbody > tr.inconsistent-tax {
    background: $red-20;
  }

  tr.inconsistent-tax:hover > td {
    background: $red-20;
  }

  .inconsistent-tooltip {
    width: 20vw;
    font-size: 12px;
  }

  .inconsistent-tax-text {
    color: $red;
    border-bottom: 1px solid $red;
    display: inline;
  }

  .custom-tooltip-transition {
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    &-enter {
      opacity: 0;
      scale: 0.95;
    }

    &-enter-active {
      opacity: 100;
      scale: 1;
    }

    &-leave-active {
      opacity: 100;
      scale: 1;
    }

    &-leave {
      opacity: 0;
      scale: 0.95;
    }
  }

  .banner-wrapper + .ant-table-wrapper {
    padding-top: 1rem;
  }

  .banner-wrapper {
    padding-top: 1rem;
    article:not(:last-child) {
      margin-bottom: var(--spacing-008);
    }
  }

  .text-with-new-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-004);
  }

  .caption {
    color: var(--text-body-default);
    margin-bottom: var(--spacing-024);
  }

  .ant-popover-inner-content {
    width: auto;
  }
}
