@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  height: -webkit-fill-available;
  position: relative;
  .title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2.5rem;
  }
  .tab {
    overflow: unset;
    > div:first-child {
      width: -webkit-fill-available;
      color: $black-100;
      > div:first-child {
        > div:first-child {
          display: flex;
          justify-content: space-around;
          border-bottom: 0.06rem solid $black-40;
        }
      }
    }
  }
  .status {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .editButton {
    color: $blue-80;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    .editIcon img {
      height: 1rem;
      filter: invert(68%) sepia(16%) saturate(3721%) hue-rotate(178deg) brightness(100%) contrast(91%);
    }
  }
  .editRestricted {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
}
