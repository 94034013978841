@import "src/assets/v1.1/scss/spectrum.scss";

.text {
  display: block;
  color: $black-100;
}
.small {
  font-size: 0.875rem;
}
.normal {
  font-size: 1.125rem;
}
.space {
  margin-top: 0.875rem;
}
.schedule {
  padding-bottom: 1.5rem;
}
