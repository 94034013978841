@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/scss/variables";

.sendMoneyCalc {
  :global {
    .ant-select {
      color: $black;
      .ant-select-selector {
        background: $white;
        border-radius: 4px;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
        height: 3.125rem;
      }
      input.ant-select-selection-search-input {
        padding: 1rem;
      }
    }

    .spm-input__input {
      height: 100%;
    }

    .country-select {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
}

.receiverLabel {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  font-size: 0.75rem;
  font-weight: normal;
}

.faq {
  padding: 1.5rem 1rem;
  margin-top: 1.5rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  color: $black-90;
  background-color: $black-30;
}

.faqLink {
  align-items: center;
  display: flex;
  color: #1e97ee;
  font-weight: 600;

  img {
    margin-left: 0.25rem;
  }
}
