.submittedBills {
  :global(.trxn_action) {
    padding-top: 0;
  }
  :global {
    .trxn_page {
      height: auto;

      .ant-table-content {
        overflow: unset !important;
        height: auto;
        > table {
          width: fit-content !important;
        }
      }
    }

    .data-table__actions-bar {
      > :first-child {
        margin-left: -1.5rem; // replace custom margin left by the Filter component
      }
    }
  }
}
