@import "src/assets/v1.1/scss/spectrum";

.currencyInput {
  + .currencyInput {
    padding-top: 24px;
  }
}

.currencyLabel {
  cursor: pointer;
}

.currencyInputGroup {
  display: grid;
  grid-template-columns: 7.5rem auto;
  gap: 0.25rem;

  &.withDropdown {
    grid-template-columns: 7.5rem auto;
  }

  .enableDropdown {
    grid-template-columns: 7.5rem auto;
  }

  :global {
    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $white;
      color: $black-90;
      cursor: auto;
    }

    .ant-select {
      background: none !important;
    }

    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
      cursor: auto;
    }

    .icon {
      cursor: auto;
    }

    .spm-input {
      height: 3.125rem;
      &_input {
        padding: 0 var(--spacing-016);
      }
    }
  }
}

.currencyOptions {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1rem auto;

  .flag {
    width: 100%;
    filter: drop-shadow(0px 0px 2px #d9e3f9);
  }
}

.inputLabel {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.exchangeDetail {
  position: relative;
  display: grid;
  grid-template-columns: 6.5rem 1rem auto;
  column-gap: 0.5rem;
  row-gap: 2rem;
  padding: 2rem 0;
  line-height: 1.5rem;
  color: $black-100;

  .verticalLine {
    position: absolute;
    top: 0;
    left: 7.438rem;
    width: 0.125rem;
    height: calc(100% + 1.75rem);
    background-color: $green-75;
  }

  .processDetailIcon {
    position: relative;
    border-radius: 50%;
    background-color: $green-75;
    margin-top: 0.25rem;
    height: 1rem;
    text-align: center;

    &.times {
      transform: rotate(45deg);
    }

    span {
      display: block;
      height: 0.75rem;
      padding: 0.125rem 0;
    }

    svg {
      display: block;
      margin: auto;
      color: $white;
    }
  }

  .detailLabel {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 0.25rem;
    font-size: 0.875rem;

    & img {
      vertical-align: middle;
    }
  }

  .detail {
    display: flex;
    align-items: baseline;
    column-gap: 0.5rem;
  }

  .detailText {
    white-space: pre-line;
    font-size: 1rem;
  }

  &.withDropdown {
    grid-template-columns: 7.75rem 1rem auto;

    .verticalLine {
      left: 8.688rem;
    }
  }
}
.selectCurrency {
  :global(.ant-select-arrow) {
    margin-top: calc(0px - var(--spacing-016));
  }
}
