@import "src/assets/v1.1/scss/spectrum";

@keyframes slidein {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.container {
  margin: 1.25rem 0;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.5rem;
  animation-name: slidein;
  animation-duration: 0.4s;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  line-height: 150%;
  max-width: 80%;

  .icon {
    width: fit-content;
    cursor: default;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .message {
    padding: 0 0.75rem;
    width: 100%;
    text-align: left;
    word-wrap: break-word;
  }

  .action {
    font-size: 0.875rem;
    height: 2rem;
    border-radius: 1rem;
    color: $green;
    background: none;
    border: 2px solid $green;
    margin-right: 1rem;
    white-space: nowrap;
    padding: 0 1rem;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .close {
    cursor: pointer;
    border-width: 0 0 0 0.063rem;
    border-style: solid;
    padding-left: 1.25rem;
    height: 2rem;
    line-height: 2.75rem;
  }

  &.success {
    background: $green-20;
    transition: width 50s;
    border: 1px solid $green-40;
    color: $green;
  }

  &.success > .close {
    border-color: $green-40;
  }

  &.error {
    background: $red-20;
    transition: width 50s;
    border: 1px solid $red-40;
    color: $red-80;
  }

  &.error > .close {
    border-color: $red-40;
  }

  &.error > .action {
    border-color: $red-80;
    color: $red-80;
  }

  &.small {
    width: 45rem;
  }

  &.medium {
    width: calc(100% - 36.25rem);
  }

  &.large {
    width: calc(100% - 9.375rem);
  }
}
