@import "src/assets/scss/variables.scss";

.ga-info {
  &__title {
    font-size: 28px;
    font-weight: bold;
    color: $dark-grey;
    text-align: center;
    line-height: 34px;
    padding: 70px 0px 16px;
  }
  &__subtitle {
    font-size: 16px;
    color: $grey;
    text-align: center;
    line-height: 24px;
  }
  &__info-title {
    background-color: $orange;
    font-size: 16px;
    font-weight: bold;
    color: $white;
    text-align: center;
  }
  &__wrapper {
    padding: 48px 0px;
    &:nth-last-of-type(2) {
      margin: 16px 0px 134px;
    }
  }
  &__fieldset {
    border: none;
    background: $zircon;
    max-width: 800px;
    margin: 0 auto;
    height: 46px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    &--title {
      display: inline;
      background-color: $orange;
      border-radius: 50px;
      line-height: 19px;
      color: $white;
      padding: 4px 8px;
      font-size: 16px;
      font-weight: bold;
    }
    &--legend {
      text-align: center;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          position: relative;
        }
        & p {
          position: absolute;
          max-width: 230px;
          top: 54px;
          font-size: 16px;
          font-weight: bold;
          color: $dark-grey;
          line-height: 34px;
        }
      }
      &-number-icon {
        background-color: $orange;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        line-height: 34px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
  }
  &__fieldset-info {
    transform: rotate(180deg);
    &--legend {
      transform: rotate(180deg);
    }
  }
  &__action-btn {
    margin-top: 32px;
    & button {
      padding: 12px 28px;
      font-size: 16px;
      height: 56px;
    }
  }
}
