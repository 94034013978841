.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-008);

  svg {
    width: var(--size-030);
    height: var(--size-030);
  }

  .title {
    color: var(--text-title-default);
  }

  .description {
    color: var(--text-body-default);
    line-height: var(--spacing-024);
  }
}
