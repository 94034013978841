.status-view {
  display: flex;
  align-items: center;
  flex-direction: column;

  &--primary {
    background: #fff;
    box-shadow: 0px 5px 20px rgba(231, 232, 242, 0.5);
    border-radius: 16px;
    margin: 40px;
    padding: 40px;
  }

  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 2.75em;
  }

  &--message {
    font-size: 20px;
    line-height: 1.875em;
    color: #131a2e;
    max-width: 650px;
    text-align: center;
  }

  &--icon,
  &--message {
    padding: 20px 0;
  }

  &--redirect-btn {
    background: #f65731;
    border-radius: 33px;
    padding: 20px 48px;
    margin-top: 20px;
    color: #fff;
    &:hover {
      color: #fff;
      background: #f23e25;
    }
  }
}
