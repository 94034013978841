.input {
  ::placeholder {
    text-transform: initial;
  }

  :global {
    .ant-select-selection-item {
      text-transform: uppercase;
    }
  }
}
