@import "src/assets/v1.1/scss/spectrum";

.container {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 100;
  cursor: default;
  text-align: initial;

  & > * {
    box-sizing: border-box;
  }

  .content {
    align-self: center;
    background: $white;
    border-radius: 0.438rem;
    position: relative;
    max-height: 95%;
    padding: 1.25rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
