@import "src/assets/v1.1/scss/spectrum";

.container {
  width: 100%;
  height: 100%;
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $black-100;
    .icon {
      margin: auto;
    }
    .title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 4.5rem;
      margin-top: 0.75rem;
    }
    .body {
      font-weight: 200;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;
    }
    .button {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      width: 24.0625rem;
    }
  }
}
