.third-party-integration-card {
  padding: 0;
  width: 100%;

  &__header {
    color: var(--text-title-default);
    margin-bottom: var(--spacing-008);
    font-weight: var(--font-weight-6);
  }

  &__caption {
    color: var(--text-body-default);
    margin-bottom: var(--spacing-008);
  }

  &__logo {
    min-width: var(--size-024);
    min-height: var(--size-024);
    border-radius: var(--radius-round);
    margin-right: var(--spacing-016);
  }

  &__body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__button {
    margin-left: auto;
  }
}
