.image-carousel {
  min-width: 665px;
  max-width: 100%;
  padding: 40px 40px;
  max-height: 100%;
  overflow: hidden;
  img {
    width: -webkit-fill-available;
  }
  .pdf-image {
    max-width: 250px;
    max-height: 200px;
    margin: 40px 0;
  }
  .rec-slider {
    display: flex;
  }
  &--icon {
    display: flex;
    align-items: center;
    margin: 4px 40px 0 40px;
    position: absolute;
    .icon__title {
      width: fit-content;
      padding: 0 0 0 6px;
      font-size: 14px;
      color: #333744;
    }

    &.less-margin {
      margin: 0 12px;
    }
  }
  &__receipts {
    margin-top: 20px;
    display: flex;
    overflow-x: scroll;
    max-width: 450px;
    .pdf-link {
      width: 200px;
      max-width: 200px;
      padding: 0;
      margin: 0;
    }
  }
  .carousel--content {
    flex-direction: column;
  }
}
.image-carousel__receipts {
  &--source-link {
    margin: 8px;
  }
  &--image {
    max-width: 200px;
    max-height: 200px;
  }
}
.image-carousel__modal-preview {
  .react-pdf__Document {
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
  }
}
.image-carousel__inline-preview {
  .react-pdf__Document {
    overflow: hidden;
  }
  .react-pdf__Page__canvas {
    max-width: 200px;
    max-height: 200px;
  }
}
