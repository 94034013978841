@import "src/assets/v1.1/scss/spectrum";

.container {
  position: absolute;
  margin-top: 0.75rem;
  background: $white;
  padding: 0.25rem 0;
  display: none;
  box-shadow: 0 0 0.625rem 0.25rem rgba($black-90, 0.08);
  border-radius: 0.25rem;

  z-index: 18;
  height: 100%;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.inherit {
    &--width {
      width: 100%;
    }
  }

  &.extra-small {
    &--width {
      width: 14.375rem;
    }
  }

  &.small {
    &--width {
      width: 17.75rem;
    }
  }

  &.regular {
    &--width {
      width: 19.375rem;
    }
  }

  &.medium {
    &--width {
      width: 24.375rem;
    }
  }

  &.large {
    &--width {
      width: 34.438rem;
    }
  }

  &.extra-large {
    &--width {
      width: 36.5rem;
    }
  }

  &.hidden {
    visibility: collapse;
    opacity: 0;
    height: 0;
    transition: visibility 100ms, height 150ms, opacity 100ms;
  }

  &.active {
    display: block;
    visibility: visible;
    opacity: 1;
    height: fit-content;
    transition: visibility 500ms, height 300ms, opacity 200ms;
  }
}
