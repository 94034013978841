@use "../Requirement.module.scss";

@import "src/assets/v1.1/scss/spectrum";

$_settlement-item-gap: 1rem;
$_settlement-item-icon-width: 2.688rem;

.container {
  @extend #requirement;
  gap: 1.25rem;
}

.item {
  display: flex;
  flex-direction: row;
  gap: $_settlement-item-gap;

  &__icon {
    width: $_settlement-item-icon-width;
    height: $_settlement-item-icon-width;
    border-radius: 50%;

    background: $black-10;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);

    display: flex;
    padding: 0.625rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    width: calc(100% - $_settlement-item-gap - $_settlement-item-icon-width);

    letter-spacing: -0.015rem;

    color: $black-100;
  }
}
