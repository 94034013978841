.select-members {
  min-width: 280px;
  padding: 40px 0 0 24px;
  border-right: 1px solid #f4f6f9;
  &__search {
    padding-right: 24px;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #131a2e;
    }
    .search {
      margin: 24px 0;
      min-width: 315px;
    }
  }
  &__members {
    max-height: 450px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    padding-right: 24px;
    h4 {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #c5c7cd;
    }
  }
  &__selected-members {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 250px;
    padding-bottom: 20px;
    & > div {
      margin-right: 10px;
    }
  }
  &--error {
    font-size: 12px;
    color: #ff4d4f;
  }
  &__matchmove-dashboardv2 {
    padding-top: 0px !important;
    .search {
      margin: 16px 0px;
    }
  }
}
