.section {
  padding: 30px 70px;
  color: $dark-grey;

  &__header {
    font-weight: 600;
    font-size: 28px;
    line-height: 2.125em;
    color: $dark-gray;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    &--black {
      color: $pure-black;
    }
  }

  &__subheading {
    font-size: 16px;
    font-weight: 400;
    line-height: 2.125em;
    color: $black;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    box-shadow: 0px 4px 20px rgba(231, 232, 242, 0.5);
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 40px;
    background: $white;

    &-title {
      font-weight: 600;
      line-height: 30px;
      font-size: 20px;
      color: $dark-grey;
    }

    &-subtitle {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $dark-grey;
      margin-bottom: 24px;
    }
  }

  &--white {
    background: $white;
  }

  &--full-height {
    min-height: 100vh;
  }
}
