.user-cards {
  &__carousel {
    height: 260px;
    .custom-carousel__arrows {
      &--next {
        right: 16px;
      }

      &--previous {
        left: -16px;
      }
    }
    &--container {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
  }

  &__placeholder {
    padding: 20;
    display: flex;
    text-align: "center";
    justify-content: center;
  }
  &__user-cards-container {
    margin-right: 24px;
  }
}
