.carousel {
  position: relative;

  &__arrows {
    align-self: center;
    justify-content: center;
    border: 0;
    outline: none;

    img {
      cursor: pointer;
    }

    &--disable {
      opacity: 0;
      visibility: hidden;
      display: flex;
      transition: visibility 300ms, opacity 200ms ease-out;

      img {
        cursor: none;
        pointer-events: none;
      }
    }
  }

  & &--content {
    flex-direction: column-reverse;

    .rec-slider-container {
      overflow-y: auto;

      .rec-item-wrapper {
        overflow-x: auto;
      }
    }

    .rec-swipable {
      align-items: center;
    }
  }

  &__pagination {
    text-align: center;
    padding-bottom: 7px;

    &--hidden {
      display: none;
      transition: display 300ms ease-out;
    }

    &__dots {
      outline: none;
      margin: 4px;
      padding: 4px;
      border: none;
      border-radius: 50%;
    }

    .active {
      background: #333744;
    }

    .inactive {
      background: #c5c7cd;
    }
  }
}
