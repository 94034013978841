@import "src/assets/scss/variables.scss";
@import "src/Splice/scss/spectrum";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/variables";

.employee-otp-verification {
  width: 424px;
  box-shadow: 0px 0px 4px rgba(51, 55, 68, 0.08);
  padding: 1.5rem 2rem 2rem;
  background-color: white;
  border-radius: 8px;

  &.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 12.5rem;
  }

  &__title,
  &__sub-title {
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    color: $dark-gray;
  }

  &__title {
    font-size: 24px;
  }

  &__sub-title {
    font-size: 14px;
  }

  &__desc {
    font-size: 16px;
    line-height: 24px;
    color: #666b79;
    padding: 1.5rem 0;
    max-width: 28.25rem;

    .button {
      color: $blue;
      min-height: 0;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__message {
    font-size: 14px;
    line-height: 24px;
    color: $black-030;

    .button {
      min-height: 0;
    }

    &--center {
      text-align: center;
    }
  }

  &__error {
    font-size: 14px;
    color: $red-80;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  &__back-to-login {
    background: $orange;
    border-radius: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background: #d44a29;
      color: #fff;
      border-color: #fff;
    }
  }

  &__assistance {
    margin-top: 32px;
    line-height: 20px;
  }

  &__input-wrapper {
    margin: 0 auto;
  }

  &__loader-screen {
    margin: 0 auto;
    padding: 1.125rem 0px 3.25rem 0px;

    .loader {
      padding: 2rem 0px;
    }

    .info-text-header {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: $dark-gray;
    }

    .info-text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: $black-2;
    }
  }

  &__resend-btn {
    color: $blue-060;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.contact-support {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 20px;
  color: $black-030;
  font-family: $font-family-noto-sans;
  margin-bottom: 2rem;
}
