@import "src/assets/scss/variables.scss";

.phone-code-dropdown__overlay {
  position: sticky;
  margin-top: 50px;
  &-container {
    max-height: 263px;
    min-width: 274px;
  }
  .phone-code-option {
    font-size: 14px;
    line-height: 32px;
    color: $dark-gray-2;
    padding-left: 8px;
  }
  .phone-code-option span {
    padding-left: 4px;
    color: $light-gray;
  }
  .ant-select-item-option-content {
    padding: 4px 0;
    height: 32px;
  }
  .ant-select-item-option-selected .phone-code-option {
    color: $primary;
  }
  .search {
    flex-direction: row-reverse;
    background: $white;
    height: 42px;
    max-width: 100%;
    padding: 0 10px 0 16px;
    align-items: center;
  }
  .search__input-field {
    background: $white;
    margin: 0;
    font-size: 14px;
  }
  .search__icon .icon__image {
    width: 14px;
    height: 14px;
    margin: 5px 3px 0 0;
  }
  .search__close {
    position: absolute;
    margin-top: 5px;
  }
}

.phone-code-dropdown {
  &__suffix-icon > img {
    padding-bottom: 2px;
  }
}
