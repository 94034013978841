@import "src/assets/v1.1/scss/spectrum";

.team-profile {
  &-wrapper {
    padding: 48px 0;
    width: 100%;

    h1 {
      font-weight: bold;
      font-size: 28px;
      line-height: 2.125em;
      color: #000000;
    }

    h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }

    &__title-area {
      h1 {
        margin-top: 30px;
      }
    }

    &__title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        margin-top: 1.8rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background-color: $red-40;
        color: $red-80;
        font-size: 0.875rem;
        height: 2.25rem;
        width: 8.5rem;

        img {
          margin-right: 0.25rem;
        }
      }

      button:disabled {
        background-color: $black-50;
        color: $white;
      }
    }

    &-name-field {
      display: flex;
      align-items: center;

      .team-profile-save-btn {
        margin-left: 30px;
      }
    }

    &__name {
      display: flex;
      margin-bottom: 26px;
      margin-top: 24px;
    }

    &__name-text-field {
      min-width: 25rem;
      width: 100%;
      margin-left: 0.938rem;
      display: flex;
      gap: 0.5rem;
      flex-direction: column;

      > div {
        margin-bottom: 0;
      }
    }

    .ant-tabs-nav {
      color: #c5c7cd;

      .ant-tabs-tab-active {
        color: #f65731;
      }
    }

    &--back-teams {
      font-weight: 600;
      color: #f65731;
      background-color: transparent;
      text-align: left;
      padding: 0;

      &:hover {
        color: #40a9ff;
      }
    }
  }

  &-menu-items {
    position: relative;

    &:before {
      content: "";
      display: block;
      border-bottom: 1px solid #ccc;
      top: 38px;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
}
