@import "src/assets/scss/variables.scss";

.update-profile-error {
  height: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .description {
    text-align: center;
  }
  .action-btns {
    margin-top: 40px;
    .back-to-home-link {
      padding: 16px;
      border-radius: 41px;
      border: 1px solid #f65731;
      background: transparent;
      color: #f65731;
      margin-right: 20px;
      &:hover {
        border: 1px solid #f65731;
        color: #ff3100;
      }
    }
  }
}

.update-profile-success {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 30px 142px;
    & > .button {
      margin-top: 40px;
      width: 260px;
      text-decoration: none;
      &:hover {
        color: $white;
      }
    }
  }
}

.update-profile-error,
.update-profile-success {
  .status-icon .icon__image {
    width: 190px;
    height: 190px;
  }
  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #ff5633;
    padding: 14px 0 16px 0;
  }
  .description {
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    color: #131a2e;
    width: 610px;
  }
}
