.billing {
  padding: var(--spacing-008);
}

.billingBanner {
  background-color: var(--navy-0);
}

.manageBillingBtn {
  margin-left: auto;
}

.frameContainer {
  width: 100%;
  min-height: 35.3125rem;
}

.vSpace4 {
  margin-top: var(--spacing-004);
}

.vSpace8 {
  margin-top: var(--spacing-008);
}

.vSpace16 {
  margin-top: var(--spacing-016);
}

.vSpace24 {
  margin-top: var(--spacing-024);
}
