.tooltip-container {
  display: flex;

  &--align-to-end {
    justify-content: end;
  }

  .tooltip-container__title {
    padding-right: 2px;
  }
}
