@import "src/assets/v1.1/scss/spectrum.scss";

.destructive-actions {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-width: 15.625rem;

  :global(.ant-btn) {
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 2.5rem;
    line-height: 1.25rem;
    height: auto;
    padding: 0.5rem 1.875rem;
    flex: 1;
  }

  &__button {
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: 2.5rem;

    &--constructive,
    &--constructive:focus {
      background-color: $green-80;
      color: $white;
      border: 0.063rem solid $green-80;

      & > span {
        text-transform: capitalize;
      }

      &:hover {
        background-color: $white;
        color: $green-80;
        border: 0.063rem solid $green-80;
      }
    }

    &--destructive {
      background: $white;
      color: $red-80;
      border: 0.063rem solid $red-80;

      &:hover {
        background-color: $red-80;
        color: $white;
        border: 0.063rem solid $red-80;
      }
    }

    &--link:global(.ant-btn) {
      background: none;
      color: $blue-80;
      border: none;
      box-shadow: none;
      padding: 0.5rem 1rem;
    }
  }

  :global(.icon) {
    line-height: 0;
  }
}
