@import "src/assets/v1.1/scss/spectrum";

.container {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 30;
  cursor: default;
  text-align: initial;
}

.content {
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 8px 16px 32px;
  align-self: self-end;
  background: #fff;
  position: relative;
  height: max-content;
  text-align: center;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  display: block;
  color: #333744;
  letter-spacing: -0.01em;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  line-height: 150%;
  color: #545454;
}

.downloadAppContent {
  font-size: 14px;
  color: #6c6c6c;
  line-height: 150%;

  a {
    color: #5ab3f3;
  }
}

.continueAnywayButton {
  width: 100%;
  margin-top: 1rem;
  height: 48px;
  margin-bottom: 1rem;
  background: #fc4d0f;
}

.indicator {
  width: 80px;
  height: 4px;
  background: #c5c7cd;
  border-radius: 100px;
  display: block;
  margin: auto;
  margin-bottom: 40px;
}
