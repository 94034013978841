@import "src/assets/v1.1/scss/spectrum";

.barContainer {
  overflow: hidden;
  width: 99%;
  height: 0.25rem;
}

.progressBar {
  position: relative;
  height: 100%;
  background-color: $green-80;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &.failed {
    width: 100% !important;
    background-color: $red-80;
  }
}
