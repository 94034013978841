.action-buttons {
  display: flex;
  align-items: center;
  .ant-btn {
    font-size: 14px;
    border-radius: 16px;
    height: auto;
  }
  & > * + * {
    margin-left: 8px;
  }
  &--bulk {
    * {
      font-size: 16px;
    }
  }
  &--detail {
    * {
      font-size: 16px;
    }
    .ant-btn {
      border-radius: 20px;
      flex-grow: 1;
    }
  }
  &__button {
    &--green.ant-btn {
      color: #ffffff;
      background: #1fce8f;
      border: 1px solid #1fce8f;
      padding: 4px 16px;
      &:hover {
        color: #1fce8f;
        background: #ffffff;
        border: 1px solid #1fce8f;
      }
    }
    &--red {
      color: #e5304c;
      background: #ffffff;
      border: 1px solid #e5304c;
      padding: 4px 16px;
      &:hover {
        color: #ffffff;
        background: #e5304c;
        border: 1px solid #e5304c;
      }
    }
    &--plain {
      border: none;
      background: none;
      padding: 4px 0px;
      color: #1e97ee;
      &:hover {
        background: none;
        color: #666b79;
      }
    }
  }
  .close-button .icon__image {
    vertical-align: middle;
  }
}
