.topup-status-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(231, 232, 242, 0.5);
  border-radius: 16px;
  margin: 40px;
  padding: 40px;

  &--redirect-btn {
    background: #f65731;
    border-radius: 33px;
    padding: 20px 48px;
    margin-top: 20px;
    color: #fff;
    &:hover {
      color: #fff;
      background: #f23e25;
    }
  }

  .refresh-btn {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    & > .icon {
      padding-right: 10px;
    }
  }
}
