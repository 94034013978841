@import "../../assets/scss/variables.scss";

.sp-datepicker {
  font-size: 16px;
  font-weight: 400;
}

.sp-datepicker__popover {
  .ant-picker-footer {
    display: block;
  }
  .ant-picker-cell-inner {
    border-radius: 50%;
  }

  .ant-picker-cell-disabled .ant-picker-cell-inner {
    color: $light-gray-4;
  }

  .ant-picker-cell-disabled:before {
    background: none;
  }
}

.sp-datepicker__popover__footer {
  .timepicker {
    padding: 8px;
    .ant-input-number {
      padding: 0px;
      border-radius: 0px;
      width: 35px;
      border: 0px;
      border-bottom: 1px solid $border-color;
      input {
        font-size: 16px;
        text-align: center;
        padding: 0px;
        color: $orange;
      }
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
    span {
      display: inline-block;
      margin: 0px 4px;
      font-size: 16px;
    }
    .timepicker__error-message {
      display: block;
      color: $red;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .action {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;

    .ant-btn-primary {
      border-radius: 8px;
      border: 0px;
      padding: 4px 20px;
      height: 40px;
      background: $orange;
      &:disabled {
        background: $light-gray;
        color: $white;
      }
    }

    .ant-btn-link {
      color: $orange;
      padding: 4px 0px;
      height: 40px;
    }
  }
}
