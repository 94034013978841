@import "src/assets/v1.1/scss/spectrum";

.recipientDetail {
  .sidePanel {
    box-shadow: 0px 0px 8px 1px rgba(51, 55, 68, 0.1);
    border-radius: 0.5rem;
    overflow: hidden;

    .sidePanelContent {
      background-color: $white;
      padding: 1.5rem;

      .sectionHeader {
        font-size: 1rem;
        font-weight: 800;
        padding: 1rem 1.5rem;
        border-top: 1px solid $black-40;
        margin: 1rem -1.5rem;
        line-height: 1.5rem;
        color: $black-80;
      }

      &Header {
        padding: 1.5rem;
        background-color: $blue-10;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 1.75rem;
          font-weight: 800;
          line-height: 1.4;
        }

        .editRecipientCTA {
          color: $blue-80;
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-top: 0.625rem;
          cursor: pointer;

          img {
            width: 0.875rem;
            height: 0.875rem;
            filter: invert(0.6) sepia(1) saturate(2) hue-rotate(175deg);
          }
        }
      }

      &Footer {
        padding: 1.5rem;
        background-color: $black-10;

        & > div {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          & > div:first-child {
            color: $black-60;
            margin-bottom: 0.5rem;
          }
        }
      }

      & > div {
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }

        & > div:first-child {
          font-size: 0.875rem;
          color: $black-60;
          margin-bottom: 0.5rem;
        }

        & > div:last-child {
          font-size: 1rem;
          color: $black-80;
          font-weight: 600;
          line-height: 1.6;

          :global {
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 0.5rem;

              img {
                width: 1.5rem;
              }
            }
          }
        }
      }

      .transactionItem {
        background-color: $blue-10;
        padding: 1rem;
        border-radius: 0.75rem;

        & > div:nth-child(2) {
          font-size: 1rem;
          font-weight: 600;
          color: $black-90;
          margin-top: 0.5rem;
        }

        & > div:last-child {
          font-size: 0.875rem;
          margin-top: 0.5rem;
        }
      }

      .noTransaction {
        text-align: center;
        background-color: $black-10;
        padding: 1.5rem;
        border-radius: 0.75rem;

        & > div {
          font-size: 0.75rem !important;
          font-weight: normal !important;
        }
      }

      &Transaction {
        &Header {
          background-color: $white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0;

          h2 {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.4;
          }
        }
      }
    }
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .seeMore {
    cursor: pointer;
  }

  .seeLess {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: $black-70;
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }

  & > section > div:last-child {
    background-color: $black-20;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid $black-40;
  }
}
