// s - 8, l - 16, md - 24, xl - 32
.sp-items--end {
  align-items: flex-end;
}

.sp-margin-right--s {
  margin-right: 8px;
}

.sp-margin-right--l {
  margin-right: 16px;
}

.sp-margin-right--md {
  margin-right: 24px;
}

.sp-margin-right--xl {
  margin-right: 32px;
}

.sp-margin-left--xl {
  margin-left: 32px;
}

.sp-margin-bottom--s {
  margin-bottom: 8px;
}

.sp-margin-bottom--l {
  margin-bottom: 16px;
}

.sp-margin-bottom--md {
  margin-bottom: 24px;
}

.sp-margin-bottom--xl {
  margin-bottom: 32px;
}

.sp-margin-top--s {
  margin-top: 8px;
}

.sp-margin-top--l {
  margin-top: 16px;
}

.sp-margin-top--md {
  margin-top: 24px;
}

.sp-margin-top--xl {
  margin-top: 32px;
}

.sp-margin-0 {
  margin: 0;
}

.sp-padding--xl {
  padding: 32px;
}

.sp-padding-0 {
  padding: 0;
}

.sp-font-weight-700 {
  font-weight: 700;
}

.sp-flex-direction-column {
  flex-direction: column;
}

.cursor-pointer {
  cursor: pointer;
}
