.caption {
  font-weight: 400;
  color: var(--text-body-default);
  line-height: var(--spacing-024);
}

.button {
  margin-left: auto;
  width: 5.5rem;
}

.label {
  margin-top: var(--spacing-016);
  color: var(--text-body-default);
  font-size: var(--font-size-0);
  line-height: var(--spacing-024);
  font-weight: var(--font-weight-6);
}

:global(.modal-download-report) {
  padding: var(--spacing-016) 0 0 0;
  width: 33.75rem;

  input {
    margin-top: 0px;
  }
}
