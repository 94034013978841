@import "src/assets/v1.1/scss/spectrum";

.info {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  font-weight: 1.25rem;
  color: $black-90;
  display: grid;
  grid-template-areas:
    "title amount"
    "desc desc";
  .title {
    grid-area: title;
    font-weight: 600;
  }

  .amount {
    grid-area: amount;
    font-weight: 600;
    text-align: right;
  }

  .desc {
    grid-area: desc;
  }
}
