@import "src/assets/scss/variables.scss";

.expenses-carousel {
  padding: 16px 0px 16px 0px;
  position: relative;
  margin-bottom: 32px;
  width: calc(100vw - 13.75rem);
  min-width: 66.25rem; // 1060px

  &__container {
    position: relative;
    &--limit-model {
      height: 178px;
    }
    &--wallet-model {
      height: 158px;
    }
    & .custom-carousel__arrows {
      &--previous {
        left: 35px !important;
      }
    }
    & .slick-track:first-child {
      margin-left: 68px;
    }
  }
  &__header {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: $pure-black;
    display: inline-block;
    margin: 0px 0px 24px 68px;

    a {
      color: $blue;
      font-size: 16px;
      line-height: 20px;
      padding-left: 12px;
    }
  }
}
