@import "src/assets/scss/variables.scss";

.download-template {
  display: grid;
  row-gap: 24px;
  background: $light-grey-2;
  border-radius: 8px;
  height: 148px;
  padding: 24px;
  border: none;
  margin-top: 32px;
  width: 602px;
  &__details {
    display: flex;
    align-items: center;
    &-title,
    &-titleDetail {
      color: $orange;
      text-align: left;
      font-weight: 600;
      font-size: 22px;
      line-height: 20px;
    }
    &-titleDetail {
      color: $black;
    }
  }
  &__btn {
    margin: auto;
    width: 244px;
    height: 56px;
  }
  &__dot {
    color: $orange;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #f65731;
    margin: 0 8px 0 4px;
  }
}
