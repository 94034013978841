@import "src/assets/scss/variables.scss";

.session-timeout-modal {
  padding: 24px;
  width: 350px;

  .modal__title {
    padding-bottom: 20px;
    margin-right: -24px;
    margin-left: -24px;
    border-bottom: 1px solid $light-gray-2;
  }

  .modal__content--children-elements {
    padding: 16px;

    p {
      text-align: center;
      line-height: 1.5;
      color: $body;
      margin-bottom: 24px;
    }
  }

  &__button {
    width: 100%;
  }
}
