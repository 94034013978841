.error {
  text-align: center;
  margin: 30px 0;
  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 35px;
  }
  p {
    display: inline-block;
    color: #f65731;
  }
}

.no-margin {
  margin: 0;
}
