@import "../../assets/scss/variables.scss";

.multi-selection {
  .ant-select-selection-item {
    color: $grey-1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    opacity: unset;
    text-transform: capitalize;
    display: block;
    margin: auto 0;
    width: 467px;
    max-width: 467px;
  }
  & > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    height: 52px;
  }
  &-option {
    height: 268px;
    overflow: scroll;
    .search {
      flex-direction: row-reverse;
      background: $white;
      height: 42px;
      max-width: 100%;
      padding: 0 10px 0 16px;
      align-items: center;
    }
    .search__input-field {
      background: $white;
      margin: 0;
      font-size: 14px;
    }
    .search__icon .icon__image {
      width: 14px;
      height: 14px;
      margin: 5px 3px 0 0;
    }
    .search__close {
      position: absolute;
      margin-top: 5px;
    }
    .ant-input:placeholder-shown {
      font-size: 14px;
      letter-spacing: 0.07em;
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
    }
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      &::after {
        width: 6px;
        height: 13px;
        top: 47%;
        left: 23%;
      }
    }
    .ant-checkbox + span {
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      color: $dark-gray-2;
      text-transform: capitalize;
    }
    .ant-checkbox-wrapper {
      margin: 0;
      padding-bottom: 10px;
    }
  }
  &__value-container {
    display: flex;
    gap: 4px;
  }
  &__value {
    border-radius: 16px;
    background: $spectrum-blue-20;
    display: flex;
    padding: 2px 12px;
    font-size: 14px;
    align-items: center;
    &-close-icon {
      cursor: pointer;
      margin-left: 8px;
      width: 9px;
      height: 9px;
    }
  }
}
