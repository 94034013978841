@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.approval-center-tab {
  margin: 12px 16px;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 16px;

  .approval-center-tab__title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.41px;
    color: $neutral-black;
    line-height: 24px;

    &.active {
      color: $orange-3;
    }
  }

  .approval-center-tab__count {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: $white;
    background-color: $orange-3;
    line-height: 16px;
  }
}

.approval-center-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .approval-flow-button {
    height: 2.8rem;
    padding: 0.625rem 0.75rem;
    background-color: $brand-20;
    color: $brand;
    border-radius: 0.5rem;
    border: 0;
    font-weight: 600;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.313rem;
  }
}
.shimmer {
  &__container {
    display: flex;
    .ant-skeleton-element {
      width: fit-content;
      margin: 0 0.25rem;
    }
  }
}
