.checkbox {
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 40px;
    line-height: 28px;

    &:before {
      content: "";
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 28px;
    }

    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 6px;
      left: 8px;
      opacity: 0;
      position: absolute;
      top: 10px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;

    &:checked + label {
      &:before {
        background-color: $orange;
        border-color: $orange;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.sp-checkbox {
  .ant-checkbox {
    .ant-checkbox-input,
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1.5) translate(-20%, -50%);
  }

  .ant-checkbox + span {
    color: $black;
    font-size: 16px;
    margin-top: 1px;
    padding: 0 16px;
    line-height: 20px;
    display: inline-block;
  }
}

.sp-checkbox--justify-column {
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-top: 16px;
  }
}
