.preview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-032);

  &Section {
    border: 1px solid var(--white-050);
    border-radius: var(--radius-s);

    // Section title
    &:before {
      content: attr(data-title);
      padding: var(--spacing-024);
      display: block;
      background-color: var(--white-050);
      color: var(--black-100);
      font-weight: 600;
    }
  }

  &Content {
    padding: var(--spacing-024);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-024);
  }

  &Item {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-016);
    line-height: var(--spacing-024);

    & > div:first-child {
      font-weight: 600;
      color: var(--black-100);
      width: 14.125rem;
      flex-shrink: 0;
    }

    & > div:last-child {
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      column-gap: var(--spacing-004);
    }
  }

  &Label {
    display: flex;
    align-items: center;
    gap: var(--spacing-024);

    & > div {
      display: flex;
      align-items: center;
    }

    .previewColor {
      width: var(--size-008);
      height: var(--size-008);
      border-radius: var(--radius-2);
      display: inline-block;
      margin-right: var(--spacing-008);
      background-color: var(--black-080);
    }

    .previewHighlight {
      @extend .previewColor;
      background-color: var(--chart-green-default);
    }
  }
}

.previewContainer {
  padding: var(--spacing-020);
}

.previewAttachmentItem {
  padding: var(--spacing-008) var(--spacing-016) var(--spacing-008) var(--spacing-008);
  color: var(--text-title-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-012);
  cursor: auto;

  .imgContainer {
    width: var(--size-016);
    height: var(--size-016);
    img {
      border-radius: var(--radius-s);
    }
    // Note: provide custom style to canvas which is rendered from react-pdf,
    canvas {
      width: var(--size-016) !important;
      height: var(--size-016) !important;
    }
  }

  .fileName {
    flex: 1 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.newValue {
  color: var(--chart-green-default);
}

.oldValue {
  color: var(--text-body-disabled);
  text-decoration: line-through;
}

.recipientContainer {
  display: block;
}

.recipientBox {
  padding: var(--spacing-016);
  background: var(--white-015);
  padding: var(--spacing-024);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-024);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: calc(-1 * 1rem); // TO DO: cannot parse variable with -1 on build -> calc(var(--spacing-016) * -1)

  svg {
    position: relative;
    top: var(--spacing-004);
    left: var(--spacing-001);
    cursor: pointer;
  }

  p {
    cursor: pointer;
  }
}

.pdfViewer {
  canvas {
    & + div {
      display: none;
    }
  }
}

.nium:not(:empty) {
  margin-top: var(--spacing-024);
}
