@import "src/Splice/scss/spaces.scss";

.container {
  display: flex;
  justify-content: center;
  row-gap: 1rem;
  max-width: 17.5rem;

  // padding: $space-20;
}

.categoryPie {
  cursor: pointer;
}
