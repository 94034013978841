@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  border-radius: 1rem;
  margin: 1rem 0 2rem;
  .text {
    margin-bottom: 1rem;
    display: block;
    color: $black-90;
  }
  .normal {
    font-size: 1rem;
    color: $black-60;
  }
  .big {
    font-size: 1.25rem;
  }
  .small {
    font-size: 0.875rem;
  }
  .extrasmall {
    font-size: 0.75rem;
  }
  .space {
    margin-bottom: 3rem;
  }
  .sla {
    &-label {
      color: $black-60;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    &-value {
      color: $black-90;
      font-size: 0.875rem;
    }
  }
  .feeContainer {
    display: flex;
    margin-left: 0.5rem;
    margin-top: -0.875rem;
    margin-bottom: 1rem;
    .leftMarker {
      background-color: $red-40;
      width: 0.25rem;
      height: auto;
      border-radius: 0.188rem;
      margin-right: 0.875rem;
    }
    .right {
      margin-top: 0.875rem;
    }
    .normal {
      margin-bottom: 0.5rem;
    }
    img {
      margin-left: 0.313rem;
      vertical-align: bottom;
    }
    .extrasmall {
      color: $black-60;
    }
  }
}

:global {
  .ant-tooltip .ant-tooltip-inner {
    background-color: $black-90 !important;
    font-weight: 400;
    font-size: 0.75rem;
    max-width: 16rem;
  }
}
