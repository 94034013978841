@import "src/assets/scss/variables";

.bulk-upload {
  &__description {
    &-title {
      font-size: 36px;
      color: $light-orange-2;
      font-weight: bold;
      letter-spacing: -0.24px;
      line-height: 160%;
    }
    &-subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      color: $dark-grey;
      width: 70%;
    }
  }
  &__content {
    .upload-step {
      display: flex;
      align-items: center;
      .message {
        letter-spacing: -0.24px;
        padding-top: 4px;
      }
    }
  }
}
