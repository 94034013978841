.analytics-table {
  .ant-table-container {
    &::before {
      box-shadow: none !important;
    }

    &::after {
      box-shadow: none !important;
    }

    .ant-table-row {
      cursor: pointer;

      &.disabled * {
        cursor: no-drop !important;
      }
    }
  }
}

.positive-change {
  color: #df1d0b !important;
}

.negative-change {
  color: #1fce8f !important;
}

.analytics-tooltip {
  .ant-popover-arrow {
    display: block !important;
    border-color: #333744 !important;
  }

  .ant-popover-inner {
    border-radius: 0.75rem !important;
  }

  .ant-popover-inner-content {
    background: #333744 !important;
    color: #ffffff !important;
    border-radius: 0.75rem !important;
    width: max-content;
    padding: 0.5rem 0.75rem;
  }
}
