.add-bank {
  padding: 40px 60px;
  &--title {
    font-weight: 600;
    font-size: 28px;
    line-height: 2.125em;
    color: #000;
    margin-bottom: 12px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(231, 232, 242, 0.5);
    border-radius: 8px;
    min-height: 600px;
    .ant-tabs {
      padding-bottom: 20px;
    }
    &--action-btns {
      margin-top: auto;
      .button {
        &:last-child {
          margin-left: 12px;
          background: none;
          border: 1px solid #c6c7cd;
          color: #666b79;
          &:hover {
            background: #666b79;
            color: #fff;
          }
        }
      }
    }
  }
  &--form {
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.875em;
      color: #131a2e;
      padding-bottom: 24px;
    }
    &--mobile {
      margin-bottom: 6.5rem;
    }
    .ant-form-item-label > label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #131a2e;
      margin-top: 0;
    }
    .ant-input {
      margin-top: 0;
      padding: 8px;
      border-radius: 6px;
    }
    .ant-input-group-addon {
      font-size: 14px;
    }
    & &__select {
      .ant-select-selector {
        height: 42px;
        border-radius: 4px;
        padding: 6px;
      }
    }
  }
}
