@import "src/Splice/scss/spectrum";
@import "src/assets/scss/mixins.scss";
@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  padding: 12px 20px;
  text-align: center;
  border-radius: 8px;
  background: $orange;
  color: $white;
  font-family: "Spenmo", sans-serif;
  font-weight: 600;
  min-width: 130px;
  position: relative;
  min-height: 48px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #fd4418;
  }

  &.active {
    background-color: #1fce8f;
  }

  > .sk-fading-circle,
  > .button__icon {
    margin-right: 8px;
  }
}

// Button Styles
.button--text-button {
  background: none;
  padding: 0;
  margin: 0 5px;
  color: #fd4418;
  min-width: 0;
  font-size: 14px;

  &--secondary {
    background: #fff5ed;
    color: #f65731;
    border: 1px solid #fff5ed;
    transition: border 0.3s ease, background-color 0.3s;
    margin-left: 5px;
    &:hover {
      border-color: #f65731;
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.button--text-button {
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

.button--secondary {
  background: $peach;
  color: $orange;
  margin-left: 5px;

  &:hover {
    background-color: $peach;
  }
}

.button--plain {
  background: $white;
  color: #333;
  transition: border 0.3s, background-color 0.3s;
  margin-left: 5px;
  box-shadow: 0px 4px 8px #eef2f5;

  &:hover {
    border: 1px solid $orange;
    background: transparent;
  }
}

.button--rounded {
  border-radius: 30px;
}

.button--disabled {
  background: $black-50;
  pointer-events: none;
  color: $black-0;

  @include button-variant($black-20, $black-50);
  cursor: not-allowed;

  &:hover {
    background-color: $black-20;
    color: $black-50;
  }
}

.button--none-pointer-events {
  pointer-events: none;
}

.button--peach {
  @include button-variant($peach, $orange);
}

.button--danger {
  @include button-variant($red, $white);
}

.button--white-red {
  @include button-variant($white, $red);
}

.button--white-red-with-border {
  @include button-variant($white, $red-80);
  border: 1px solid $red-80;

  &.button--disabled {
    @include button-variant($black-20, $black-50);
    border: 1px solid $black-50;
  }

  &:hover {
    background-color: $red-80;
    color: $white;
  }
}

.button--soft-red {
  @include button-variant($red-20, $red);

  &.button--disabled {
    @include button-variant($black-20, $black-50);
  }

  &:hover {
    background-color: $red-20;
    color: $red;
  }
}

.button--green {
  @include button-variant($green-80, $white);
}

.button--transparent-red {
  @include button-variant(transparent, $red);
}

.button--white-orange-with-border {
  @include button-variant($white, $orange);
  border: 2px solid $orange;

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.button--transparent-orange-with-border {
  @include button-variant(transparent, $orange);
  border: 2px solid $orange;
}

// Sizes {
.button--l {
  font-size: 14px;
  width: 200px;
  height: 45px;
}

.button--xl {
  font-size: 16px;
  width: 300px;
  height: 56px;
}
