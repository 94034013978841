@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spectrum.scss";
@import "src/Splice/scss/variables.scss";

.teams-tab-page {
  .feature-flag-container {
    padding: 3rem 0;
    overflow-y: auto;
    height: 100vh;
    background-color: $white-0;

    .ant-table-pagination.ant-pagination {
      padding-right: 0;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-016);
  }

  .data-table-container {
    width: auto;
    height: auto;
  }

  .data-table-container .data-table-content.data-table-padding__default {
    padding: 0;
  }

  .data-table-container .data-table-content .ant-table-wrapper {
    padding-top: 0;
  }

  &__name {
    font-weight: bold;
    display: flex;
    align-items: center;
    overflow-wrap: anywhere;
    .letter-avatar {
      margin-right: 8px;
    }
  }

  &_size {
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    text-align: right;

    a {
      padding: 5px 10px;
      margin-right: 10px;
      color: #666b79;
    }

    &-delete-team-btn {
      background: transparent;
      padding: 5px 10px;
      min-width: 0;
      min-height: 0;
      margin: 0 10px;
      color: #e5304c;
    }

    &-delete-team-btn:hover {
      border: 0;
      background-color: #fd44181a;
      border-radius: 8%;
    }
  }

  .manager-only {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.24px;
    color: $black-50;
  }

  .team-banner-toast {
    margin-right: auto;
    margin-top: 1.5rem;
  }

  .team-list-table {
    .ant-table-wrapper {
      width: fit-content;
      min-width: 33%;
    }

    .recursive-table__container {
      padding: 48px 0;
    }

    thead > tr > th {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.24px;
      color: $black-100;
    }

    tbody > tr > td:not(:first-child) {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.24px;
      color: $black-90;
    }
  }

  .ant-table-thead {
    .ant-table-cell:last-child {
      padding-right: 50px;
    }
  }

  .additional_info {
    margin: 2rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $black-030;
    font-family: $family-stack-noto-sans;
  }

  .budget-info {
    display: block;
    color: var(--black-015);
    padding-bottom: var(--spacing-020);
  }

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: var(--spacing-024);
  }

  .fund-trnsfr-dwnld-csv-btn {
    background-color: var(--fill-brand-orange);
    border-radius: var(--radius-l);
    color: var(--text-body-brand);
  }

  .create-budget-button {
    height: 2.8rem;
    padding: 0.625rem 0.75rem;
    background-color: $bright-orange-050;
    color: $white-0;
    border-radius: 0.5rem;
    border: 0;
    font-weight: $font-weight-semi-bold;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }

  .action-buttons {
    display: flex;

    button {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }

    .action-buttons__button--stop {
      color: $red-060;
    }

    .action-buttons__button--stop:hover {
      color: inherit;
    }
  }

  .teams-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.flex-row {
      flex-direction: column;
      align-items: start;
      padding-top: 0.5rem;

      .additional_info {
        margin: 1.5rem 0;
      }
    }
  }

  .transaction-header-content {
    margin: var(--spacing-024) 0;

    span {
      &:first-child {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;

        color: $black-60;
      }
    }
  }

  .align-right {
    text-align: right;
  }

  .column-with-tooltip {
    display: flex;
    align-items: center;

    button {
      line-height: var(--size-008);
      height: var(--size-008);
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .overspend-icon {
    vertical-align: text-bottom;
  }
}

.typhography-caption {
  color: $black-70;
  font-size: 0.75rem;
}
