.icon {
  width: fit-content;
  text-align: center;
  cursor: pointer;
  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #f65731;
    padding-top: 8px;
    width: 100px;
  }
  &--disabled {
    pointer-events: none;
  }
}
