@import "src/assets/scss/variables.scss";

.transaction-accounting-tags {
  margin-bottom: 30px;
  width: 100%;
  position: relative;

  .accounting-tag-input {
    .ant-form-item-label > label {
      margin: 0;
    }

    &.disabled {
      .tag-input {
        background-color: $white-1;
        cursor: not-allowed;
        border-radius: 5px;

        &:hover {
          border-color: unset;
        }
      }
    }
  }
}
