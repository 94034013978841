@import "src/assets/v1.1/scss/spectrum";

.container {
  display: grid;
  grid-template-columns: 23% 75%;

  grid-column-gap: 1.5%;

  align-content: center;
  align-items: center;
  justify-items: start;

  & > h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: auto 0;
    width: 100%;
    word-break: break-word;
  }

  & > .content {
    width: 100%;
    height: 100%;
  }
}
