@import "src/Splice/scss/spectrum";
@import "src/Splice/scss/variables";

.login-screen-wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 520px auto;
  font-family: $font-family-montserrat;
}

.login-screen-wrapper__container {
  min-width: 400px;
  padding: 40px 30px;
  background: #f7fafc;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
