@import "src/assets/v1.1/scss/spectrum";

.contentItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &Empty {
    color: $black-50;
  }

  span {
    color: $black-70;
  }

  :global {
    .icon {
      min-width: 1.25rem;
      display: flex;
    }
  }

  .initial {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    color: $brand;
    background-color: $brand-20;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
  }
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.secondary {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  color: $black-70 !important;
}

.noRecipients {
  background-color: $black-10;
  text-align: center;
  margin-top: 2rem;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  border-radius: 0.5rem;

  .noRecipientsImage {
    width: 6.25rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: $black-90;
  }

  p {
    color: $black-80;
    font-size: 1rem;
  }
}

.highlightRow {
  & > td {
    background-color: $light-orange-05;
  }
}

.addRecipent {
  position: absolute;
  right: 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-008);
  }
}
.noData {
  margin-top: var(--spacing-048);
}
