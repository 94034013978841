@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spaces.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.lineChartTooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.4375rem 0rem;
  gap: $space-4;

  width: 9.0625rem;
  // height: 11.875rem;
  box-sizing: border-box;

  background: $white;
  border-radius: 0.5rem;

  letter-spacing: 0.02em;

  & > .total {
    width: 100%;
    padding: $space-4 $space-8;
    span {
      display: block;
      &:nth-child(1) {
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 1.5;
        color: $black-80;
      }
      &:nth-child(2) {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.33;
        color: $black-90;
      }
      &:nth-child(3) {
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 1.6;
        color: $black-70;
      }
    }
  }

  & > .subTotal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-4;
    width: 100%;
    padding: $space-4 $space-8;

    background: #f7fafc;
    border-radius: 0.25rem;

    & > .row {
      span {
        display: block;
        &:nth-child(1) {
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 1.6;
          color: $black-70;
        }
        &:nth-child(2) {
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 1.5;
          color: $black-90;
        }
      }
    }
  }
}
