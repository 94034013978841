@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.approval-flow__container {
  background: $white;
  height: 100vh;
  display: grid;
  padding: 72px 0 0;
  grid-template-rows: 120px 55px;
  grid-template-columns: 1fr 200px;

  .tabs-list {
    overflow: visible;
    grid-column: span 2;
    min-height: 100vh;
    .tabs-list-content-holder {
      padding-top: 1.5rem;
    }
  }

  .approval-flow__header {
    display: flex;
    justify-content: space-between;
    padding-right: 0.5rem;
    .approval-flow__self {
      color: $brand;
      background-color: $brand-20;
    }
    .approval-flow__title {
      font-size: 28px;
      line-height: 34px;
      font-weight: 700;
      color: $black;
      margin-bottom: 8px;
    }

    .approval-flow__subtitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $black;
    }
  }

  .approval-flow__action {
    button {
      padding: 12px 16px;
      border-radius: 8px;
      background-color: $orange;
      color: $white;
      font-size: 14px;
      height: auto;
      border: none;
      box-shadow: none;
      display: flex;
      gap: 0.25rem;
      align-items: center;
      .icon {
        display: flex;
      }
    }
    .spm-ctx-menu__popover__option {
      .approval-flow__action__option {
        .icon {
          display: flex;
        }
        display: flex;
        gap: 1rem;
        align-items: center;
        &__label {
          line-height: 1.25rem;
          font-size: 0.875rem;
          font-weight: 600;
          color: $black-90;
        }
      }
    }
  }

  .list__item__invalid-error {
    grid-column: span 3;
  }
}
