@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/scss/variables";

@mixin merchant-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
}

@mixin merchant-icon-style {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
}

@mixin details-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 1em;
  color: $white-10;
}

@mixin card-status-indicator {
  padding: 0;
  justify-content: center;
  position: absolute;
}

@mixin card-style {
  display: flex;
  flex-direction: column;
  width: 161px;
  height: 236px;
  padding: 24px;
  border-radius: 12px;
}

.merchant-icon {
  display: flex;

  @include merchant-icon-style;
}

.card {
  margin-right: 16px;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  background-color: $black-100;

  @include card-style;
  .merchant-lock-container {
    display: flex;
    height: 32px;
    align-items: flex-start;
    & > div:nth-child(2) {
      transform: translateX(-9px);
      border-radius: 50%;
      z-index: 2;
    }
    & > div:nth-child(3) {
      transform: translateX(-18px);
      border-radius: 50%;
      z-index: 3;
    }
    & > div:nth-child(4) {
      transform: translateX(-26px);
      z-index: 4;
      min-width: 2rem;
    }
  }
  .merchant-letter-icon {
    @include merchant-icon-style;

    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    font-size: 12px;
    color: $blue;
    background: $blue-20;
    border: 2px solid $white;
    border-radius: 50%;
    margin-bottom: 8px;
    padding: 0;
  }

  .status-overlay {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: rgba(19, 26, 46, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    padding: 24px 0 0 40px;
    .chip-indicator {
      right: 24px;
      width: 66px;
      height: 20px;
      font-weight: 600;
      font-size: 10px;
      line-height: 150%;
      background: $red-40;
      color: $black-90;
      top: 24px;
      @include card-status-indicator;
      & > div {
        margin-right: 4px;
        background: $red-80;
      }
    }
  }
  .chip-justify-center {
    padding: 0;
    & > div {
      position: relative;
      top: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .card-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.25em;
    color: $white-10;
    max-width: 113px;
    padding: 8px 0 4px 0;
  }
  .card-num,
  .team-name {
    @include details-text;
  }
  .team-name {
    padding-bottom: 4px;
  }
  .card-type {
    font-weight: 300;
    font-size: 10px;
    line-height: 1em;
    color: $white-10;
  }
}

.card-view-btn {
  background: $white;
  justify-content: center;
  align-items: center;
  color: $blue-80;

  @include merchant-text;
  @include card-style;
  & > div {
    margin-bottom: 8px;
  }
}
