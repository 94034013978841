.select-item {
  &__empty-state {
    text-align: center;
    padding-top: 40px;
    font-weight: 600;
    line-height: 20px;
    color: #f65731;
  }
  .ant-checkbox-group {
    width: 100%;
  }
}
