@import "src/assets/scss/variables.scss";

.otp-input-form {
  form {
    display: flex;
    justify-content: space-around;
  }

  .ant-form-item {
    margin: 0;
  }

  .otp-input-field {
    width: 35px;
    border: none;
    background: transparent;
    border-bottom: 2px solid $light-gray;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
    &:focus,
    &:visited {
      border-bottom: 2px solid $blue;
    }
    &--error {
      border-color: $red;
    }
  }

  &__error-message {
    margin-top: 12px;
    color: $red;
    text-align: center;
  }
}

.otp-input-form--secondary-xl {
  .ant-form {
    justify-content: center;
    .ant-form-item {
      margin: 0px 4px;
      input {
        caret-color: $blue;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        padding: 4px;
        color: $pure-black;
      }
    }
  }
}
