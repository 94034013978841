@import "src/assets/v1.1/scss/spectrum";

.container {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.5rem;

  & > * {
    box-sizing: border-box;
  }
}
