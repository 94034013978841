.pdf-viewer {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__left-arrow,
  &__right-arrow {
    position: absolute;
    top: 40%;
    cursor: pointer;
    pointer-events: all;
    z-index: 10;
    & > .icon__image {
      padding: 5px;
    }
  }
  &__right-arrow {
    right: 10%;
  }
  &__left-arrow {
    left: 10%;
  }
}
