@import "src/assets/v1.1/scss/spectrum";

.spm-menu-line-item {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 0.75rem;

  cursor: pointer;
  padding: 0.75rem 1rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  color: $black-80;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: $blue-20;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.disabled {
  color: var(--text-body-disabled);
}
