.ant-picker.date-picker {
  &__date {
    background: #f4f6f9;
    border-radius: 6px;
    width: 220px;
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #666b79;
    border: none;
  }
}
