@import "src/assets/v1.1/scss/spectrum.scss";

.content {
  display: flex;
  gap: 0.75rem;
  padding: 1.375rem 2rem;
  align-items: center;
  justify-content: space-evenly;
}

.invalidAmountError {
  display: flex;
  font-size: 0.75rem;
  color: $red-80;
  line-height: 1rem;
  margin-bottom: -0.5rem;
}

.errorSpacing {
  margin: -0.5rem 2.1rem 1rem;
}
