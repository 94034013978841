@import "../../assets/scss/variables";
@import "../../assets/v1.1/scss/spectrum.scss";

.topup {
  &__payment-btn {
    padding: 40px 50px;
    color: $dark-gray;
    margin-bottom: 16px;
    font-weight: 600;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    line-height: 1.6;

    &:hover,
    &--active {
      background: $zircon;
      color: $blue;
    }
  }

  &__payment-wrapper {
    padding: 24px 32px;
    border-radius: 8px;
    background: $zircon;
  }

  &__label {
    margin-bottom: 16px;
  }

  &__balance-amount {
    .info-unit__value {
      height: 40px;
    }
  }

  &__nium:not(:empty) {
    margin-top: var(--spacing-032);
  }
}

.bank-transfer {
  &__flag {
    display: inline-flex;
    margin-right: 8px;

    > img {
      width: 40px;
    }
  }

  &__bank-logo {
    margin-bottom: 12px;
    > img {
      height: 32px;
    }
  }

  &__info {
    font-size: 18px;
    color: $dark-gray-3;
    margin-bottom: 40px;
    line-height: 1.6;
  }

  &__note {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: $dark-gray-3;
    margin-bottom: 3.5rem;

    b {
      font-weight: 600;
    }

    // the color is in variables (since it's an old color
    // but it's being overwritten by the spectrum file
    &__link {
      color: #1e97ee;
      line-height: 1.5rem;

      &-icon {
        display: inline-block;
        height: 1rem;
        margin-left: 0.25rem;
        color: #1e97ee;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 16px;
  }

  &__item {
    background: $white;
    color: $dark-gray;
    border-radius: 8px;
    padding: 24px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  &__label-clipboard {
    margin-top: 12px;
    .table-row-field__title {
      font-size: 16px;
    }
    .table-row-field__details {
      display: flex;
    }
    .icon {
      margin-left: 16px;
      margin-top: 3px;
    }
  }

  &__label-text {
    margin-bottom: 8px;
  }

  &__help-text {
    font-weight: 300;
    font-size: 16px;
    color: $dark-gray-3;
    margin-bottom: 8px;
    .icon__image {
      width: 28px;
      height: 28px;
      margin-top: 12px;
    }
  }

  &__info-text {
    padding: 10px;
    border-radius: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $dark-gray-3;
    background-color: $peach;
    margin-bottom: 8px;
    .icon__image {
      width: 22px;
      height: 22px;
    }
  }

  &__modal {
    max-width: 508px;
    .modal__title {
      color: $primary;
    }
    .modal__content--children-elements {
      padding: 12px 32px;
      font-size: 16px;
      font-weight: 300;
    }
    &__list {
      padding-left: 20px;
      list-style: auto;
    }
  }

  &__paragraph {
    > ol {
      list-style: disc;
      > li {
        margin-left: 1.8rem;
      }
    }
  }

  &__bank_note {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  &__bold {
    color: $blue;
    font-weight: bold;
  }
}

.paynow {
  &__header {
    display: flex;
    font-weight: 600;
    align-items: center;
    font-size: 16px;
    margin-bottom: 24px;
  }
  &__qr-code {
    background: $white;
    padding: 32px;
    text-align: center;
    margin-bottom: 24px;
    border-radius: 8px;

    &-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 16px;
    }
  }
}
