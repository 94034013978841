@import "src/assets/scss/variables.scss";

.tax-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #e6f4ff;
    padding: 46px 0 10px 0;
  }
  .ant-form .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #e6f4ff;
  }
  &--form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
    .tax-name,
    .tax-rate {
      &--input,
      &--input:hover {
        background: transparent;
        color: #fff;
        &::placeholder {
          color: #c5c7cd;
        }
      }
      &--input:disabled {
        opacity: 0.4;
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    .action-btn-wrapper {
      margin-top: auto;
      &--button {
        width: 100%;
        background: #62cb94;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .ant-select-selector {
    color: $white;
  }
  &__confirmation-message {
    color: $white;
    margin-top: 30px;
    font-size: 20px;
    line-height: 1.5;
    flex: 1;
  }

  &__edit-btn {
    margin: auto;
    padding: 20px 0;

    .button {
      margin-bottom: 0;
      width: 184px;
    }
  }

  &__group-button {
    display: flex;
    gap: 18px;
    justify-content: space-between;

    .bg-green {
      .button {
        background-color: $green;
        border-radius: 30px;
      }
    }

    .bg-white {
      .button {
        background-color: $white;
        color: $dark-gray-5;
        border-radius: 30px;
      }
    }
  }

  .ant-select-clear {
    background: $dark-gray-2;
    color: white;
  }
}
