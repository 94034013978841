@import "src/assets/v1.1/scss/spectrum";

.dynamicForm {
  :global {
    .ant-form-item-extra {
      padding-top: 0.5rem;
      font-size: 0.75rem;
    }

    .ant-form-item-explain-error {
      div:not(:first-child) {
        display: none;
      }
    }
  }

  @for $i from 1 through 3 {
    .indent#{$i} {
      padding-left: 1rem * $i;
    }
  }

  .sectionHeader {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 2rem -1rem 0;
    padding: 2rem 1rem 0;
    border-top: 1px solid $black-40;
  }
}
