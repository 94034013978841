@import "src/assets/scss/variables";

.upload-invoice-container {
  .ant-form-item-control-input {
    min-height: auto;
  }
  .ant-upload-drag-container {
    vertical-align: top !important;
  }
  .react-pdf__Page__canvas {
    margin: auto;
  }
  .ant-form-item-label > label {
    color: $dark-gray-2 !important;
    font-size: 14px !important;
    margin: 32px 0 0 0;
    display: flex;
  }
  .ant-form-item-label > label:after {
    content: none;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-radio-button-wrapper {
    height: 48px;
    width: 110px;
    position: relative;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ant-picker,
  .select-amount__input-amount,
  .ant-input {
    border-radius: 4px;
    height: 48px;
    padding: 0 16px;
  }
  .ant-input-affix-wrapper {
    input {
      height: auto;
    }
    border-radius: 4px;
    height: 48px;
    padding: 0 16px;
  }
  .select-amount__input-amount::placeholder,
  .ant-input::placeholder,
  textarea.ant-input::placeholder {
    color: $light-grey;
    font-size: 16px;
  }
  .ant-select-selector {
    padding: 0 16px;
    border-radius: 4px;
    min-height: 48px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 16px;
    left: 16px;
  }
  .ant-select {
    border-radius: 4px;
    min-height: 48px;
    background: $white;
    font-size: 16px;
    width: 100%;
    > .ant-select-selector {
      > .ant-select-selection-search {
        > .ant-select-selection-search-input {
          padding: 0 16px;
        }
      }
    }
  }
  .ant-select.tag-input {
    height: auto;
    .ant-select-selection-search {
      left: -4px;
      .ant-select-selection-search-input {
        padding: 0px;
      }
    }
  }
  .ant-form-item-explain-error {
    padding-top: 4px;
  }
  .react-pdf__Document {
    overflow-x: scroll;
  }
  textarea.ant-input {
    border-radius: 4px;
    padding: 12px 16px;
  }

  .spm-label {
    font-size: 14px;
  }
  .country-select {
    margin-bottom: 32px;
  }
  .two-way-input {
    .sender__amount,
    .receiver__amount {
      height: 48px;
      input {
        height: auto;
      }
    }
  }

  .accounting-tag-input {
    .ant-input.tag-input {
      padding: 10px 16px;
    }
  }
}
