@import "src/assets/v1.1/scss/spectrum.scss";

.title {
  display: block;
  color: $black-100;
  font-size: 1rem;
  padding-top: 0.625rem;
  padding-left: 1.875rem;
}
.card {
  border-radius: 1.25rem;
  background-color: $white;
  padding: 1.875rem;
  margin: 1.25rem 0 5rem;
}
.container {
  padding-bottom: 5rem;
}
