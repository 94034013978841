@import "./variables.scss";

.tiles {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(231, 232, 242, 0.5);
  padding: 32px 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    &__title {
      font-size: 20px;
      line-height: 1.5em;
      color: $black;
    }
    &__action > a {
      line-height: 1.25em;
      color: $orange;
      &:hover {
        color: $red;
      }
    }
  }
}
