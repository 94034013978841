@import "src/assets/v1.1/scss/spectrum";

.container {
  position: relative;
}

.content {
  padding: 1.75rem 2rem 2rem;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 1rem;
}
