$space-2: 0.125rem;
$space-4: 0.25rem;
$space-8: 0.5rem;
$space-12: 0.75rem;
$space-16: 1rem;
$space-20: 1.25rem;
$space-24: 1.5rem;
$space-32: 2rem;
$space-40: 2.5rem;
$space-48: 3rem;
$space-56: 3.5rem;
$space-64: 4rem;
