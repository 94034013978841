.titleColor {
  color: var(--text-title-default);
}

.bodyColor {
  color: var(--text-body-default);
}

.linkColor {
  color: var(--text-link-default);
}

.brandColor {
  color: var(--text-body-brand);
}

.paymentMethod {
  padding: 0 var(--spacing-008);
}

.whiteBG {
  background-color: var(--fill-neutral-default);
}

.title {
  @extend .titleColor;
  padding: var(--spacing-008);
}

.flagContainer {
  display: flex;
  column-gap: var(--spacing-008);
  align-items: center;
}

.flagImg {
  width: var(--size-012);
  height: auto;
}

.walletInfo {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-004);
  justify-content: space-between;
}

.flex {
  display: flex;
  column-gap: var(--spacing-004);
}

.walletAmount {
  @extend .titleColor;

  display: flex;
  align-items: center;
  column-gap: var(--spacing-004);

  span {
    line-height: var(--size-008);
    height: var(--size-008);
    cursor: pointer;
  }
}

.radioSpace + .radioSpace {
  margin-top: var(--spacing-016);
}

.paynowLogo {
  height: var(--size-010);
  width: auto;
}

.paymentDetail {
  padding: var(--spacing-016);
  border-radius: var(--radius-m);
  background: var(--dark-navy-0);
  margin-top: var(--spacing-016);
}

.description {
  margin-bottom: var(--spacing-024);
}

// Bank Transfer Component
.bankDetailSection + .bankDetailSection {
  margin-top: var(--spacing-016);
}

.bankDetail {
  background-color: var(--white-0);
  margin-top: var(--spacing-008);

  img {
    height: 1.375rem;
    width: auto;
  }
}

.bankItem + .bankItem {
  margin-top: var(--spacing-012);
}

.instantReflectNotes {
  span {
    display: inline-block;
    line-height: inherit;
    vertical-align: middle;
  }

  img {
    height: var(--size-008);
    width: auto;
    margin-right: var(--spacing-004);
  }
}

.usdVABankBtn {
  @extend .brandColor;
  cursor: pointer;
}

.usdVAClause {
  @extend .bodyColor;
  list-style: decimal;
  padding-left: 1rem;
  margin-top: var(--spacing-012);
}

.paynowInput {
  margin-top: var(--spacing-012);
  margin-bottom: var(--spacing-016);
  display: flex;
  column-gap: var(--spacing-016);
  align-items: center;

  .amount {
    width: 15rem;
    height: 2.375rem;
    border-radius: var(--radius-s);
    border: var(--border-size-1) solid var(--border-neutral-default);
    background: var(--fill-neutral-default);
    padding: var(--spacing-008) var(--spacing-016);
    font-size: 0.875rem;
    line-height: var(--size-010);
    font-family: var(--font-noto-sans);

    &::placeholder {
      color: var(--text-body-disabled);
    }

    &:focus {
      border-color: var(--text-link-default);
      box-shadow: none;
    }
  }
}

.banner {
  margin-top: var(--spacing-016);
}

.autoPayBtn {
  margin-top: var(--spacing-012);
}

// Paynow component
.topUpBtn {
  padding: 0;
  margin-bottom: var(--spacing-012);
}

.qrCodeCard {
  @extend .whiteBG;
  text-align: center;
  padding: var(--spacing-040) var(--spacing-040) var(--spacing-024);

  img {
    width: 15rem;
    height: auto;
  }
}

// Copy Text component
.copyTextItem {
  display: flex;
  column-gap: var(--spacing-008);
  align-items: center;

  svg {
    cursor: pointer;
  }
}

// animation
$rotate-duration: 1s;

.rotate {
  transition: transform $rotate-duration linear;
  transform: rotate(0deg);
}

.rotating {
  animation: rotate $rotate-duration linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
