@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/v1.1/scss/typography";

.amount {
  display: flex;
  gap: 1rem;

  label {
    margin-bottom: 0.5rem;
    display: block;
    font-size: 0.75rem;
    color: $black-80;
  }

  input {
    background: $white;

    border: 0.063rem solid $black-40;
    border-radius: 0.25rem;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    align-content: center;

    font-weight: 400;
    font-size: 1rem;
    color: $black-80;

    &::placeholder {
      color: $black-50;
    }

    cursor: pointer;

    &.active,
    &:focus-within {
      outline: $blue-80 solid 0.063rem;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}
