@import "src/Splice/scss/spectrum";

.auth-box {
  &__redirect {
    &-btn {
      background: none;
      border: none;
    }

    a {
      color: $dark-orange-040;

      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .ant-form .ant-form-item {
    text-align: left;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ant-form-item-has-error {
    .ant-form-item-explain {
      margin-top: 0.5rem;
      color: $red-050;
    }

    .ant-input,
    .ant-input-password {
      border-color: $red-050;
      border-width: 2px !important;
    }

    .ant-input:focus {
      box-shadow: none;
      border-color: $red-050;
    }

    .ant-input:hover {
      border-color: $red-050;
    }
  }

  .ant-form .ant-form-item-label > label {
    line-height: 100%;
    font-size: 14px;
    color: $black-050;
    margin-top: 0;
    height: unset;
  }

  .ant-form .ant-form-item-label > label:after {
    content: none;
  }

  .login-password-field {
    > div {
      display: block;

      > label {
        width: 100%;
        display: flex;
        justify-content: space-between;

        a {
          font-size: 0.75rem;
          color: $black-015;
          font-weight: 400;
          line-height: 1rem;
        }
      }
    }

    .ant-input-affix-wrapper {
      padding: 0;
      padding-right: 1rem;
      margin-top: 0.625rem;

      input {
        margin: 0;
        padding: 0.75rem 1rem;
      }

      .ant-input-password-icon svg {
        width: 17px;
        height: 17px;
      }

      &:focus {
        border-color: $blue-060;
        box-shadow: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .ant-form-item-label > label.ant-form-item-required:before {
    display: none;
  }

  .ant-input {
    height: 3rem;
    // box-shadow: 0px 0.625rem 20px rgba(0, 0, 0, 0.03);
    border: 1px solid $white-060;
    margin-top: 0.625rem;
    padding: 0.75rem 1rem;
    border-radius: 4px;

    &:focus {
      border-color: $blue-060;
      box-shadow: none;
    }
  }
}
