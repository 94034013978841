@import "src/assets/scss/variables";

.outer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  .inner {
    position: absolute;
    padding-top: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    width: 254px;
    height: 243px;
    background-color: $white;
    border-radius: 24px;
    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding-top: 27px;
      padding-bottom: 4px;
      text-align: center;
      color: $black;
    }
  }
}
