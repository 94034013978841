@import "src/assets/v1.1/scss/spectrum.scss";

.wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 28.75rem;
  padding: 1rem 2.5rem;
  background: $black-10;
  border-top: 0.063rem solid $black-40;
  .container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .button {
      margin-right: 0.5rem;
      height: 3rem;
      flex-grow: 1;
    }
    .approve {
      color: $white;
      background-color: $green-80;
      border: 0.063rem solid $green-80;
      &:hover {
        color: $green-80;
        background: $white;
        border: 0.063rem solid $green-80;
      }
    }
    .save {
      color: $white;
      background-color: $green-80;
      border: 1px solid $green-80;
      padding: 0;
      &:hover {
        color: $green-80;
        background: $white;
        border: 1px solid $green-80;
      }
      &:disabled {
        color: $white;
        background: $black-50;
        border: transparent;
      }
    }
    .decline {
      color: $red;
      background: $white;
      border: 0.063rem solid $red;
      &:hover {
        color: $white;
        background: $red;
        border: 0.063rem solid $red;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
