.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--spacing-024);
}

.bodyColor {
  color: var(--text-body-default);
}

.captionColor {
  color: var(--text-caption-default);
}

.titleColor {
  color: var(--text-title-default);
}

.paymentRunPreview {
  padding: var(--spacing-016) 0;

  > button {
    margin-bottom: var(--spacing-020);
  }
}

.cycleTitle {
  @extend .titleColor;
  padding-left: var(--spacing-008);
}

.totalPayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionItem {
  padding-bottom: var(--spacing-008);
}

.verticalSpace + .verticalSpace {
  margin-top: var(--spacing-016);

  &.sectionItem {
    padding: var(--spacing-008) 0;
  }
}
