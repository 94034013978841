.close {
  right: 1.25rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: absolute;
}

.fix-to-top {
  top: 1.5rem;
}
