.legendLabel {
  text-transform: capitalize;
  color: var(--text-body-default);

  font-family: var(--font-noto-sans);
  font-size: var(--font-size-00);
}

.legend {
  margin-top: var(--spacing-016);
  display: flex;
}

.card {
  position: relative;
  background-color: var(--fill-neutral-default);
}

.amount {
  font-size: var(--font-size-00);
  color: var(--text-title-default);
  font-weight: var(--font-weight-6);
  line-height: var(--font-lineheight-3);
}

.date {
  color: var(--text-body-subtle);
  font-size: var(--font-size-00);
  line-height: var(--font-lineheight-1);
}

.section {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--text-title-default);
  font-size: var(--font-size-1);
}

.loadingContainer {
  // Match the chart SVG width and height to prevent layout shift
  width: 31.25rem;
  height: var(--size-100);
  display: flex;
  align-items: center;
  justify-content: center;
}
