@import "src/assets/scss/variables.scss";

.bulk-upload-error-modal {
  width: 411px;
  height: 460px;
  padding-bottom: 35px;
  padding-top: 18px;
  border-radius: 8px;
  overflow-y: hidden;
  .modal__close {
    display: none;
  }
  .modal__title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.41px;
    color: $black;
  }
  .bulk-upload-modal-container {
    text-align: center;
    .bulk-upload-error-icon {
      margin: auto;
      .icon__image {
        width: 130px;
        height: 130px;
      }
    }
    .error-description {
      padding: 15px 52px 28px 30px;
      font-size: 14px;
      line-height: 20px;
      color: $black-2;
      font-weight: 300;
      .error-description-two {
        padding-top: 24px;
      }
    }
    .action-btns {
      display: flex;
      justify-content: center;
      button {
        padding: 0;
        min-height: 40px;
        border-radius: 4px;
        span {
          font-size: 12px;
          line-height: 120%;
        }
      }
      &__download-btn {
        width: 159px;
        background-color: $green;
        margin-right: 30px;
        min-height: 40px;
        border-radius: 4px;
        color: $white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__close-btn {
        min-width: 72px;
        border: 1px solid $light-grey-7;
        background: $white;
        color: $black-2;
      }
    }
  }
  .breakline {
    border: 1px solid $light-grey-2;
    margin: 26px 0 19px 0;
  }
}
