@import "src/assets/v1.1/scss/spectrum";

.date-section {
  & > .ant-form-item:first-child > .ant-form-item-label > label {
    margin-top: 0;
  }
  & > .ant-form-item > .ant-form-item-label {
    overflow: visible;
  }

  h3 {
    color: $yellow;
  }
}

.optimized-label {
  display: flex;
  background-color: $blue-10;
  padding: 1rem;
  label {
    margin-left: 0.625rem;
    margin-top: 0.125rem;
    font-size: 1rem;
    color: $blue;
  }
}

.icon-tooltip {
  position: absolute;
  z-index: 9;
  right: 1.25rem;
  bottom: 0.5rem;

  &-payment-schedule {
    right: 2.713rem;
  }
}

.loader-container {
  position: absolute;
  z-index: 9;
  right: 2.713rem;
  bottom: 0.8rem;

  .custom-loader {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.213rem solid $brand;
    border-right-color: transparent;
  }
}

.date-hint {
  margin-top: 0.512rem;
}

.icon-cursor-default {
  cursor: default;
}
