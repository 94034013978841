.select-data-item {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f5f6f9;
  cursor: pointer;
  &__checkbox {
    display: flex;
    flex-direction: row-reverse;
    width: 90%;
    min-height: 64px;
    align-items: center;
    & > span:nth-child(2) {
      color: #131a2e;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding-left: 12px;
      cursor: pointer;
      width: 100%;
    }
  }

  .ant-checkbox-input {
    min-width: 60px;
    min-height: 65px;
    top: -25px;
    left: -10px;
  }

  .ant-checkbox {
    width: 28px;
    height: 28px;
    margin-top: 4px;
  }

  .ant-checkbox-inner {
    opacity: 0;
  }

  .ant-checkbox-checked:after {
    content: url("../../../assets/img/checkmark.svg");
    border: 0;
    visibility: visible;
    animation: none;
  }

  .ant-checkbox::before {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    border: 1px solid #c5c7cd;
    border-radius: 50%;
    background: #fff;
  }
}
