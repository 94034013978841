@import "../../../assets/scss/variables.scss";
@import "../../../assets/v1.1/scss/spectrum.scss";

.spm-search-dropdown {
  .spm-search-display-selected-values:first-child {
    .approver_or_container {
      display: none;
    }
  }
  .approver_or_container {
    text-align: center;
    margin: 0.563rem 0;
    .approver_or {
      width: 3rem;
      padding: 0.375rem;
      border-radius: 0.25rem;
      background: $black-20;
      color: $black-80;
      font-weight: 700;
    }
  }
  .spm-search-dropdown__value {
    padding: 11px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: $dark-gray-2;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-areas:
      "text closeButton"
      "error error";

    .spm-search-dropdown__value__text {
      grid-area: text;
    }

    .spm-search-dropdown__value__delete {
      grid-area: closeButton;
      width: 12px;
      height: 12px;
      position: absolute;
      right: 12px;
      top: calc(50% - 6px);
      filter: brightness(0);
      display: none;
    }

    &:hover {
      .spm-search-dropdown__value__delete {
        display: block;
      }

      background: $zircon;
    }

    .spm-search-dropdown__error {
      grid-area: error;
      font-size: 12px;
      color: #e5304c;
      line-height: 16px;
      font-weight: 400;
    }
  }

  .spm-search-dropdown__addmore {
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    width: 100%;
    color: $brand;
    background: $brand-20;
    text-align: center;
    padding: 0.5rem;
    border: 0rem;
    border-radius: 0.5rem;
    height: auto;

    &:disabled {
      background: $light-gray;
      color: $white;
    }
  }

  .spm-search-dropdown__input {
    .ant-input {
      border-radius: 0rem;
    }
  }
}
