.amount-details {
  padding: 40px 30px 40px 60px;
  .title,
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #131a2e;
    margin-bottom: 0;
    &:first-child {
      padding-bottom: 20px;
    }
  }
  .ant-input-textarea-clear-icon {
    padding-top: 10px;
  }
  .ant-form-item {
    flex-flow: column;
  }
  &__select-wallet {
    &--details {
      display: flex;
      align-items: center;
      & > h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #131a2e;
        padding-left: 20px;
      }
    }
  }
  &__select-amount {
    .ant-input-group.ant-input-group-compact {
      display: flex;
    }
    p {
      font-size: 12px;
      line-height: 20px;
      color: #b9bac0;
      width: 50%;
      padding-top: 14px;
    }
    & > h4 > span {
      color: #f65731;
    }
  }
  &__schedule-payment,
  &__expiry-date {
    text-align: justify;
    padding-top: 40px;
    h4 {
      width: 100%;
    }
    & > div {
      display: inline-block;
      width: 50%;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding: 10px;
    }
  }
  &__upload-image,
  &__comments,
  &__select-amount {
    padding-top: 40px;
  }
  &__comments {
    .ant-input {
      font-size: 14px;
      line-height: 22px;
      color: #131a2e;
      border: 1px solid #e4e8ed;
      border-radius: 6px;
      min-height: 112px;
      resize: none;
    }
    .ant-input-textarea::after {
      float: right;
      white-space: nowrap;
      content: attr(data-count) " / 255";
      pointer-events: none;
      margin-right: 16px;
    }
  }
  .confirm-btn {
    display: flex;
    width: 240px;
    justify-content: center;
    margin-left: auto;
    margin-top: 40px;
    background: #1fce8f;
  }
  &__interval {
    float: left;
    padding: 10px 16px;
    border: 1px solid #e6e7ec;
    border-radius: 12px;
    margin: 20px 10px;
    color: #131a2e;
    cursor: pointer;
    &.active {
      border: 1px solid #f65731;
    }
  }
  &__card-details,
  &__delivery-to-name,
  &__shipping-address {
    .ant-input {
      margin: 0;
      max-height: 48px;
    }
    .ant-form-item-label > label.ant-form-item-required:before {
      display: none;
    }
  }
  &__card-details,
  &__delivery-to-name {
    .ant-input {
      max-width: 285px;
    }
    .ant-form-item input[type="checkbox"] {
      min-width: 24px;
      min-height: 24px;
      border-radius: 6px;
    }
  }
  &__delivery-to-name {
    padding-top: 20px;
  }
  &__contact-details-check {
    padding-top: 16px;
    .ant-checkbox-inner {
      height: 24px;
      width: 24px;
      border-radius: 6px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f65731;
    }
    .ant-checkbox-checked:after,
    .ant-checkbox-checked .ant-checkbox-inner {
      border: #f65731;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
      margin-left: 3px;
    }
  }
  &__shipping-address {
    & > h4 {
      padding: 20px 0;
    }
    & > div {
      display: flex;
      padding: 4px 0;
      &:first-of-type {
        .ant-form-item:last-child {
          width: 70%;
          margin-left: 8px;
        }
      }
      &:last-child {
        .ant-form-item:first-child {
          width: 70%;
          margin-right: 8px;
        }
      }
    }
  }
  &__default-expense-category {
    margin-top: 10px;
    width: 75%;
  }
}
