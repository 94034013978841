@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.tab-status-pending-detail {
  padding-bottom: 5rem;

  .ant-steps-item {
    min-height: 5rem;
  }
  .ant-steps-item-title {
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 600;
    color: $black-50;
  }
  .ant-steps-item-description {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after,
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: $orange;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: $orange;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
  .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-tail:after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: $light-grey;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: $black-50;
  }
  .ant-steps-item-description {
    color: $black-100 !important;
    padding-bottom: 0;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
    margin-left: 0.875rem;
    top: -0.5rem;
    position: relative;
    min-height: 8rem;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: $orange;
  }
  .ant-steps-icon-dot {
    width: 0.75rem !important;
    height: 0.75rem !important;
  }
  .ant-steps-item-content {
    width: auto;
  }
  .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
    left: 0;
  }

  .ant-steps-item.has-error,
  .ant-steps-vertical > .ant-steps-item.has-error > .ant-steps-item-container > .ant-steps-item-tail:after {
    min-height: 6.25rem;
  }
  .error-box {
    .error-box__icon {
      filter: brightness(15);
    }
    gap: 0.25rem;
    padding: 0.25rem 0 0;
    background: none;
    color: $white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }

  .audit-trail-notes {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: $black-60;
    font-size: 0.75rem;

    h4 {
      color: $black-60;
      margin-bottom: 0.25rem;
    }
  }
}
