@media screen and (max-width: 600px) {
  .container {
    padding: 1rem;
  }

  .description {
    width: 100% !important;
  }

  .text {
    text-align: center;
  }
}
