@import "src/assets/v1.1/scss/spectrum";

#requirement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 1rem 1.75rem;
  padding: 1.25rem;

  background: $black-10;
  border-radius: 0.5rem;
}
