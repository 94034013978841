@import "../../../../assets/scss/variables.scss";

.spm-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid $border-grey;
  display: flex;
  justify-content: center;
  &.radio {
    border-radius: 50%;
  }
  &.checked {
    &:not(.radio) {
      background: $orange;
      .spm-checkbox__inner {
        padding: 6px;
        filter: brightness(100);
      }
      &.disabled {
        background: $inactive-grey;
      }
    }
    &.radio {
      padding: 4px;
      border-color: $orange;
      .spm-checkbox__inner {
        border-radius: 50%;
        background: $orange;
        width: 100%;
        height: 100%;
      }
      &.disabled {
        border-color: $border-grey;
        .spm-checkbox__inner {
          background: $inactive-grey;
        }
      }
    }
  }
  &:not(.checked) {
    &:not(.radio) {
      .spm-checkbox__inner {
        opacity: 0;
      }
    }
  }
  &.disabled {
    background: $white-1;
  }
  &:hover {
    border-color: $accent-blue;
  }
}
