@import "src/assets/scss/variables.scss";

.chat-widget__header {
  &--small,
  &--large {
    display: inherit;
    justify-content: center;
    h4 {
      justify-content: flex-end;
      font-weight: bold;
      line-height: 18px;
      color: $white;
    }
    .icon {
      position: absolute;
    }
  }
  &--small {
    padding-bottom: 16px;
    h4 {
      font-size: 12px;
    }
    .icon {
      top: 10px;
      right: 12px;
    }
  }
  &--large {
    background: #131a2e;
    padding: 14px 0;
    border-radius: 8px 8px 0 0;
    h4 {
      font-size: 16px;
    }
    .icon {
      top: 13px;
      right: 22px;
    }
  }
}
