.ant-tooltip {
  max-width: 360px;

  .ant-tooltip-arrow:before {
    background-color: white;
  }

  .ant-tooltip-inner {
    border-radius: 8px;
    padding: 8px;
  }
}
