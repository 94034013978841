@import "src/assets/scss/variables";

.text {
  display: block;
}
.title {
  font-size: 13px;
  color: $gray;
}
.content {
  font-size: 16px;
  color: $dark-gray;
  word-wrap: break-word;
}
.container {
  position: relative;
}
.container:not(:last-child) {
  margin-bottom: 24px;
}
