@import "src/assets/v1.1/scss/spectrum.scss";

.card-list {
  &.data-table-size__large.data-table-content {
    padding: 0px;
    .ant-table-wrapper {
      padding-top: 0;
    }
    .ant-table-wrapper .ant-table {
      .ant-table-container .ant-table-thead > tr > th {
        color: $black-90;
        line-height: 1.25rem;
        padding: 0.875rem 1rem;
        background-color: $black-30;
      }
      .ant-table-tbody {
        .ant-table-row {
          &.row-selected {
            background-color: $brand-20;
          }
        }
      }
      .ant-table-tbody > tr > td {
        line-height: 1.25rem;
        vertical-align: middle;
        padding: 0.75rem 1rem;

        &:hover {
          cursor: pointer;
        }
      }

      .ant-table-tbody > tr:nth-child(odd) {
        background-color: $white;
        border-radius: 0.5rem;
      }
      .ant-table-tbody > tr:nth-child(even) {
        background-color: $black-10;
        & > td {
          &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
          }
          &:last-child {
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        .ant-table-selection,
        .ant-checkbox-wrapper,
        .ant-checkbox {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner {
          background-color: $brand;
          border-color: $brand;
          &:after {
            background-color: $white;
            height: 0.125rem;
            width: 0.625rem;
          }
        }
      }
    }
    .ant-table-wrapper .ant-table-empty {
      .ant-table-tbody {
        > tr > td {
          padding: 0;
          &:hover {
            background-color: $white;
          }
        }
      }
    }
    &.request-card-listing {
      .ant-table-wrapper .ant-table .ant-table-tbody > tr > td {
        padding: 1rem;
      }
    }
    &.card-list-skeleton {
      .ant-table-wrapper .ant-table .ant-table-tbody > tr {
        height: 72px;
      }
    }
  }
}

@mixin shimmer-input-height($height) {
  height: $height !important;
}
@mixin shimmer-input-border-radius {
  border-radius: 1rem;
}

@mixin skeleton-input($width, $height) {
  width: $width !important;
  @include shimmer-input-height($height);
  @include shimmer-input-border-radius;
}

@mixin text-ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
