@import "../../assets/scss/variables.scss";

.file-upload {
  min-width: 400px;
  padding: 20px 80px;

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__text {
    font-weight: 600;
    font-size: 1.2rem;
    color: $dark-grey-2;
    margin-bottom: 10px;
  }

  &__hint {
    color: $light-grey;
    margin-bottom: 10px;
  }

  &__error {
    color: $red;
    margin-bottom: 10px;
  }

  &__button {
    margin-top: 20px;
  }
}
