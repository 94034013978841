@import "../../../../assets/scss/variables.scss";

.spm-tag {
  color: $spectrum-blue-1;
  background: $spectrum-blue-20;
  border: 1px solid $secondary-blue;
  border-radius: 16px;
  padding: 2px 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
  gap: 8px;

  .spm-tag__close-btn {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }
}
