@import "src/Splice/scss/spectrum";
@import "src/assets/v1.1/scss/spectrum";

.xero-setup {
  &__step-bar {
    & :global(.ant-steps-item-content) {
      text-align: start;
      width: 13.75rem;
      white-space: nowrap;
    }
    & :global(.ant-steps-item:last-child) {
      & :global(.ant-steps-item-content) {
        width: 7.125rem;
      }
    }
    & :global(.ant-steps-item-finish .ant-steps-item-title) {
      color: $brand !important;
    }
  }
  &__action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      max-width: 13.375rem;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        margin-left: 0.75rem;
      }
    }
  }
  &__tag-container {
    max-height: 1.5rem;
    margin-left: 0.75rem;
  }
}

.navy {
  background-color: $navy-0;
}

.description {
  color: var(--text-body-default);
}
