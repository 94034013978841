.role-explanation-modal {
  width: 806px;
  height: 635px;
  margin-top: 22px;

  table {
    font-family: "Public Sans", sans-serif;
    width: 100%;
    color: #333744;
  }

  th {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: #f5f8fd;
    border-top: 0px solid;
    height: 48px;
    vertical-align: middle;
  }

  td {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    height: 48px;
  }

  tr > th {
    border-top: 0px solid;
  }

  td:first-child {
    text-align: left;
  }

  th:first-child {
    border-radius: 8px 0px 0px 0px;
    width: 246px;
  }

  th:not(:first-child) {
    width: 112px;
    text-align: center;
    vertical-align: middle;
  }

  th:last-child {
    border-radius: 0px 8px 0px 0px;
  }

  td,
  th {
    border: 1px solid #e4e5e7;
    padding: 8px;
  }

  td:nth-child(even) {
    background-color: #fafafa;
  }

  td,
  th {
    border-left: 0px solid;
    border-right: 0px solid;
  }

  .footer {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #676b78;
    margin-top: 24px;
  }

  .footer > div > img {
    margin-right: 3px;
    margin-left: 0px;
  }

  .footer-first {
    margin-left: 13px;
  }

  .footer-second {
    margin-top: 4px;
  }

  tr > td > img {
    margin-left: 36px;
  }
}
