@import "../../assets/scss/variables";

.select-amount {
  color: #131a2e;
  font-family: "Spenmo", sans-serif;
  width: 480px;
  max-width: 100%;

  & &__input-wrapper {
    display: flex;
    .ant-select-selector {
      border: none;
    }
  }

  &__select {
    background: $white;
    border-radius: 5px !important;
    border: 1px solid $border-color;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    .ant-select-arrow .anticon {
      color: #131a2e;
      font-size: 10px;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & &__input-amount {
    border-radius: 5px !important;
    border: 1px solid $border-color;
    margin-left: 20px;
    box-shadow: none;
    font-size: 16px;
    line-height: 24px;
    padding: 0 20px;
    margin-top: 0;
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  &__amount-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  &__currency {
    color: $dark-gray;
    font-size: 18px;
    padding: 8px 16px;
  }

  .ant-select-arrow {
    margin-right: 0px;
  }

  .ant-form-item-explain {
    margin-top: 8px;
  }

  &--transparent {
    .select-amount__select {
      background: transparent;
    }
  }
}
