@import "../../assets/scss/variables.scss";

.detail-dropdown__button {
  cursor: text;
  padding: 12px 16px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  &:focus,
  &:hover {
    color: inherit;
  }
}

.detail-dropdown__popover {
  .ant-popover-inner-content {
    padding: 0px;
  }
  .detail-dropdown__popover__content {
    li {
      padding: 12px 16px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;

      &.active,
      &:hover {
        background: $white-smoke;
        color: $orange;
      }
    }
  }
}

.detail-popover {
  padding: 24px;
  position: relative;
  .detail-popover__close-button {
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    left: 24px;
    top: 24px;
  }
  h2 {
    margin: 4px 24px;
    font-size: 16px;
    font-weight: 600;
  }
}
