@import "src/assets/scss/variables";

.container {
  width: 544px;
  .body {
    border-top: 1px solid $light-gray-2;
    padding: 24px 40px 32px;
    margin-top: 24px;
    color: $gray;
    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      b {
        font-weight: 700;
        color: $black;
      }
    }
    .box {
      margin: 24px 0 0;
      padding: 14px 12px;
      background-color: $white-smoke;
      border-radius: 8px;
      .line {
        width: 100%;
        background-color: $light-gray;
        height: 1px;
        margin-bottom: 14px;
      }
      .item {
        display: grid;
        grid-template-columns: 130px 15px auto;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas: ". . .";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        .color-black {
          color: $black;
        }
      }
      .item:not(:last-child) {
        padding-bottom: 14px;
      }
      .topup-admin {
        line-height: 30px;
      }
      .topup-value-admin {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        color: $black;
      }
      .topup-non-admin {
        line-height: 24px;
      }
      .topup-value-non-admin {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: $black;
      }
    }
  }
  .cta {
    display: flex;
    justify-content: center;
    .button {
      padding: 20px 50px;
      cursor: pointer;
      user-select: none;
      border-radius: 30px;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .mainCta {
      background-color: $orange;
      color: $white;
    }
    .secondaryCta {
      background-color: $peach;
      color: $orange;
    }
  }
}
