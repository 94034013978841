@import "src/assets/scss/variables";

.flow-item {
  position: relative;
  margin: 0px 20px;
  cursor: default;
  &.disabled {
    pointer-events: none;

    .flow-item__menu {
      display: none;
    }
  }
  .flow-item__container {
    position: relative;
    min-width: 240px;
    max-width: 440px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    &.block {
      background: $white;
      border-radius: 12px;
      box-shadow: 0px 1px 9px 1px $box-shadow;
      .flow-item__icon {
        background: none;
        box-shadow: none;
        width: 32px;
        height: 32px;
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }
    &.sequence {
      background: $white;
      border-radius: 12px;
      box-shadow: 0px 1px 9px 1px $box-shadow;
      .flow-item__icon {
        background: $peach;
        color: $orange;
        box-shadow: none;
        width: 32px;
        height: 32px;
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }
    &.dashed {
      .flow-item__icon {
        background: $light-grey-2;
        box-shadow: none;
        border: 2px dashed $light-gray;
      }
    }
    .flow-item__icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      box-shadow: 0px 1px 9px 1px $box-shadow;
    }
    .flow-item__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-align: center;
      color: $black;
      margin-bottom: 12px;
    }
    .flow-item__subtitle {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-align: center;
      color: $gray;
      margin-bottom: 20px;
    }
    .flow-item__children {
      min-width: 100%;
    }
    .flow-item__menu {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }

  &.line-in::before {
    position: absolute;
    top: 44px;
    left: -24px;
    content: "";
    width: calc(50% + 24px);
    height: 2px;
    background: $light-gray;
    z-index: 0;
  }
  &.line-in--active::before {
    background: $orange;
  }

  &.line-out::after {
    position: absolute;
    top: 44px;
    left: 50%;
    content: "";
    width: calc(50% + 24px);
    height: 2px;
    background: $light-gray;
    z-index: 0;
  }
  &.line-out--active::after {
    background: $orange;
  }
}

.flow-item__menu__popover {
  .ant-popover-content {
    .flow-item__menu__delete-btn {
      cursor: pointer;
      color: $red;
      display: flex;
      font-size: 14px;
      line-height: 22px;
      img {
        margin-right: 12px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
