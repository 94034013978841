@import "src/assets/scss/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .child {
    margin-bottom: 20px;
    width: 130px;
    height: 130px;
    > img {
      border-radius: 8px;
      width: 130px;
      max-height: 130px;
    }
  }
  .add {
    border-radius: 8px;
    border: 1px dashed $light-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .wrapper {
      cursor: pointer;
      .icon {
        margin: auto;
        > img {
          width: 65px;
          height: 65px;
        }
      }
      .text {
        color: $blue;
        font-size: 14px;
        user-select: none;
      }
    }
  }
  .hidden {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .inner {
    width: 130px;
    height: 130px;
    margin-bottom: 20px;
  }
}
