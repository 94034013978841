@import "src/assets/v1.1/scss/spectrum.scss";

.text {
  margin-bottom: 0.875rem;
  display: block;
}
.normal {
  color: $black-90;
  font-size: 0.875rem;
}
.big {
  color: $black-100;
  font-size: 1.5rem;
}
.small {
  color: $black-90;
  font-size: 0.75rem;
}
.space {
  margin-bottom: 1.875rem;
}
.button {
  width: 100%;
  text-align: center;
}
.confirm {
  margin-bottom: 1.25rem;
  height: 2.5rem !important;
}
.cancel {
  user-select: none;
  cursor: pointer;
}
