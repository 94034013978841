@import "./variables";

@mixin circle-img($size) {
  width: $size;
  height: $size;
  border-radius: 100%;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@mixin button-variant($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;

  &:hover,
  &:active {
    background-color: darken($bg-color, 10%);
    color: $text-color;
  }

  &.button--outline {
    background-color: transparent;
    border: 1px solid $bg-color;
    color: $bg-color;

    &:hover,
    &:active {
      background-color: lighten($bg-color, 10%);
      color: $text-color;
    }
  }
}

@mixin card-shadow {
  box-shadow: 0px 0px 4px rgba(51, 55, 68, 0.08);
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 0px 0px 8px 1px rgb(51 55 68 / 10%);
  }
}

@mixin cards-drop-down {
  padding: 0px;
  box-shadow: 0px 0px 10px 4px rgba(51, 55, 68, 0.08);
  & .rc-virtual-list {
    background: $white;
    border-radius: 4px;
    & .ant-select-item {
      padding: 12px 16px;
    }
    & .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background: $alice-blue;
    }
    & .ant-select-item-option-content {
      padding: 0px;
      font-weight: 400;
      font-size: 16px;
      color: $dark-gray-1;
      line-height: 24px;
    }
  }
}

/* Shimmer effect */
@mixin shimmerAnimation($name) {
  animation-name: $name;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-clip: content-box;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

/**
 * Mixin for styling scrollbars.
 * This mixin provides a standardized appearance for scrollbars across elements
 * that use the ::-webkit-scrollbar pseudo-element (primarily on Webkit-based browsers).
 */
@mixin scrollbar {
  /* width and height */
  &::-webkit-scrollbar {
    appearance: none;
    width: var(--size-004);
    height: var(--size-004);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: var(--radius-1);
    background: var(--fill-neutral-strong);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-1);
    background: var(--dark-navy-015);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--dark-navy-030);
  }

}

/**
 * Mixin for styling the corner of the scrollbar (where both horizontal and vertical scrollbars meet).
 * This mixin provides styling for the scrollbar corner on Webkit-based browsers.
 */
@mixin scrollbarCorner {
  &::-webkit-scrollbar-corner {
    background: var(--fill-neutral-strong);
  }
}
