@import "src/assets/v1.1/scss/spectrum";

@mixin loader($size, $border) {
  & > .loading {
    width: $size;
    height: $size;
    border: $border solid $brand;
    border-radius: 80%;
    border-right-color: transparent;
    animation: loader 0.6s ease-out infinite;
    margin: auto;

    @keyframes loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@mixin searchIcon($size) {
  &.active > .search__icon {
    background-color: $black-90;
  }

  & > .search__icon {
    width: $size;
    height: $size;

    display: inline-flex;
    background-color: $black-50;

    mask: url("../../../assets/v1.1/icons/Core/Search/line_16.svg");
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
  }
}

@mixin searchInput($fontSize, $lineHeight) {
  & > input[type="search"] {
    border: none;
    padding: 0;
    width: calc(100% - 1.5rem);
    transition: width 200ms;
    border-radius: 0;

    font-weight: 400;
    color: $black-80;

    font-size: $fontSize;
    line-height: $lineHeight;

    &::placeholder {
      font-weight: 400;
      color: $black-50;

      font-size: $fontSize;
      line-height: $lineHeight;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

@mixin closeIcon($size) {
  &.active:focus-within > .close {
    width: $size;

    background: $blue-80;
    visibility: visible;

    transition: width 400ms, visibility 200ms;
  }

  & > .close {
    width: 0;
    height: $size;

    padding: 0;
    border: none;

    visibility: hidden;
    background: transparent;

    cursor: pointer;
    pointer-events: visiblePainted;

    transition: width 400ms, visibility 200ms;

    mask: url("../../../assets/v1.1/icons/Navigation/Cross/fill_16.svg");
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
  }
}

@mixin searchBase($padding) {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  padding: $padding;

  gap: 0.75rem;

  width: 100%;
}
