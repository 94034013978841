@import "src/assets/v1.1/scss/spectrum.scss";

.action-with-more-menu {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  :global(.ant-btn) {
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 2.5rem;
    line-height: 1.25rem;
    height: auto;
    padding: 0.5rem 1.875rem;
    min-width: 10rem;
  }

  &__button {
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: 2.5rem;

    &--primary:global(.ant-btn) {
      display: flex;
      justify-content: center;
      background-color: $brand;
      color: $white;
      border: 1px solid $brand;
      svg {
        fill: $white;
      }
    }

    &--secondary:global(.ant-btn) {
      background: $brand-20;
      color: $brand;
      border: 1px solid $brand-20;
      display: flex;
      justify-content: center;
      svg {
        fill: $brand;
      }
    }
  }
  &__source-icon {
    cursor: pointer;
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__active-icon {
    background-color: $black-30;
  }
}

.action-with-more-menu__target-container {
  position: fixed;
  width: 15vw;

  @for $i from 0 through 6 {
    &__item__#{$i} {
      top: calc(100vh - (7.2rem + (2.3rem * ($i - 1)))) !important; // Customizing top position for the popover
    }
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
    width: auto;
  }
}
