@import "src/assets/v1.1/scss/spectrum.scss";

.transaction-details {
  min-height: calc(100vh - 4.5rem);
  display: flex;
  flex-direction: column;
  .table-row-field {
    margin-top: 2rem;
  }
  &--preview-btn {
    position: absolute;
    top: 2.5rem;
  }
  &__header,
  &__details {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__transaction-details {
    width: 80%;
  }
  &__details {
    .table-row-field {
      flex: 1;
    }
  }

  &__redirect_link {
    margin-top: 0.5rem;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }

  &__header {
    .table-row-field {
      margin-top: 8px;
    }
  }

  &__receipt-wrapper {
    margin-top: 1.875rem;
    & > p {
      font-size: 1rem;
      line-height: 1.75rem;
      color: $black-50;
      padding-top: 0.5rem;
    }
    &__receipts {
      margin-top: 1.25rem;
      display: flex;
      overflow-x: scroll;
      max-width: 450px;
    }
  }
  .button {
    margin-top: auto;
    border-radius: 1.875rem;
    margin-bottom: 1.125rem;
  }

  .confirm-btn {
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .reversal-redirect {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 0.75rem;

    img {
      margin-right: 0.25rem;
    }
  }

  .receipt-image {
    max-width: 7.5rem;
    max-height: 7.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
  }
  &__edit-btn {
    margin: auto;
    padding: 2.5rem 0;
    .button {
      margin-bottom: 0;
      width: 11.5rem;
    }
  }
  #expense-edit .ant-select {
    background: $white;
    color: $black-90;
  }

  &__confirmation-message {
    color: $black-90;
    font-size: 1.25rem;
    margin-top: 1.875rem;
    line-height: 1.5;
    flex: 1;
  }

  &__group-button {
    display: flex;
    justify-content: space-between;

    .bg-green {
      .button {
        background-color: $green;
      }
    }

    .bg-white {
      .button {
        background-color: $white;
        color: $black-70;
      }
    }
  }
}

.expense-details-edit,
.expense-details-edit {
  .ant-form-item {
    &:last-child {
      margin-top: auto;
      .button {
        width: 100%;
        margin: 1.25rem 0 2rem 0;
        background-color: $green-80;
      }
    }
  }
  &__default-tax {
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      color: $black-50;
    }
  }
  &__notes {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-label > label {
    margin-top: 2rem;
  }
  .drop-down .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.25rem;
    padding: 0.625rem;
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    max-height: 5.625rem;
    .upload-image__camera-icon {
      margin-left: 0.25rem;
    }
  }
  & &--select-category {
    .ant-select-selector {
      padding: 0.625rem;
    }
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    color: $white;
  }
  .upload-image {
    .ant-upload.ant-upload-select-picture-card {
      position: relative;
    }
    .ant-upload-picture-card-wrapper {
      padding-top: 1.25rem;
    }
  }
}
