@import "src/assets/scss/variables";

.container {
  background-color: $white;
  height: 100vh;
  max-width: calc(100vw - 72px);
  overflow-x: scroll;
  margin-left: -1.5rem;
  table {
    padding-right: 48px;
    thead > tr > th {
      background-color: $zircon !important;
      color: $black !important;
      font-size: 14px;
      vertical-align: middle;
    }
    thead > tr:first-child > th:first-child,
    tbody > tr > td:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    thead > tr:first-child > th:last-child,
    tbody > tr > td:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
  .hidden {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    .loading {
      position: fixed;
      top: 50%;
      right: 45%;
      transform: translate(-50%, -50%);
    }
  }
}
