@import "../../assets/scss/styles.scss";

.toaster {
  margin: 20px 0;
  padding-left: 20px;
  border-radius: 8px;
  color: #131a2e;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  animation: 0.4s slidein;
  position: fixed;
  bottom: 0;
  left: 100px;
  z-index: 1000;
  width: calc(100% - 150px);
  .icon {
    width: fit-content;
    padding-top: 5px;
    cursor: default;
  }
  .message {
    padding: 10px;
    width: 100%;
  }
  &--view-details {
    margin-left: 8px;
    color: #131a2e;
    border-bottom: 1px solid #131a2e;
    cursor: pointer;
  }
  &--close-icon.icon {
    padding: 5px 8px 0 0;
    cursor: pointer;
  }
  &--success {
    background: #1fce8f;
    transition: width 50s;
  }
  &--error {
    background: #eb5753;
  }
  &--primary {
    width: calc(100% - 580px);
  }
}
