@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spaces.scss";

.leftCol,
.rightCol {
  height: 3rem;
}

.leftCol {
  display: flex;
  align-items: left;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  .category {
    width: 0.25rem;
    height: 2rem;
    border-radius: 1.8125rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .otherCategory {
    width: 1px;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: $space-12;

    span {
      &:first-child {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.33;
        color: $black-90;
      }

      &:not(:first-child) {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.33;
        color: $black-80;
      }
    }
  }
}

.rightCol {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.33;
    color: $black-80;
  }
}
