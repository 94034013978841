@import "src/assets/v1.1/scss/spectrum";

.stepCard {
  border-radius: 1rem;
  background-color: $white;
  padding: 2.5rem 1.5rem;
  max-width: 22.5rem;
  min-height: 28.125rem;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0px;
  }

  .section {
    flex-direction: column;
    height: 100%;
    img {
      border-radius: 1rem;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  .title {
    color: $brand;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  .content {
    font-size: 1rem;
    font-weight: 400;
    color: $black-100;
    line-height: 1.5rem;
    b {
      font-weight: 700;
    }
    .link {
      text-decoration: underline;
    }
  }
}

.doneCta {
  max-width: 13.375rem;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    margin-left: 0.75rem;
  }
}
