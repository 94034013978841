@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/Splice/scss/spaces.scss";

.merchantsName {
  display: flex;
  gap: $space-12;
  align-items: center;
}

.avatar {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  background: $brand-20;

  line-height: 2.25rem;
  text-align: center;
  font-weight: 600;
  color: $brand;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 8px;
}
