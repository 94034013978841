.bank-details {
  display: contents;
  .table-row-field {
    p {
      color: #d5d4d4;
    }
  }
  &__header {
    text-align: center;
    color: #131a2e;
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: #d5d4d4;
      line-height: 1.875em;
    }
    p {
      font-size: 16px;
      line-height: 1.5em;
    }
  }
  &__action-btns {
    margin-top: auto;
    padding-bottom: 40px;
    .button {
      width: 100%;
    }
  }
}
