@import "src/assets/v1.1/scss/spectrum.scss";

.section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  width: 100%;
  position: relative;
}

.searchBar {
  padding: 0.75rem;
  border-bottom: 0.0625rem solid $black-40;
}

@mixin contentStyle {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.marginBottomXL {
  margin-bottom: 2rem;
}
