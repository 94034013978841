@import "src/assets/scss/variables";

.flow-condition {
  width: 400px;
  .flow-condition__select {
    width: 100%;
    .flow-condition__select__button {
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      width: 100%;
      color: $orange;
      background: $peach;
      text-align: center;
      padding: 8px;
      border: 0px;
      border-radius: 8px;
      height: auto;
    }
  }
}

ul.flow-condition__select__menu {
  margin-top: -40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 1px 9px 1px $box-shadow;
  max-width: 400px;
  li.ant-dropdown-menu-item,
  li.ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
    display: grid;
    position: relative;
    grid-template:
      "ico title"
      "ico desc" auto / 48px auto 10px;
    padding: 16px;
    padding-right: 32px;
    white-space: normal;
    cursor: pointer;
    &:hover {
      background: $peach;
    }
    .flow-condition__menu__icon {
      grid-area: ico;
      margin-right: 16px;
    }
    .flow-condition__menu__title {
      grid-area: title;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: $black;
      text-align: left;
    }
    .flow-condition__menu__desc {
      grid-area: desc;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: $gray;
      text-align: left;
    }
    .flow-condition__menu__arrow {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: rotate(-90deg) translateX(50%);
      filter: brightness(1.5);
    }
    .ant-dropdown-menu-submenu-arrow {
      display: none;
    }
  }
}

.flow-condition__select__sub-menu {
  ul {
    margin-left: -12px;
    border-radius: 8px;
    li.ant-dropdown-menu-item {
      padding: 8px;
    }
  }
}
