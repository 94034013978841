/* Brand: Dark Orange */
$dark-orange-0: #fffdfc;
$dark-orange-005: #ffebe4;
$dark-orange-010: #fedacd;
$dark-orange-015: #fec8b5;
$dark-orange-020: #feb79d;
$dark-orange-030: #fd936e;
$dark-orange-040: #fd703e;
$dark-orange-050: #fc4d0f;
$dark-orange-060: #cf3f0c;
$dark-orange-070: #a23109;
$dark-orange-080: #752306;
$dark-orange-085: #5f1c05;
$dark-orange-090: #481503;
$dark-orange-095: #320e02;
$dark-orange-100: #1b0700;

/* Brand: Bright orange */
$bright-orange-0: #fffcfb;
$bright-orange-005: #fff0e8;
$bright-orange-010: #ffe3d4;
$bright-orange-015: #ffd7c1;
$bright-orange-020: #ffcaad;
$bright-orange-030: #ffb187;
$bright-orange-040: #ff9860;
$bright-orange-050: #ff7f39;
$bright-orange-060: #e46e2e;
$bright-orange-070: #c85d22;
$bright-orange-080: #ad4c17;
$bright-orange-085: #9f4411;
$bright-orange-090: #913b0b;
$bright-orange-095: #843306;
$bright-orange-100: #762a00;
$bright-orange-110: #5e2200;
$bright-orange-120: #471900;
$bright-orange-130: #2f1100;
$bright-orange-135: #230d00;

/* Brand: Light Orange */
$light-orange-0: #fffbf6;
$light-orange-005: #fff4e6;
$light-orange-010: #ffedd5;
$light-orange-015: #ffe6c5;
$light-orange-020: #ffdfb5;
$light-orange-030: #ffd294;
$light-orange-040: #ffc474;
$light-orange-050: #ffb653;
$light-orange-060: #efa642;
$light-orange-070: #df9632;
$light-orange-080: #cf8521;
$light-orange-085: #c77d19;
$light-orange-090: #bf7511;
$light-orange-095: #b76d08;
$light-orange-100: #af6500;

/* Brand: Dark Navy */
$dark-navy-0: #f8fbfe;
$dark-navy-005: #f2f6fb;
$dark-navy-007: #e8ecf2;
$dark-navy-010: #c8d0d8;
$dark-navy-015: #b1bac5;
$dark-navy-020: #99a4b2;
$dark-navy-030: #69798c;
$dark-navy-040: #3a4d66;
$dark-navy-050: #0a2240;
$dark-navy-060: #081b33;
$dark-navy-070: #061426;
$dark-navy-080: #040e1a;
$dark-navy-085: #030a13;
$dark-navy-090: #02070d;
$dark-navy-095: #010306;

/* Neutral  */
/* black */
$black-0: #858585;
$black-005: #7c7c7c;
$black-010: #747474;
$black-015: #6c6c6c;
$black-020: #646464;
$black-030: #545454;
$black-040: #434343;
$black-050: #333333;
$black-060: #292929;
$black-070: #1f1f1f;
$black-080: #141414;
$black-085: #0f0f0f;
$black-090: #0a0a0a;
$black-095: #050505;
$black-100: #000000;

/* white */
$white-0: #ffffff;
$white-005: #fdfdfd;
$white-010: #fcfcfc;
$white-015: #fafafa;
$white-020: #f8f8f8;
$white-030: #f5f5f5;
$white-040: #f1f1f1;
$white-050: #eeeeee;
$white-060: #dedede;
$white-070: #cdcdcd;
$white-080: #bdbdbd;
$white-085: #b5b5b5;
$white-090: #adadad;
$white-095: #a5a5a5;
$white-100: #9c9c9c;

/* Navy */
$navy-0: #f7fafc;
$navy-005: #e5edf6;
$navy-010: #d2e0f0;
$navy-015: #c0d3ea;
$navy-020: #aec6e4;
$navy-030: #89add7;
$navy-040: #6593cb;
$navy-050: #4079bf;
$navy-060: #34629a;
$navy-070: #284b76;
$navy-080: #1b3351;
$navy-085: #15283f;
$navy-090: #0f1c2d;
$navy-095: #09111a;
$navy-100: #030508;

/* Blue */
$blue-0: #edf6ff;
$blue-005: #d7ecff;
$blue-010: #c1e1fe;
$blue-015: #aad7fe;
$blue-020: #94ccfe;
$blue-030: #68b7fd;
$blue-040: #3ba2fd;
$blue-050: #0f8dfc;
$blue-060: #0c75d2;
$blue-070: #095ea8;
$blue-080: #07467e;
$blue-085: #053a69;
$blue-090: #042f54;
$blue-095: #02233f;
$blue-100: #01172a;

/* Green */
$green-0: #f3fcf7;
$green-005: #e4f8ed;
$green-010: #d5f3e3;
$green-015: #c6efd8;
$green-020: #b7eace;
$green-030: #99e2ba;
$green-040: #7bd9a5;
$green-050: #5dd091;
$green-060: #4fb77e;
$green-070: #419e6b;
$green-080: #348559;
$green-085: #2d794f;
$green-090: #266c46;
$green-095: #1f603c;
$green-100: #185333;

/* Yellow */
$yellow-0: #ffffff;
$yellow-005: #fffbee;
$yellow-010: #fff8dd;
$yellow-015: #fff4cd;
$yellow-020: #fff0bc;
$yellow-030: #ffe99a;
$yellow-040: #ffe179;
$yellow-050: #ffda57;
$yellow-060: #cfb146;
$yellow-070: #9f8834;
$yellow-080: #705e23;
$yellow-085: #584a1a;
$yellow-090: #403511;
$yellow-095: #282109;
$yellow-100: #100c00;

/* Red */
$red-0: #fef6f6;
$red-005: #fbe0e0;
$red-010: #f9caca;
$red-015: #f6b4b4;
$red-020: #f49e9e;
$red-030: #ee7373;
$red-040: #e94747;
$red-050: #e41b1b;
$red-060: #b81616;
$red-070: #8c1111;
$red-080: #610b0b;
$red-085: #4b0909;
$red-090: #350606;
$red-095: #1f0404;
$red-100: #090101;

/* Purple */
$purple-0: #fcfbfe;
$purple-005: #ede8f8;
$purple-010: #ded6f1;
$purple-015: #cfc3eb;
$purple-020: #c0b0e5;
$purple-030: #a28bd8;
$purple-040: #8465cc;
$purple-050: #6640bf;
$purple-060: #52339a;
$purple-070: #3e2774;
$purple-080: #2a1a4f;
$purple-085: #20143c;
$purple-090: #160e29;
$purple-095: #0c0717;
$purple-100: #020104;
