.wrapper {
  position: relative;

  .actions {
    position: absolute;
    right: 0;
    top: -1.5rem;
    display: flex;
    gap: 0.25rem;
    color: var(--text-link-default);
    cursor: pointer;
  }
}

.preview {
  margin-top: var(--spacing-008);
}

.editBtn {
  display: grid;
  grid-template-columns: 1rem 1fr;
  column-gap: var(--spacing-004);
  cursor: pointer;
  color: var(--text-link-default);

  > span {
    padding: var(--spacing-002);
    height: var(--size-010);
  }
}

.infoPanel {
  margin-top: var(--spacing-008);

  h4 {
    color: var(--text-body-warning);
  }
}

.btnGroup {
  display: flex;
  margin-top: var(--spacing-008);
  column-gap: var(--spacing-024);

  button {
    width: 100%;
  }
}

.highlight {
  color: var(--text-body-brand);
}
