@import "src/assets/v1.1/scss/spectrum";

.checkbox {
  margin-top: 2rem;
  display: flex;

  .title {
    font-weight: 600;
    font-size: 0.875rem;
    color: $black-90;
  }

  .desc {
    font-size: 0.875rem;
  }

  :global {
    .ant-checkbox {
      margin-top: 0.375rem;
    }
  }
}
