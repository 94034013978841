@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.create-flow__container {
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .create-flow__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 32px 72px;
    box-shadow: 0px 1px 9px 1px $box-shadow;
    z-index: 2;
    gap: 1.5rem;
    .create-flow__close {
      margin-right: 20px;
      button {
        border: none;
        box-shadow: none;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .create-flow__title {
      flex-grow: 5;
      h1 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: $black;
        margin-bottom: 8px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $gray;
      }
      .create-flow__title__input {
        max-width: 600px;
        background: $zircon;
        color: $black;
        border: none;
        padding: 8px 12px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-family: "Spenmo", sans-serif;
      }
      &-label {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 600;
        color: $black-100;
        letter-spacing: -0.022rem;
      }
    }
    .create-flow__action {
      .create-flow__action__button {
        padding: 12px 40px;
        border-radius: 8px;
        background-color: $peach;
        border: none;
        color: $orange;
        font-size: 14px;
        height: auto;
        &.primary {
          background-color: $orange;
          color: $white;
        }
        &:disabled {
          background: $light-gray;
          color: $white;
        }
      }
    }
  }
  .create-flow__content {
    position: relative;
    background: $light-grey-2;
    padding: 56px 100px;
    width: calc(100vw - 13.75rem);
    height: calc(100vh - 112px);
    overflow-x: scroll;
    cursor: grab;
    &.scrolling {
      cursor: grabbing;
    }
    z-index: 1;
  }
}

.warn,
.warn::before,
.warn::after {
  position: relative;
  padding: 0;
  margin: 0;
}

.warn {
  font-size: 18px;
  color: transparent;
}

.warn.warning {
  display: inline-block;

  top: 4px;

  width: 1.15em;
  height: 1.15em;

  overflow: hidden;
  border: none;
  background-color: transparent;
  border-radius: 0.6em;
}

.warn.warning::before {
  content: "";
  display: block;
  top: -0.1em;
  left: 0em;
  position: absolute;
  border: transparent 0.6em solid;
  border-bottom-color: #dd1c0b;
  border-bottom-width: 1em;
  border-top-width: 0;
}

.warn.warning::after {
  display: block;
  position: absolute;
  top: -0.1em;
  left: 0;
  width: 100%;
  padding: 0 1px;
  text-align: center;
  content: "!";
  font-size: 0.65em;
  font-weight: bold;
  color: #ffffff;
}
