/* Font Families */
$font-family: "Spenmo";

/* https://css-tricks.com/snippets/css/system-font-stack/ */
$font-family-stack: $font-family, sans-serif, -apple-system, blinkmacsystemfont, "Segoe UI", roboto, helvetica, arial,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Font Filenames */
$font-name-light: "PublicSans-Light";
$font-name-light-italic: "PublicSans-LightItalic";
$font-name-regular: "PublicSans-Regular";
$font-name-italic: "PublicSans-Italic";
$font-name-medium: "PublicSans-Medium";
$font-name-medium-italic: "PublicSans-MediumItalic";
$font-name-semi-bold: "PublicSans-SemiBold";
$font-name-semi-bold-italic: "PublicSans-SemiBoldItalic";
$font-name-bold: "PublicSans-Bold";
$font-name-bold-italic: "PublicSans-BoldItalic";
$font-name-extra-bold: "PublicSans-ExtraBold";
$font-name-extra-bold-italic: "PublicSans-ExtraBoldItalic";

/* Font Weights */
$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf woff woff2) {
  $src: null;
  $formats: (
    ttf: "truetype",
    woff: "woff",
    "woff2": "woff2",
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote("./" + $path + "." + $ext)) format(quote($format)), comma);
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
@include font-face($font-family, fonts/unquote($font-name-light), $font-weight-light, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-light-italic), $font-weight-light, italic, ttf);
@include font-face($font-family, fonts/unquote($font-name-regular), $font-weight-regular, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-italic), $font-weight-regular, italic, ttf);
@include font-face($font-family, fonts/unquote($font-name-medium), $font-weight-medium, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-medium-italic), $font-weight-medium, italic, ttf);
@include font-face($font-family, fonts/unquote($font-name-semi-bold), $font-weight-semi-bold, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-semi-bold-italic), $font-weight-semi-bold, italic, ttf);
@include font-face($font-family, fonts/unquote($font-name-bold), $font-weight-bold, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-bold-italic), $font-weight-bold, italic, ttf);
@include font-face($font-family, fonts/unquote($font-name-extra-bold), $font-weight-extra-bold, normal, ttf);
@include font-face($font-family, fonts/unquote($font-name-extra-bold-italic), $font-weight-extra-bold, italic, ttf);
