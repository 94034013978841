@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 28.75rem;
  padding: 1rem 2.5rem;
  background-color: $black-10;
  .text {
    color: $black-90;
  }
  .button {
    user-select: none;
    cursor: pointer;
  }
}
