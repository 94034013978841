@import "src/assets/v1.1/scss/spectrum";
@import "src/Splice/scss/backgroundColors";

.container {
  background: $white;
  padding: var(--spacing-024);
  position: fixed;
  width: calc(100vw - 13.75rem);
  height: 100vh;
  overflow: auto;
  top: 0;
  left: 13.75rem;

  .title {
    margin-bottom: 1.125rem;
  }

  .back {
    margin-bottom: 1.25rem;
    color: $blue-80;
    cursor: pointer;
  }

  .desc {
    color: $black-100;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .draftTable {
    padding: 0 0 1rem !important;

    .columnText {
      display: inline;
      line-height: 1.25rem;
    }

    .columnIcon {
      cursor: pointer;
      height: 1.25rem;
      line-height: 1.25rem;
      vertical-align: middle;
      margin-left: 0.25rem;
    }
  }

  .submit {
    position: fixed;
    bottom: 4.5rem;
    right: 4.5rem;
  }

  .errorDesc {
    color: $red-80;
    margin-top: 1.5rem;

    .errorCount {
      font-size: 0.75rem;
    }

    .errorBtn {
      padding: 0.5rem 1rem;
      background: none;
      border: 1px solid $red-80;
      border-radius: 0.5rem;
      margin-left: 1rem;
      &:hover {
        background: $red-40;
      }
    }
  }

  .rowError {
    background: $interactive-danger !important;

    &:hover > * {
      background: $interactive-danger-hover !important;
    }
    &:active > * {
      background: $interactive-danger-pressed !important;
    }
  }

  .cellError {
    color: $red-80;
    text-decoration: underline;
    cursor: pointer;
  }
}

.errorCount {
  text-align: center;
  padding: 0.25rem 0.5rem;
  color: $white;
  background: $red-80;
  border-radius: 0.5rem;
}

.errorList {
  li + li {
    margin-top: 0.5rem;
  }
}

.edit {
  padding: 0.375rem 0.5rem;
  border-radius: 2.5rem;
  width: 100%;
  color: $blue-80;
  background: $blue-40;
  .editLabel {
    margin-right: 0.25rem;
  }
}

.cellDesc {
  font-size: 0.75rem;
  line-height: 1rem;
  color: $black-70;
}
.highlight {
  color: $black-100;
  font-weight: 700;
}
