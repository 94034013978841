@import "src/assets/v1.1/scss/spectrum";

.columnTwo {
  column-count: 2;
}

.previewModal {
  padding: 1.5rem 1rem !important;
  width: calc(100% - 8rem);
  height: calc(100vh - 8rem);

  &.previewModalSimple {
    width: initial;
    height: initial;

    .content {
      border-bottom: 0;
    }
  }

  .banner {
    margin-bottom: 2rem;

    &Title,
    p {
      color: $blue;
      font-size: 0.875rem;
      line-height: 1.6;
    }

    &Title {
      font-weight: 600;
    }
  }

  .preview {
    border-radius: 1.5rem;
    background-color: $black-20;
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    & > div + div {
      margin-top: 1.5rem;
    }

    .previewItem {
      break-inside: avoid;
      margin-top: 1.5rem;
      line-height: 1.5rem;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      .itemLabel {
        color: $black-60;
      }

      .itemValue {
        color: $black-80;
        font-size: 1rem;
      }

      .itemHighlight {
        color: $green-80;
        font-size: 1rem;
      }

      .itemPrevValue {
        color: $black-50;
        font-size: 1rem;
        margin-right: 0.25rem;
        text-decoration-line: line-through;
      }
    }
  }

  .previewLabel {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;

    & > div {
      display: flex;
      align-items: center;
    }

    .previewColor {
      width: 1rem;
      height: 1rem;
      border-radius: 0.25rem;
      display: inline-block;
      margin-right: 0.5rem;
      background-color: $black-100;
    }
    .previewHighlight {
      width: 1rem;
      height: 1rem;
      border-radius: 0.25rem;
      display: inline-block;
      margin-right: 0.5rem;
      background-color: $green-80;
    }
  }

  .content {
    display: flex;
    width: calc(100% + 2rem);
    height: calc(100% - 6.5rem);
    border-bottom: 1px solid $black-20;
    margin: 0 -1rem;
    overflow-y: auto;

    .contentBody {
      width: 100%;
      padding: 1.5rem 2rem;
      height: 100%;
      overflow-y: auto;
    }

    .contentSidebar {
      width: 440px;
      border-left: 1px solid $black-20;
      padding: 3rem 2rem 2rem;
      height: 100%;
      overflow-y: auto;
      flex-shrink: 0;
      color: $black-80;

      .confirm {
        padding-bottom: 2rem;

        .confirmContainer {
          display: flex;
          gap: 0.625rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        .confirmCheckbox {
          width: 1.25rem;
          height: 1.25rem;
        }
        .confirmBold {
          font-weight: 600;
          color: $black-100;
        }

        .confirmDesc {
          color: $black-70;
        }
      }
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }

    p {
      line-height: 1.375rem;
    }
  }

  .footer {
    display: flex;
    padding: 1rem 1rem 0;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .footerCloselink {
      cursor: pointer;
      color: $blue-80;
    }
  }

  :global {
    .ant-steps {
      margin-top: 2rem;

      &-item {
        &-content {
          width: calc(100% - 2rem);
        }

        &-title {
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: 600;
          color: $brand !important;
        }

        &-description {
          color: $black-60 !important;

          b {
            color: $black-80;
            font-weight: 600;
          }
        }

        &-tail {
          top: 0.75rem !important;
          padding: 0 !important;

          &:after {
            background-color: $black-50 !important;
          }
        }

        &-finish {
          .ant-steps-item-tail:after {
            background-color: $brand !important;
          }
        }

        &-wait {
          .ant-steps-item-title {
            color: $black-50 !important;
          }
          .ant-steps-icon-dot {
            background: $black-50 !important;
          }
        }
      }

      &-icon {
        color: $brand;

        &-dot {
          background: $brand !important;
          transform: scale(2);
        }
      }
    }
  }

  .invoices {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;

    .invoicesImage {
      width: 10rem;
      height: 10rem;
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0.5rem;
      margin-top: 0.25rem;
    }
  }

  .sla {
    background-color: $black-30;
    padding: 1.375rem 1rem;
    margin-top: 1.5rem;
    border-radius: 0.25rem;

    & > div {
      margin-top: 0;
    }
  }

  .fxRateBanner {
    margin-bottom: 2rem;
    :global(.styles_design-system-banner__message__3__-l) {
      width: 100%;
    }
    .fxRateContainer {
      width: 100%;
      .preview {
        background: $yellow-20;
        column-count: 2;
        column-fill: auto;
        padding: 1rem 1rem 0.5rem;
        margin-top: 0.5rem;
        border-radius: 0.5rem;
      }
    }
  }
}

.wrapText {
  overflow-wrap: break-word;
}
