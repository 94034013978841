.container {
  padding-bottom: 0;
  max-width: var(--size-240);

  display: flex;
  flex-direction: column;
  align-items: center;

  .information {
    color: var(--text-body-default);
    text-align: center;

    .user {
      color: var(--text-body-brand);
    }
  }

  .divider {
    margin: var(--spacing-032) 0;
    height: 1px;
    width: 100%;
    background: var(--border-neutral-subtle);
  }

  .fullWidth {
    margin-bottom: var(--spacing-020);
    width: 130%;
  }

  .termAndCondition {
    width: 100%;

    label {
      align-items: flex-start;
      width: fit-content;

      span {
        width: fit-content;
      }
    }

    .checkboxLabel {
      color: var(--text-caption-default);
    }

    .link {
      color: var(--text-link-default);
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: var(--spacing-012);
  }
}
