.letter-avatar {
  min-width: 40px;
  min-height: 40px;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Spenmo", sans-serif;
  &--rounded {
    background: #fff5ed;
    color: #f65731;
    border-radius: 50%;
  }
  &--square {
    background: #c5ecd5;
    color: #5aaf60;
    border-radius: 8px;
  }
  &--big-square {
    width: 120px;
    height: 120px;
    background: #c5c7cd;
    border-radius: 4px;
    font-size: 36px;
    color: #fff;
  }
}
