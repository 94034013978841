.reset-password {
  padding: 48px 68px;
  max-width: 550px;
  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 2.125em;
    color: #000000;
    padding-bottom: 12px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    margin: 0;
    padding-left: 12px;
  }
  .ant-input-affix-wrapper {
    border: none;
    border-radius: 6px;
    padding: 0 12px 0 0;
    min-height: 52px;
  }
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
    color: #131a2e;
  }
  .ant-form-item {
    padding-left: 40px;
  }
  &__info {
    font-size: 12px;
    margin: 10px 0;
    color: #c14122;
    line-height: 1.2em;
    max-width: 350px;
    padding-left: 40px;
  }
  .button {
    min-width: 180px;
    margin-top: 20px;
  }
  &--error {
    color: #ff4d4f;
    padding-left: 40px;
  }
}
