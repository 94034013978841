@import "src/assets/scss/variables";

.error-box {
  position: relative;
  padding: 8px 12px;
  display: flex;
  background: $inactive-bg;
  color: $red;
  align-items: flex-start;
  border-radius: 8px;
  gap: 12px;
  margin-bottom: 16px;
  .error-box__icon {
    filter: invert(37%) sepia(55%) saturate(7160%) hue-rotate(335deg) brightness(93%) contrast(92%);
  }
  .error-box__message {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    .error-box__action {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
