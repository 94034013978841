@import "../Bills.module.scss";

.sidepanel {
  @extend .sidepanel;
  background-color: var(--white-0);

  input {
    border-radius: var(--size-002);
    border-color: var(--white-060);
  }

  :global {
    .ant-form-item-explain,
    .ant-form-item-extra {
      margin-top: --spacing-008;
    }
  }
}

.recipientLog {
  margin-bottom: var(--spacing-024);
  padding: var(--spacing-024);
  background-color: var(--white-015);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--spacing-024);
  border-radius: var(--radius-m);

  .title {
    color: var(--white-100);
    margin-bottom: var(--spacing-008);
  }

  .info {
    display: grid;
    grid-template-columns: var(--size-016) 1fr;
    column-gap: var(--spacing-008);
    align-items: center;
  }

  .nameInitial {
    color: var(--text-body-brand);
    padding: var(--spacing-004) var(--spacing-008);
    border-radius: 50%;
    background-color: var(--fill-brand-orange);
    height: var(--size-016);
    text-align: center;
  }

  .name {
    color: var(--text-title-default);
  }

  .date {
    color: var(--text-caption-default);
  }
}

.preview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-032);

  &Section {
    border: 1px solid var(--white-050);
    border-radius: var(--radius-s);

    // Section title
    &:before {
      content: attr(data-title);
      padding: var(--spacing-024);
      display: block;
      background-color: var(--white-050);
      color: var(--black-100);
      font-weight: 600;
      color: var(--black-100);
    }
  }

  &Content {
    padding: var(--spacing-024);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-024);
  }

  &Item {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-016);
    line-height: var(--size-012);

    & > div:first-child {
      font-weight: 600;
      color: var(--black-100);
      width: 14.125rem;
      flex-shrink: 0;
    }

    & > div:last-child {
      text-align: right;
    }
  }
}

.hidden {
  display: none;
}

.discardModal {
  text-align: center;
  line-height: var(--size-012);
  font-size: var(--size-008);

  .image {
    margin: var(--spacing-016) auto var(--spacing-024) auto;
    width: 100%;
    height: 7.5rem;
  }
}
.recipientSidePanel {
  width: 37rem;
  margin-left: auto;
}
