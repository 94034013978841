.uploader {
  padding: 24px 0;
  width: 552px;
}

.toaster-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: fit-content;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
  & > * {
    position: relative;
    margin: 0.25rem 0;
  }
}
