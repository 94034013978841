@import "src/assets/scss/mixins";
@import "src/assets/v1.1/scss/spectrum";
@import "src/Splice/scss/spectrum";

.notFound {
  text-align: center;
  padding: 1rem;

  img {
    margin-bottom: 0.5rem;
  }

  p {
    color: #666b79;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
