@import "src/assets/v1.1/scss/spectrum.scss";

.sync-icons {
  display: flex;
  align-items: center;
  z-index: 2000;
  .sync-title,
  .icon__title {
    width: auto;
    padding: 0 0 0 7px;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.24px;
    color: #333744;
    cursor: default;
  }
}
.sync-failed-icon {
  z-index: 1079;
}

.sync-failed-tooltip.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $black-90;
  }
}
