.withTooltip {
  display: flex;
  align-items: center;

  // icon button container
  > button {
    padding: 0 0 0 var(--spacing-004);
  }

  // typography
  > p {
    white-space: pre-line;
  }
}

.amountColumn {
  justify-content: flex-end;
}

.icon {
  display: flex;
}

.paymentAmount {
  color: var(--text-label-default);
}

.invoiceAmount {
  color: var(--text-link-subtle);
  text-decoration: underline;
}

.invoiceTooltip > button {
  text-align: right;
  padding: 0;
}
