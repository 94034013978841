.number-icon {
  padding: 11px 27px;
  max-height: 42px;
  width: fit-content;
  font-weight: 600;
  line-height: 20px;
  color: #f65731;
  background: #fff5ed;
  border-radius: 32px;
  &--primary {
    padding: 11px 15px;
    margin: 0 2px;
  }
}
