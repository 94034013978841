@import "src/assets/scss/variables";

.save-vendor-modal {
  .ant-modal-content {
    width: 500px;
    border-radius: 16px;
    padding: 0px;
    margin-left: -75px;
    .ant-modal-close {
      margin-top: 14px;
      margin-right: 14px;
      .ant-modal-close-icon {
        height: 14px;
        width: 14px;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      text-align: center;
      .save-vendor-modal__title {
        font-size: 20px;
        line-height: 32px;
        font-weight: 700;
        border-bottom: 1px solid $light-gray-2;
        width: 100%;
        padding: 20px 24px;
      }
      .save-vendor-modal__content {
        padding: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        .icon {
          width: 100px;
        }
        .desc {
          margin-bottom: 24px;
        }
        .save-vendor-modal__footer {
          display: flex;
          gap: 24px;
          .ant-btn {
            font-weight: 600;
            line-height: 20px;
            font-size: 16px;
          }
          .action {
            border-radius: 30px;
            background: $orange;
            color: $white;
            padding: 16px 24px;
            height: auto;
            width: 100%;
            margin-top: 24px;
            border: 1px solid $orange;
          }
          .cancel {
            border-radius: 30px;
            background: $white;
            color: $orange;
            padding: 16px 24px;
            height: auto;
            width: 100%;
            margin-top: 24px;
            border: 1px solid $orange;
          }
        }
      }
    }
  }
  .icon {
    cursor: default;
    margin-bottom: 16px;
  }
}
