@import "src/Splice/scss/spectrum";
@import "src/assets/v1.1/scss/spectrum.scss";

.policy-content {
  &__relative-container {
    position: relative;
  }

  &__para {
    margin-top: 0.64rem;
    line-height: 1.25rem;
    color: $black-015;
    margin-bottom: 1rem;
  }

  &__add-btn {
    text-align: center;
    button {
      background: transparent;
      color: $dark-orange-040;
    }

    button:hover {
      background-color: transparent;
    }
  }

  &__admin-container {
    padding: 0.69rem 0;
  }

  &__default-admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__admin-option {
    font-size: 1rem;
    font-weight: 700;
    color: $black-100;

    p {
      font-weight: 400;
      font-size: 14px;
      color: $black-80;
    }
  }

  &__option-label {
    color: $black-030;
    font-size: 0.75rem;
    background: $white-030;
    border-radius: 12px;
    padding: 2px 8px;
  }
}
.self-approval {
  &__checkbox-right {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &__tab-container {
    background-color: $white-0;
    padding: 0 1rem 2rem 1rem;
  }
  &__selected-option {
    padding: 0.69rem 0 !important;
  }
  &__add-more-btn-container {
    position: absolute;
    top: 0.25rem;
    right: 0;
  }

  &__add-more-btn,
  &__reset-btn {
    padding: 0;
    border: 0;
    color: $dark-orange-040;
    font-weight: 600;
    font-size: 0.75rem;
    cursor: pointer;
  }

  &__reset-btn {
    color: $black-015;
    margin-left: 0.25rem;
  }

  &__discard-save-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 29.5rem;
    background: $white-0;
    bottom: 0;
    right: 0;
    padding: 1rem 2rem;

    button {
      width: 48%;
    }
  }

  &__discard-confirm-modal {
    width: 360px;
    height: 460px;
    text-align: center;
    h3 {
      font-weight: 600;
    }
  }

  &__discard-modal {
    background: $white-020;
    color: $brand;
  }

  &__discard-modal:hover {
    background: $white-020;
  }

  &__discard-confirm-modal-text {
    padding: 0 1rem 3rem 1rem;
    h3 {
      margin: 0.5rem 0;
    }
    p {
      line-height: 1.25rem;
    }
  }

  &__discard-save-modal-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white-0;
    padding: 1.25rem 0;
    border-top: 1px solid $white-050;
    button {
      width: 48%;
    }
  }

  &__discard {
    background: $brand-20;
    color: $brand;
  }

  &__discard:hover {
    background-color: $brand-20;
  }
  &__save {
    background: $bright-orange-050;
  }

  &__save:hover {
    background-color: $bright-orange-050;
  }

  &__illustration {
    margin: auto;
    padding: 50px 0 0 0;
  }
}
