@import "src/assets/scss/variables";

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $gray;
  }
  .ant-tooltip-arrow:before {
    background-color: $gray;
  }
}

.upload-complete__header-tooltip {
  display: flex;
  gap: 4px;
}
