@import "src/assets/v1.1/scss/spectrum";

.addCategory {
  padding: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;

  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black-90;
    text-align: center;
  }

  & > button {
    border-radius: 53px;
    background: $brand;
    color: $white;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 1rem;
    min-width: 0;
    min-height: 0;
  }
}
