@import "src/assets/scss/variables";
@import "src/assets/v1.1/scss/spectrum.scss";

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 5rem;

  .pageControl {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    border-bottom: 1px solid $black-20;

    .pageInput {
      width: 2.875rem;
      font-size: 0.875rem;
      height: 2.25rem;
      padding: 0.625rem;
      border-radius: 4px;
      margin: 0 0.75rem;
    }

    .pageButton {
      border-radius: 4px 0 0 4px;
      width: 2.25rem;
      height: 2.25rem;
      margin-left: 0.75rem;
      min-width: 2.25rem;
      min-height: 2.25rem;
      background: none;
      color: $black-90;
      border: 1px solid $black-40;

      & + .pageButton {
        border-left: none;
        border-radius: 0 4px 4px 0;
        margin-left: 0;
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 1.5rem 2.25rem 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 0.625rem 0.25rem rgba($black-90, 0.08);

    .actions {
      display: flex;
      justify-content: flex-start;
      gap: 1.5rem;
      .deleteButton {
        color: $red-80;
      }
      .saveButton {
        color: $blue-80;
      }
    }

    .submit {
      border-radius: 6.25rem;
      padding: 0.5rem 2rem;
    }
  }
}

.container {
  height: 100vh;
  &.edit {
    height: 100%;
  }
  &.multiPage {
    height: calc(100% - 70px);
  }
  .col {
    padding: 36px;
  }
  .left {
    background-color: $zircon;
    text-align: center;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .aryadana {
      width: 100%;
      padding: 1.875rem;
      margin-top: 1.875rem;
    }
  }
  .right {
    background-color: $white;
    height: 100%;
    overflow: auto;
    .title {
      margin: 50px 0;
    }
  }
}
