/* Font Families */
/* Font Montserrat Variables */
$font-family-montserrat: "Montserrat";

/* https://css-tricks.com/snippets/css/system-font-stack/ */
$family-stack-montserrat: $font-family-montserrat, sans-serif, -apple-system, blinkmacsystemfont, "Segoe UI", roboto,
  helvetica, arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Font Filenames */
$montserrat-light: "Montserrat-Light";
$montserrat-light-italic: "Montserrat-LightItalic";
$montserrat-regular: "Montserrat-Regular";
$montserrat-italic: "Montserrat-Italic";
$montserrat-medium: "Montserrat-Medium";
$montserrat-medium-italic: "Montserrat-MediumItalic";
$montserrat-semi-bold: "Montserrat-SemiBold";
$montserrat-semi-bold-italic: "Montserrat-SemiBoldItalic";
$montserrat-bold: "Montserrat-Bold";
$montserrat-bold-italic: "Montserrat-BoldItalic";
$montserrat-extra-bold: "Montserrat-ExtraBold";
$montserrat-extra-bold-italic: "Montserrat-ExtraBoldItalic";

/* Font Noto Sans Variables */
$font-family-noto-sans: "Noto Sans";

/* https://css-tricks.com/snippets/css/system-font-stack/ */
$family-stack-noto-sans: $font-family-noto-sans, sans-serif, -apple-system, blinkmacsystemfont, "Segoe UI", roboto,
  helvetica, arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Font Filenames */
$noto-sans-light: "NotoSans-Light";
$noto-sans-light-italic: "NotoSans-LightItalic";
$noto-sans-regular: "NotoSans-Regular";
$noto-sans-italic: "NotoSans-Italic";
$noto-sans-medium: "NotoSans-Medium";
$noto-sans-medium-italic: "NotoSans-MediumItalic";
$noto-sans-semi-bold: "NotoSans-SemiBold";
$noto-sans-semi-bold-italic: "NotoSans-SemiBoldItalic";
$noto-sans-bold: "NotoSans-Bold";
$noto-sans-bold-italic: "NotoSans-BoldItalic";
$noto-sans-extra-bold: "NotoSans-ExtraBold";
$noto-sans-extra-bold-italic: "NotoSans-ExtraBoldItalic";

/* Font Weights */
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

/* Font Noto Sans JP Variables */
$font-family-noto-sans-jp: "Noto Sans JP";

/* Font Filenames */
$noto-sans-jp-light: "NotoSansJP-Light";
$noto-sans-jp-regular: "NotoSansJP-Regular";
$noto-sans-jp-thin: "NotoSansJP-Thin";
$noto-sans-jp-medium: "NotoSansJP-Medium";
$noto-sans-jp-bold: "NotoSansJP-Bold";
$noto-sans-jp-black: "NotoSansJP-Black";

$family-stack-noto-sans-jp: $font-family-noto-sans-jp, sans-serif, -apple-system, blinkmacsystemfont, "Segoe UI", roboto,
  helvetica, arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
