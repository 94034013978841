@import "src/assets/scss/variables";

.flow-condition__selected {
  & > li + li,
  li + .flow-condition__selected__error {
    margin-top: 52px;
    &::before {
      content: "AND";
      position: absolute;
      top: -40px;
      left: calc(50% - 24px);
      width: 48px;
      padding: 6px;
      border-radius: 4px;
      background: $zircon;
      color: $grey;
      text-align: center;
      font-weight: 700;
    }
  }
  li.flow-condition__selected__item {
    position: relative;
    display: block;
    background: $white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 1px 9px 1px $box-shadow;
    margin-bottom: 16px;
    cursor: pointer;
    .selected__item__header {
      position: relative;
      margin-bottom: 16px;
      .selected__item__number {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $gray;
        margin-right: 4px;
      }
      .selected__item__title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: $black;
      }
      .selected__item__delete-btn {
        color: $red;
        font-size: 14px;
        line-height: 22px;
        position: absolute;
        top: 0px;
        right: 54px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
      .selected__item__arrow {
        position: absolute;
        border-left: 1px solid $light-grey-7;
        padding-left: 16px;
        top: 0px;
        right: 0px;
        cursor: pointer;
        img {
          transition: transform 200ms;
          width: 24px;
        }
      }
    }
    &.expanded {
      .selected__item__header {
        .selected__item__arrow img {
          transform: rotate(-180deg);
        }
      }
    }
    .selected__item__content {
      .selected__item__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      .ant-select {
        width: 100%;
      }
    }
    &.invalid {
      border: 1px solid $red;
    }
  }
}
