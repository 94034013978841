@import "src/assets/scss/variables";

.container {
  background-color: $ghost-white;
  border-radius: 8px;
  padding: 24px 24px 36px;
  width: 600px;
  margin: 0 auto 24px;
  text-align: center;
  .title {
    display: flex;
    margin-bottom: 24px;
    h3 {
      display: inline-block;
      width: auto;
      margin: 0;
    }
    .number {
      color: $dark-orange;
      &::after {
        content: " • ";
        margin-right: 6px;
      }
    }
  }
}
