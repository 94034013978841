.BillForm {
  background-color: var(--white-0);
  margin-bottom: -2.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: var(--spacing-032);
  height: calc(100vh - 13.188rem);
  border-top-left-radius: var(--size-010);
  border-top-right-radius: var(--size-010);
  overflow-y: auto;
}

.errorState {
    display: flex;
    margin: auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 14.375rem;
    gap: var(--size-004) var(--size-008);

    & > svg {
      height: 80px;
      width: 80px;
    }
    
    & > button {
      margin-top: var(--size-004);
    }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: var(--white-0);
}

.overlayIcon {
  margin-top: var(--spacing-048);
  justify-content: center;
}
