@import "../../../assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";

.spm-input__input {
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;

  &.ant-input:hover {
    border-color: $accent-blue !important;
  }

  &.ant-input:focus {
    border-color: $accent-blue !important;
    box-shadow: 0 0 0 2px $secondary-blue !important;
  }

  &.err {
    border-color: $red !important;
    box-shadow: 0 0 0 2px $red !important;
  }

  .ant-input-prefix {
    font-size: 16px;
    color: $light-gray;
    margin-right: 12px;
  }
}

.spm-input__error {
  color: $red;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 4px;
}

.rc-input-affix-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 0.75rem 1rem !important;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  transition: all 0.3s;
  display: inline-flex;
  border: 1px solid #dedede;

  &:hover,
  &:focus-within,
  &:active {
    border-color: $accent-blue;
    box-shadow: 0 0 0 2px $secondary-blue;
    border-right-width: 1px;
    outline: 0;
  }
}

.rc-input-affix-wrapper > input.rc-input {
  font-size: inherit;
  border: none;
  outline: none;
  padding: 0;

  &:focus,
  &:active {
    border: none;
    box-shadow: none !important;
  }

  min-height: 0;
}

.rc-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
  color: $black-50;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}
