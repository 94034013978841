@import "src/assets/v1.1/scss/spectrum";

.modal {
  margin: 4rem;
  padding: 1.5rem 0 !important;
  align-self: unset !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  .formContainer {
    border-top: 1px solid $black-20;
    margin-top: 1.5rem;
    flex: 1 1 auto;
    overflow: auto;

    &.multiPage {
      border-top: none;
      margin-top: 0;
    }
  }
  .footer {
    flex: 1 1 auto;
  }
}

.discardModalContainer {
  width: 18.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .discardModalIcon {
    margin: 1.875rem;
  }
  .discardModalText {
    margin-bottom: 1.875rem;
  }
}
.discardModalButton {
  border-radius: 1.875rem;
  width: 100%;
}
