@import "src/assets/v1.1/scss/spectrum";
@import "src/assets/scss/variables";

.section-empty-state {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  text-align: center;
  &__message {
    padding-top: 24px;
    font-weight: 400;
    font-size: 1.12em;
    line-height: 1.75em;
    color: $black-80;
    &--primary {
      color: $black-90;
    }
    &--with-header {
      padding: 0;
      color: $gray;
    }
  }
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: $gray;
    padding: 24px 0 8px 0;
  }
}
