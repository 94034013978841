.upload-image {
  &--drag {
    &__placeholder > span {
      border: 1px dashed #e6e7ec;
      background: #fff;
      border-radius: 8px;
      width: 100%;
      height: 100px;
      padding: 50px;
      display: flex;
      align-items: center;
      .icon {
        padding-right: 8px;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      background: none;
      width: 100%;
    }
  }
  &--select-card {
    max-width: 100px;
  }
  .loading-wrapper {
    padding: 30px;
  }
  .ant-upload-list-item-actions {
    top: 10%;
    left: 90%;
    opacity: 1;
    .anticon-plus,
    .anticon-eye {
      display: none;
    }
    .anticon-delete {
      opacity: 0;
    }
  }
  &__close-icon {
    width: 28px;
    height: 28px;
    border-radius: 12px;
    margin-left: auto;
  }
  &__camera-icon {
    width: auto;
  }
  .ant-upload-list-item-info,
  .ant-upload-list-item,
  .ant-upload-select {
    border: 0;
    border-radius: 12px;
    background-color: #f4f6f9;
  }
  .ant-upload-list-item {
    padding: 0;
    background-color: #ffffff;
  }
  .ant-upload-list-item-card-actions-btn {
    background: url("../../assets/icons/close-icon_bkg.svg");
    padding: 8px;
    width: auto;
    height: auto;
    border: none;
  }
  .ant-upload-list-picture-card-container {
    border-radius: 12px;
  }
  .ant-tooltip {
    display: none;
  }
}
