@import "src/assets/scss/variables";

.refresh-fx-modal {
  .ant-modal-content {
    border-radius: 1rem;
    padding: 0rem;
    .ant-modal-close {
      margin-top: 0.875rem;
      margin-right: 0.875rem;
      .ant-modal-close-icon {
        height: 0.875rem;
        width: 0.875rem;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem;
      text-align: center;
      .refresh-fx-modal__title {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 700;
        border-bottom: 1px solid $light-gray-2;
        width: 100%;
        padding: 1.25rem 1.5rem;
      }
      .refresh-fx-modal__content {
        padding: 1.5rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        .icon {
          width: 6.25rem;
        }
        .desc {
          margin-bottom: 1.5rem;
        }
        .refresh-fx-modal__action {
          display: flex;
          gap: 0.5rem;
          .action {
            border-radius: 1.875rem;
            background: $orange;
            color: $white;
            padding: 1rem 1.5rem;
            height: auto;
            width: 100%;
            margin-top: 1.5rem;
          }
          .close {
            border-radius: 1.875rem;
            border: 1px solid $orange;
            background: $white;
            color: $orange;
            padding: 1rem 1.5rem;
            height: auto;
            width: 100%;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
  .icon {
    cursor: default;
    margin-bottom: 1rem;
  }
}
