@import "./assets/scss/fonts.scss";
@import "./assets/v1.1/scss/spectrum.scss";

body {
  font-family: $font-family-stack;
  font-size: 14px;
}

.disclaimer {
  font-size: 0.8em;
  color: #ccc;
  display: block;
}

.ant-input-number,
.ant-input,
input {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  height: auto;
  -webkit-appearance: none;

  &:hover {
    border-color: #5ab3f3;
  }

  &::placeholder {
    color: #e8e8e8;
  }

  &:focus {
    border-color: #5ab3f3;
    box-shadow: 0 0 0 2px #cdeeff;
  }
}

.ant-input,
input {
  padding: 8px;
}

.ant-input-number-input {
  padding: 20px 8px;
}

.ant-spin-dot-item {
  background-color: #ff8240;
}

/* antd custom drop down styles */

.ant-btn {
  position: static;
}

.ant-select-single .ant-select-selector .ant-select-selection-item:after {
  display: none;
}

.ant-select-selection-item,
.ant-select-item-option-content {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.ant-select {
  font-size: 14px;
}

.ant-select-item,
.ant-select-item-empty {
  font-size: 16px;
  line-height: 20px;
  color: #666b79;
}

.ant-select-selection-item:focus,
.ant-select:focus,
.ant-select-selection-search:focus .ant-select-selector:focus {
  outline: none;
}

.drop-down--dark .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.drop-down__team .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  outline: none;
  box-shadow: none;
}

.ant-select-clear {
  right: 15px;
}

.ant-select-arrow {
  margin-right: 8px;
  color: #131a2e;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f8f8f8;
  color: #f65731;
  font-weight: 500;
}

.ant-form-item-label > label {
  font-weight: 600;
  line-height: 1.25em;
  color: #666b79;
  margin-top: 22px;
}

// antd tab styles

.ant-tabs-nav .ant-tabs-tab {
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25em;
  padding: 10px;
}

/* antd date picker custom styles */
.ant-picker-input > input {
  font-size: 16px;
  line-height: 24px;
  color: #666b79;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #f65731;
}

.ant-tabs-top .ant-tabs-ink-bar-animated {
  background-color: #f65731;
}

.anticon-calendar,
.ant-picker-content th,
.ant-picker-header button,
.ant-picker-cell-in-view {
  color: #131a2e;
  font-size: 14px;
}

.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn,
.ant-picker-footer {
  display: none;
}

.ant-picker-panel-container {
  padding: 15px 8px;
}

.ant-picker-header {
  border-bottom: 1px solid #f8faff;
}

.ant-picker-header-view {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: 1px solid #f65731;
  border-radius: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #f65731;
}

.ant-picker-panel-container .ant-picker-panel {
  border: none;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 26px;
  height: 26px;
  line-height: 26px;
}

/* antd table custom styles */
.ant-table {
  font-family: "Spenmo", sans-serif;
  font-size: 14px;
  line-height: 22px;
  border-radius: 6px;
  color: #333744;
}

.ant-table .ant-table-thead > tr > th {
  font-weight: 600 !important;
  line-height: 22px;
  color: #666b79;
}

.ant-table .ant-table-thead > tr > th {
  background: #fff;
}

.ant-table .ant-table-thead > tr > th {
  padding: 14px 30px;
}

.ant-table .ant-table-tbody > tr > td {
  font-weight: 400 !important;
  padding: 24px 30px;
  border-bottom: none;
}

.ant-table .ant-table-tbody > tr:nth-child(even) {
  background: #ffffff;
}

.ant-table tbody > tr:nth-child(odd) {
  background: #f8f8f8;
}

/* antd custom pagination styles */

.ant-table-pagination.ant-pagination {
  width: 100%;
  text-align: end;
  padding: 16px 45px;
  background: #fff;
  border-radius: 0 0 6px 6px;
  margin: 0;
}

.ant-table-pagination.ant-pagination .ant-pagination-item-link {
  border: none;
  font-size: 14px;
}

.ant-table-pagination.ant-pagination .ant-pagination-item {
  border: none;
  font-family: $font-family-stack;
}

.ant-table-pagination.ant-pagination .ant-pagination-item-active a {
  color: #f65731;
  font-size: 14px;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: #fff;
}

.ant-form .ant-form-item-label > label {
  display: block;
  font-size: 16px;
  color: #8a8b91;
}

.ant-form-item {
  display: block;
}

.ant-tag {
  padding: 4px 12px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 24px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid rgb(217, 217, 217);
  background: transparent;
  max-width: 100%;
  border-radius: 5px;
  padding: 8px 16px;

  .section-options {
    &__title {
      font-weight: 600;
      line-height: 24px;
      color: #131a2e;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-selector {
  height: auto;
  box-shadow: none;
}

.ant-picker {
  padding: 9px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
}

.ant-select-selection-search,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
  height: 100%;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #ff8240;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #f65731;
}

.ant-checkbox-checked:after,
.ant-checkbox-checked .ant-checkbox-inner {
  border: #f65731;
}

.ant-tabs-top .ant-tabs-ink-bar-animated {
  bottom: -1px;
}

.modal-download-report {
  padding: 30px;
  padding-bottom: 0;

  p {
    font-size: 16px;
    margin-bottom: 8px;
  }

  input {
    margin-top: 16px;
  }

  .text-light {
    font-weight: 300;
  }

  strong {
    color: #333744;
  }

  .button {
    background: #1fce8f;
    width: 75%;
  }

  .modal-download-report-info {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 24px;

    p {
      color: #9599a4;
      font-size: 12px;
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  .modal-download-report-button {
    display: flex;
    justify-content: center;
  }
}

.ant-pagination-item-active {
  border: none;

  a,
  a:hover {
    color: #f65731;
  }
}

.ant-radio-wrapper {
  span:nth-child(2) {
    color: $black-90;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .ant-radio {
    &.ant-radio-checked .ant-radio-inner::after {
      background-color: $brand;
    }
  }
}

.ant-modal-mask,
.ant-modal.no-animation {
  transform: none !important;
  transition: none !important;
  animation-duration: 0ms !important;
}

strong {
  font-weight: bolder;
}

.v-stack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
