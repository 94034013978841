@import "src/assets/v1.1/scss/spectrum.scss";

.drop-down {
  display: inline-flex;
  align-items: center;
  .section-select {
    min-width: 230px;

    // this is to fix glitch on dropdown when focused
    > span:first-of-type:not(.ant-select-arrow) {
      display: none !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    outline: none;
    box-shadow: none;
  }
  .section-options {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #666b79;
    }
    &__team-title,
    &__amount {
      font-size: 14px;
      line-height: 18px;
      color: #9599a4;
      margin-right: 24px;
      margin-left: auto;
    }
    &__content {
      display: grid;
      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 2rem;
        color: $black-100;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__subtitle {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
        color: $black-70;
      }
    }
  }
  .ant-select-selection-item {
    .section-options {
      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__title {
          max-width: 14rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__amount {
        display: none;
      }
    }
  }
  &__team {
    .section-select {
      .ant-select-selector {
        background-color: transparent;
      }
    }
  }
  // dropdown with background
  &__team {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }
  &--dark {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background: #f4f6f9;
      border-radius: 6px;
      min-height: 48px;
      border: none;
    }
  }
  &__select-wallet {
    .section-select {
      .ant-select-selector {
        border: 1px solid #e6e7ec;
        min-width: 33;
        min-width: 384px;
        border-radius: 4px;
        padding: 14px 16px;
        .section-options {
          &__title {
            font-weight: 600;
            line-height: 24px;
            color: #131a2e;
          }
        }
      }
    }
  }
  .ant-select-selection-placeholder {
    font-size: 16px;
  }
  &--link {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: #9599a4;
    text-decoration: none;
    &:hover {
      color: #f65731;
    }
  }
  &--full-size {
    .section-select {
      width: 100%;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 10px;
      border-radius: 4px;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    padding: 0.5rem 1rem;
    &:hover {
      background: $blue-20;
    }
    .section-options__title {
      margin-left: 8px;
    }
    .section-options__team-title {
      margin-left: auto;
      margin-right: 45px;
    }
  }
}
