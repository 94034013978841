@import url("/assets/scss/variables.scss");

.mobile-qr-code-banner {
  background-image: url("../../assets/img/orange-yellow-gradient-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  flex-direction: column;
  .qr-code {
    width: 276px;
    height: 336px;
    background: #fff;
    border-radius: 17px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .spenmo-icon {
      position: absolute;
      bottom: 84%;
    }
    .mobile-qr-code,
    .mobile-qr-code img {
      width: 205px;
      height: 205px;
      margin: 8px 0;
    }
    .mobile-qr-code img {
      margin: 8px 0;
    }
    .qr-code {
      margin-top: 56px;
    }
    .mobile-app-stores-imgs {
      display: flex;
      padding-top: 12px;
      width: 90%;
      justify-content: space-evenly;
    }
  }
  &__desc {
    text-align: center;
    padding-top: 24px;
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    color: #131a2e;
    width: 392px;
  }
  &__desc span {
    color: #ff5633;
    font-weight: bold;
  }
}
