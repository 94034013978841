@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.item {
  @include card-shadow;
  position: relative;
  border-radius: 16px;
  width: 100%;
  padding: 20px 34px 20px 40px;
  display: flex;
  user-select: none;

  &:not(:first-child) {
    margin-top: 24px;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .content {
      color: $black;

      &.flex1 {
        flex: 1 1;
      }

      .titleContainer {
        display: flex;
        align-items: center;
      }

      .title,
      .subtitle {
        margin-bottom: 0 !important;
        padding-left: 24px;
        line-height: 1.875rem;
      }
      .title {
        display: flex;
        align-items: center;
        flex: 1 1;
        font-weight: 400;

        :global(.ant-typography-copy) {
          position: absolute;
          right: 34px;
        }
      }

      .subtitle {
        font-weight: 200;
      }
    }
    .bills-email-icon {
      margin-bottom: 15px;
    }
  }

  .icon {
    width: 48px;

    :global(.icon) {
      margin: 0 auto;
    }
  }
}
