@import "../../assets/scss/variables";

.clipboard {
  display: flex;

  &__btn {
    margin-right: 8px;
  }

  &__copied {
    color: $green;
  }
}
