.formField {
  position: relative;

  + .formField {
    margin-top: var(--spacing-024);
  }

  &Help {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.6;
  }

  :global {
    .ant-select {
      width: 100%;
    }

    .ant-picker {
      width: 100%;
    }
  }

  &.indent1 {
    margin-left: var(--spacing-016);

    :global(.ant-checkbox-wrapper) + .formFieldHelp {
      margin-left: 1.375rem;
    }
  }

  &.indent2 {
    margin-left: 2.375rem;
  }
}

.formLabel {
  margin-bottom: var(--spacing-008);
  display: flex;
  align-items: center;
  column-gap: var(--spacing-004);

  > label {
    margin-bottom: 0;

    span {
      color: var(--black-015);
      font-weight: normal;
    }
  }

  :global {
    // modifying tooltip from splice
    .sp-tooltip__trigger {
      padding: 0;
      line-height: 1rem;
      height: 1rem;
    }
  }
}

.tooltip {
  max-width: 25rem;
}

.formFieldError {
  margin-top: var(--spacing-002);
  color: var(--red-050);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-002);

  .fieldErrorIcon {
    min-width: var(--size-008);
    line-height: var(--size-010);
    padding: var(--spacing-002) 0;
    box-sizing: content-box;
  }
}

.formGroup {
  display: flex;
  column-gap: var(--spacing-008);

  .formField {
    margin-top: 0;
  }

  > * {
    flex: 1 1;
  }

  /**
  * col 1-24 for the grid system
  */
  @for $i from 1 through 24 {
    > .col#{$i} {
      flex: 0 0 calc(100% / 24 * $i);
    }
  }
}

.boldText {
  font-weight: var(--font-weight-6);
  color: #131a2e; // old color
}

.notFound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1.625rem 0;
  margin: 0 -0.5rem;
  font-size: 0.875rem;

  img {
    width: 3.75rem;
  }

  &Text {
    max-width: calc(100% - 4rem);
    text-align: center;
  }
}

.newSelectButton {
  border: 0;
  font-size: 0.875rem;
  width: 100%;
  text-align: left;
  box-shadow: none;
  height: 2.875rem;
  color: var(--blue-050);
  cursor: pointer;
  padding-left: var(--spacing-024);
  margin-bottom: -4px;
  border-top: 1px solid var(--white-060);
  border-radius: 0;

  &:hover {
    border-color: var(--white-060);
  }

  &:disabled {
    color: var(--black-050);
    background-color: var(--white-0);
    cursor: not-allowed;
  }
}

.selectOptionMultilines {
  :global {
    .ant-select-item-option-content {
      flex-wrap: wrap;
      & > div:first-child {
        font-size: 0.875rem;
        width: 100%;
      }

      & > div:last-child {
        font-size: 0.625rem;
      }
    }
  }
}

.textArea textarea {
  padding: var(--spacing-012) var(--spacing-016);
}

.textCounter {
  text-align: right;
  margin-top: var(--spacing-008);
  color: var(--text-caption-default);
}

// error border styling
.error {
  :global {
    .ant-select .ant-select-selector,
    .ant-input,
    .ant-picker {
      border-color: var(--red-050);
    }
  }
}

// Bank Select
.highlight {
  color: var(--text-body-brand);
  font-weight: var(--font-weight-6);
}

// normalize the form field styles
// IMPROVEMENT: should be deleted after we use all field from Splice
.formField {
  :global {
    // SELECT
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: var(--spacing-012) var(--spacing-016);
      border-radius: var(--radius-m);

      input {
        padding: var(--spacing-012) var(--spacing-032) var(--spacing-012) var(--spacing-016);
      }
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-size: var(--size-008);
      line-height: var(--size-012);
    }

    .ant-select-selection-placeholder {
      font-size: var(--size-008);
      line-height: var(--size-012);
      color: var(--white-070);
      opacity: 1;
    }

    .country-select .country-select__option,
    .country-select__dropdown .country-select__option {
      overflow: hidden;

      .country-select__flag {
        min-width: 18px; // overwrite antd style
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-select-selection-search {
      z-index: 1;
      overflow: hidden;
    }

    // Multiple Select
    .ant-select.tag-input {
      .ant-select-selector {
        padding: var(--spacing-012) var(--spacing-016);
        border-radius: var(--radius-m);
      }

      .ant-select-selection-search {
        margin: 0;
        height: var(--size-012);
        font-size: var(--size-008);
        line-height: var(--size-012);

        .ant-select-selection-search-input {
          margin: 0;
          font-size: var(--size-008);
          line-height: var(--size-012);
        }
      }

      .ant-select-selection-search-mirror {
        font-size: var(--size-008);
        line-height: var(--size-012);
      }

      .ant-select-selection-item .ant-select-selection-item-content {
        font-size: 0.875rem;
        line-height: var(--size-010);
        padding: 0 var(--spacing-012);
      }

      .ant-select-selection-placeholder {
        left: var(--spacing-016);
      }

      .ant-select-selector:after {
        margin: 0;
        line-height: var(--size-012);
      }
    }

    // INPUT
    .ant-input,
    .ant-input-affix-wrapper {
      padding: var(--spacing-012) var(--spacing-016);
      border-radius: var(--radius-m);
      font-size: var(--size-008);
      line-height: var(--size-012);

      &::placeholder {
        font-size: var(--size-008);
        line-height: var(--size-012);
        color: var(--white-070);
      }
    }

    // SPM Input (Custom component)
    .spm-input .ant-input {
      border-radius: var(--radius-m);
    }

    // DATE
    .ant-picker {
      padding: var(--spacing-012) var(--spacing-016);
      border-radius: var(--radius-m);

      .ant-picker-suffix {
        line-height: var(--size-012);
      }

      .ant-picker-input input::placeholder {
        font-size: var(--size-008);
        line-height: var(--size-012);
        color: var(--white-070);
      }
    }
  }
}
