@import "src/assets/v1.1/scss/spectrum";

$gap: 1rem;

.vendorSection {
  .sectionHeader {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 1rem -2rem 0;
    padding: 1.5rem 2rem;
    border-top: 1px solid $black-40;
  }

  .currencyAndCountry {
    display: flex;
    align-items: center;
    gap: $gap;

    & > div {
      // currency field
      &:first-child {
        width: 100%;
      }

      // country field
      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  .selectVendor {
    &Help {
      padding: 0 1rem;
      margin: 0.5rem -1rem 0 -1rem;
      font-size: 0.75rem;
      color: $black-70;
    }

    &Separator {
      margin: 1rem -1rem 0 -1rem;
      height: 1px;
      background-color: $black-40;
    }

    :global {
      .ant-form-item-label label {
        margin-top: 0;
      }
    }
  }

  .clearIcon {
    height: 0.75rem;
    width: 0.75rem;
    cursor: pointer;
  }

  .addNewBanner {
    padding-top: 0.5rem;
    color: $black-70;
  }

  .draftVendorBanner {
    .title {
      font-weight: 600;
      size: 1rem;
      line-height: 1.5rem;
    }
  }

  :global {
    .country-select {
      margin-bottom: 0 !important;
    }

    .ant-form-item-optional {
      color: $black-50;
      margin-left: 0.25rem;
    }

    .ant-form-item-explain-error {
      padding-top: 0.5rem !important;
    }

    .ant-form-item-required-mark-optional,
    .ant-form-item-required {
      &:before {
        display: none !important;
      }

      .ant-form-item-tooltip {
        display: inline-flex;
        margin-left: 0.25rem;
      }
    }
  }
}

.dropdown {
  .divider {
    margin: 0 !important;
  }

  .newVendorButton {
    border: 0;
    font-size: 0.875rem;
    width: 100%;
    text-align: left;
    box-shadow: none;
    height: 2.875rem;
    color: $blue-80;
    cursor: pointer;
    padding-left: 1.5rem;
    margin-bottom: -4px;

    &:disabled {
      color: $black-50;
      background-color: $white;
      cursor: not-allowed;
    }
  }

  .notFound {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1.625rem 0;
    margin: 0 -0.5rem;
    border-bottom: 1px solid $black-30;
    font-size: 0.875rem;

    img {
      width: 3.75rem;
    }

    &Text {
      max-width: calc(100% - 4rem);
      text-align: center;
    }
  }

  :global {
    .ant-select-item-empty {
      padding: 0;
    }
  }
}
