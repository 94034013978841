@import "src/assets/v1.1/scss/spectrum";

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  background: transparent;
  width: 100%;
  min-height: 48px;
  padding: 1rem;

  img {
    align-self: flex-start;
  }

  &:hover {
    .title {
      color: $red-80;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  align-items: flex-start;
  justify-content: center;
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $black-100;
}

.subtitle {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: var(--text-body-default);
  text-align: left;
}
