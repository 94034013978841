@import "src/assets/v1.1/scss/spectrum";
@import "src/Modules/DS/Search/SearchMixin.module.scss";

$height: 1.563rem;

.container {
  position: relative;
}

.content {
  background: $white;

  border: 0.063rem solid $black-40;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  align-content: center;

  cursor: pointer;

  &.active,
  &:focus-within {
    outline: $blue-80 solid 0.063rem;
  }

  & > .chevron {
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: $black-90;

    mask: url("../../../../../assets/v1.1/icons/Navigation/Chevron/down_16.svg");
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;

    transition: transform 200ms cubic-bezier(0.19, 1, 0.22, 1), mask 150ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  & > .up__chevron {
    transform: rotateX(180deg);
  }

  & > .search__icon {
    mask: url("../../../../../assets/v1.1/icons/Core/Search/line_24.svg");
  }

  @include loader(1.5rem, 0.125rem);
  & > .loading {
    mask: none;
    background-color: transparent;
  }

  & > .label {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $black-50;

    height: $height;

    width: calc(100% - 1.5rem);

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;

    & > input[type="search"] {
      border: none;
      padding: 0;
      width: calc(100% - 1.5rem);
      transition: width 200ms;
      border-radius: 0;

      font-weight: 400;
      color: $black-80;

      font-size: 1rem;
      line-height: $height;

      &::placeholder {
        font-weight: 400;
        color: $black-50;

        font-size: 1rem;
        line-height: 1.5rem;
      }

      &:focus-visible {
        outline: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.spm-dropdown-label {
  max-width: calc(100% - 0.5rem);

  & > span {
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 1rem);
  }
}

/*
  when there are no elements in popover,
  popover element adds a default padding & box shadow
  since custom class and popover class are applied on same tag
  there might be slight chance that padding of popover getting applied
  in order to ensure zero padding & box shadow in case of no elements
  we have used the !important keyword.
*/
.popoverWithoutPadding {
  padding: 0 !important;
  box-shadow: none !important;
}
