@import "src/assets/scss/variables.scss";
@import "src/assets/v1.1/scss/spectrum.scss";

.container {
  display: flex;
  flex-flow: row nowrap;

  width: 100%;

  .subContainer {
    display: flex;
    flex-flow: column nowrap;

    .bar {
      height: 20px;
      &:hover {
        cursor: pointer;
      }
    }

    .content {
      white-space: nowrap;

      font-weight: 400;
      font-size: 10px;
      line-height: 1.6;
      color: $black-80;
      text-align: left;
    }

    &:first-child {
      & > .bar {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      & > .bar {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}

.categoryCard {
  display: flex;
  width: fit-content;
  & div:first-child {
    width: 4px;
    margin-right: 8px;
    border-radius: 8px;
  }
  .amount {
    font-weight: 600;
    color: #333744;
  }
}

.otherCategory {
  display: flex;
  width: fit-content;
  & div:first-child {
    width: 1px;
    margin-right: 8px;
  }
  .amount {
    font-weight: 600;
    color: #333744;
  }
}
