@import "src/assets/v1.1/scss/spectrum.scss";

@mixin colorStyles {
  line-height: 150%;
  color: $black-80;
}

@mixin textStyle {
  font-weight: 400;
  font-size: 1rem;
  padding-top: 1.5rem;

  @include colorStyles;
}

.content {
  padding: 2.5625rem 4rem;
  max-width: 57.5rem;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;

  @include colorStyles;
}

.text {
  @include textStyle;
}
