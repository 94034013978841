@import "src/assets/v1.1/scss/spectrum";

.container {
  height: 17rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  & > .text {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 2rem;
    color: $black-90;
  }
}
