.title {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: var(--spacing-008);

  h2 {
    margin-bottom: var(--spacing-004); // add additional spacing to be align with button height
  }
}

.form,
.upsellingBanner {
  padding: var(--spacing-024) 0;
}

.formFooter {
  display: flex;
  column-gap: var(--spacing-004);
  justify-content: flex-end;
}

.field {
  width: var(--size-160);
}

.space8 {
  margin-top: var(--spacing-008);
}

.space12 {
  margin-top: var(--spacing-012);
}

.weekdaysContainer {
  display: flex;
  column-gap: var(--spacing-008);
}

.weekdaysBtn {
  border-radius: 50%;
  background-color: var(--fill-brand-navy);
  padding: var(--spacing-008);
  min-width: var(--size-016);
  text-align: center;
  transition: all 300ms ease;

  &.active {
    background-color: var(--dark-navy-050);
    color: var(--text-body-default-inverse);
  }
}
