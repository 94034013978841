.employee-otp {
  &__spenmo-logo {
    margin: auto;
    padding-bottom: 32px;
    img {
      height: 42px;
    }
  }
  &__loader {
    padding: 7rem 6rem;
  }
}

.employee-otp--wrapper .login-screen-wrapper__container {
  min-width: 33.75rem;
}
