.bank-listing {
  .ant-table tbody > tr {
    background: #fff;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fdfdfc;
  }
  .side-section {
    height: 92vh;
    position: absolute;
    right: 0;
    width: 460px;
    bottom: 0;
    height: -webkit-fill-available;
    background: #333744;
  }
}
