@import "src/assets/scss/variables.scss";

@mixin shimmer-grey {
  background-color: $light-grey-2;
}

.expense-card {
  &__container {
    height: 158px;
    background: #ffffff;
    box-shadow: 6px 6px 16px rgba(214, 212, 212, 0.5), -6px -6px 16px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    border: none;
    margin-right: 32px;
    &--with-progress {
      width: 476px;
    }
    &--limit-model {
      min-width: 382px;
    }
    &--wallet-model {
      height: 138px !important;
    }
    .ant-skeleton-element {
      display: inline-flex;
      .title {
        width: 79px;
        height: 10px;
        border-radius: 12px;
        background: linear-gradient(90deg, $yellow-1 25%, $yellow-2 37%, $yellow-1 63%);
      }
      .expense-title {
        width: 88px;
        height: 8px;
        border-radius: 12px;
        @include shimmer-grey;
        margin-bottom: 16px;
      }
      .expense {
        width: 112px;
        height: 12px;
        border-radius: 12px;
        @include shimmer-grey;
      }
    }
  }
  &__shimmer-container {
    width: 144px;
  }
  &__team-name {
    background: #fff8e7;
    padding: 12px 24px;
    color: $dark-gray-2;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    border-radius: 20px 0px;
    display: inline-block;
    margin-right: 32px;
  }
  &__expense-details-container {
    padding: 24px;
    display: flex;
    flex-direction: row;
  }
  &__expense-detail-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 4px;
    display: inline-flex;
    img {
      margin-left: 6px;
    }
    &--green {
      color: $green;
      &:hover {
        color: $green;
      }
    }
    &--blue {
      color: $blue;
      &:hover {
        color: $blue;
      }
    }
    &--gray {
      color: $gray;
    }
  }
  &__expense {
    font-weight: 900;
    font-size: 16px;
    line-height: 28px;
    color: $dark-gray-2;
    padding-bottom: 4px;
    &--na {
      font-weight: 900;
      font-size: 16px;
      line-height: 28px;
      color: $light-gray;
    }
  }
  &__expense-detail-validity {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: $gray;
    opacity: 0.6;
  }
  &__progress-bar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: $green;
    margin-left: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: $white;
      position: absolute;
    }
  }
  &__progress-bar-inner-text {
    position: relative;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75rem;
    color: $blue;
    letter-spacing: -0.35px;
    &__red {
      color: $red;
    }
  }
}
