@import "src/assets/v1.1/scss/spectrum";

.syncAccount {
  margin: 0px auto;
  .ant-form {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .fullWidth {
    width: 100%;
  }

  .syncCta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      max-width: 18rem;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        margin-left: 0.75rem;
      }
    }
  }

  .informationWrapper {
    padding: 1.5rem 0;
    display: grid;
    gap: 0.5rem;
  }

  .informationBox {
    background-color: $white;
    border-radius: 0.625rem;
    padding: 1rem 2rem;
    box-shadow: 0px 1px 4px rgba($black-50, 0.45);
    color: $black-100;
    font-size: 0.875rem;
  }
}
