@import "src/assets/v1.1/scss/spectrum";

.cellWith {
  white-space: pre-line;

  &Subtitle {
    & p {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: left;
      color: $black-70;

      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    & h5 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: $black-90;

      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &Icon {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;

    background-color: $black-20;
    display: inline-flex;

    &.hover:hover {
      cursor: pointer;
    }

    &.disabled.hover:hover {
      cursor: not-allowed;
    }

    &.disabled {
      opacity: 0.5;
    }

    & > img.icon {
      margin: auto;

      display: inline-flex;
      width: 1.5rem;
      height: 1.5rem;

      mask-size: cover;
      -webkit-mask-size: cover;
      mask-position: center;
      -webkit-mask-position: center;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
    }
  }
}
