@import "src/assets/scss/variables";

.page-leave-container {
  width: 441px;
  height: 237px;

  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-close {
      top: 18px;
      right: 16px;

      svg {
        width: 20px;
        height: 20px;
        color: $dark-gray;
      }
    }
  }

  .page-leave-container__body {
    padding: 31px 24px 28px;
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    .page-leave-container__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  .page-leave-container__content {
    margin-top: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .page-leave-container__actions {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;

    .page-leave-container__actions__button {
      padding: 20px 50px;
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      font-size: 14px;
      text-align: center;
      border: none;
      display: flex;
      align-items: center;
      box-shadow: none;

      &.page-leave-container__actions__button--cancel {
        background-color: $orange;
        color: $white;
      }

      &.page-leave-container__actions__button--ok {
        color: $red;
      }
    }
  }

  .ant-modal-header {
    border-bottom: 0;
    background: none;

    .ant-modal-title {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
