@import "src/assets/scss/variables";

.approval-amount-condition {
  .spm-dropdown {
    margin-bottom: 16px;
  }

  .spm-input {
    margin-top: 8px;
    position: relative;
  }

  .spm-input + .spm-input {
    margin-top: 52px;
    &::before {
      content: "TO";
      position: absolute;
      top: -40px;
      left: calc(50% - 24px);
      width: 48px;
      padding: 6px;
      border-radius: 4px;
      background: $zircon;
      color: $grey;
      text-align: center;
      font-weight: 700;
    }
  }
}
