@import "src/assets/v1.1/scss/spectrum.scss";

.popout {
  margin-left: 8px;
}

.popout__button {
  padding: 8px 16px;
  white-space: nowrap;
  height: 48px;
  border-radius: 8px;
  background: none;
  color: $black-100;
  font-weight: 400;
  &:hover,
  &:focus {
    border: 1px solid $blue-80;
    background: none;
    color: $black-100;
  }
}

.popout__button--on {
  border: 1px solid $blue-80;
}

.popout {
  margin-left: 8px;
  &__content {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    height: 40px;
    border: 1px solid $black-20;
    border-radius: 8px;
    color: $black-100;
    font-weight: 400;
    transition: width 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
    &.ant-popover-open,
    &--on,
    &:hover,
    &:focus {
      border: 1px solid $blue-80;
      background: none;
      color: $black-100;
    }
  }
  &__limit-text {
    display: inline-block;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.375rem;
  }
  &__capitalize {
    text-transform: capitalize;
  }
  &__note-text {
    color: $black-50;
  }
  &__clear-icon {
    display: flex;
    padding-left: 0.625rem;
  }
  &__checkbox-group {
    padding-top: 26px;
    padding-left: 20px;
    .ant-radio-input {
      width: 24px;
      height: 24px;
    }
    .ant-radio-wrapper {
      font-size: 14px;
      color: $black-80;
      padding-bottom: 24px;
      &:last-child {
        padding-bottom: 10px;
      }
      span.ant-radio + * {
        padding-left: 16px;
      }
    }
  }
}
