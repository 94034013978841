@import "src/assets/v1.1/scss/spectrum";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 0.5rem 0.75rem;

  background: $white;
  border: 0.063rem solid $black-40;
  border-radius: 0.5rem;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $black-80;

  &:hover,
  &.active {
    outline: $blue-80 solid 0.063rem;
    cursor: pointer;
  }

  &.selected {
    font-weight: 600;

    & > .close {
      background: $blue-80;
      width: 1rem;
      visibility: visible;
      margin-left: 0.5rem;
    }
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;

    max-width: 9.6rem;
  }

  & > .close {
    padding: 0;
    width: 0;
    height: 1rem;

    border: none;
    visibility: hidden;
    background: transparent;

    transition: width 400ms, visibility 200ms;

    cursor: pointer;
    pointer-events: visiblePainted;

    mask: url("../../../../assets/v1.1/icons/Navigation/Cross/fill_16.svg");
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-position: center;
    -webkit-mask-position: center;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
  }
}
