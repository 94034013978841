@import "src/Modules/DS/Search/SearchMixin.module.scss";
@import "src/assets/v1.1/scss/spectrum";

.search__bar {
  @include searchBase(0.5rem 0.75rem 0.5rem 0.75rem);
  @include closeIcon(1rem);
  @include searchIcon(1rem);
  @include loader(1rem, 0.1rem);
  @include searchInput(0.875rem, 1.25rem);

  width: 20.25rem;

  background: $white;
  border: 0.063rem solid $black-40;
  border-radius: 0.5rem;

  margin-right: 0.5rem;

  &:focus-within {
    outline: $blue-80 solid 0.063rem;
  }
}
