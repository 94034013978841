.request-money {
  &-btn {
    border: 1px solid #ececec;
    margin: 0 10px;
  }
  //&-modal {
  //  &__action-btns {
  //    display: flex;
  //    justify-content: space-between;
  //    padding: 46px;
  //    min-width: 350px;
  //  }
  //}
}
