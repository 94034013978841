@import "src/Splice/scss/spectrum";
@import "src/assets/v1.1/scss/spectrum.scss";
@import "src/assets/scss/mixins.scss";

.default-actions {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  :global(.ant-btn) {
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 2.5rem;
    line-height: 1.25rem;
    height: auto;
    padding: 0.5rem 1.875rem;
    min-width: 12rem;
  }

  &__button {
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: 2.5rem;

    &--primary:global(.ant-btn) {
      background-color: $brand;
      color: $white;
      border: 1px solid $brand;
      svg {
        fill: $white;
      }
    }

    &--disabled:global(.ant-btn) {
      background-color: $black-50;
      pointer-events: none;
      color: $black-0;

      @include button-variant($black-50, $white);
      cursor: not-allowed;

      &:hover {
        background-color: $black-20;
        color: $black-50;
      }
    }

    &--secondary:global(.ant-btn) {
      background: $brand-20;
      color: $brand;
      border: 1px solid $brand-20;
      svg {
        fill: $brand;
      }
    }
  }
}
