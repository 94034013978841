$primary: #ff5633;
$white: #fff;
$white-10: #fbfdff;
$white-1: #f5f5f5;
$white-2: #fbfdff;
$light-white: #f7f7f9;
$white-smoke: #f8f8f8;
$light-white-1: #eef2f5;
$light-white-2: #f9f9f9;
$alice-blue: #e5f4ff;
$solitude: #e6e7ec;

$grey: #ccc;
$grey-1: #333333;
$athens-grey: #e4e8ed;
$dark-grey: #686c76;
$neutral-black: #9599a4;

$gray: #666b79;
$light-gray: #c5c7cd;
$light-gray-1: #c6c6c6;
$light-gray-2: #f4f6f9;
$light-gray-3: #bbbcc0;
$light-gray-4: #eaeaea;
$light-gray-5: #e4e5e7;
$dark-gray-3: #666b79;
$light-grey-4: #f6f6f6;
$light-grey-5: #8b8b8b;
$light-grey-6: #b9b9b9;
$light-grey-7: #e6e9ee;
$light-grey-8: #dadde4;
$light-grey-9: #8a8b90;
$light-grey-10: #8a8b91;

$dark-gray-3: #666b79;

$dark-gray: #131a2e;
$dark-gray-1: #535766;
$dark-gray-2: #333744;
$dark-gray-4: #141b2f;
$dark-gray-5: #676b78;
$dark-gray-6: #ececec;

$red: #e5304c;
$red-1: #df1d0b;
$red-2: #dd1c0b;
$red-3: #fdd2ce;
$red-4: #fff5f6;
$dark-yellow: #e5304c;
$yellow: #f2c94c;
$yellow-1: #fcf1c5;
$yellow-2: #fcf0c5;

$green: #1fce8f;
$purple: #9b51e0;
$aqua: #e0ffff;
$black: #131a2e;
$pure-black: #000000;
$orange: #f65731;
$orange-1: #ff7917;
$orange-2: #ff8240;
$orange-3: #f66031;
$blue: #1e97ee;
$blue-1: #5ab3f3;
$black-1: #000000;
$black-2: #13162e;
$red-9: #dd1c0b;
$red-10: #df1d0b;

$light-orange: #f79e1b;
$light-orange-2: #ff5633;

$dark-green: #00a446;
$green: #62cb94;
$green-1: #a6f2d7;
$green-2: #e9fcf5;
$green-3: #0d8258;

$silver: #b9b9b9;
$dark-grey: #131a2e;
$dark-grey-1: #868894;
$dark-grey-2: #333744;
$dark-grey-3: #333547;
$dark-gray-4: #141b2f;
$grey: #666b79;
$light-grey: #c5c7cd;
$light-grey-2: #f4f6f9;

$zircon: #f8faff;
$peach: #fff5ed;
$border-color: #d9d9d9;

$active-bg: #b0f3db;
$inactive-bg: #fff1f3;
$selected-row: #fff5ed;

$opaque-shadow: #d9e3f9;
$box-shadow: #c7c7c733;
$flamingo: #eb5757;

$border-radius: 8px;
$dark-orange: #f13f26;
$light-blue: #e6f4ff;
$light-blue-1: #f5f8fd;
$light-blue-2: #cdeeff;
$invalid-form: #ff4d4f;
$invalid-form-light: #ffedf0;
$ghost-white: #f6f9fe;
$hovered-row: #fafafa;
$error-warning: #f65732;

$spectrum-blue-1: #084672;
$spectrum-blue-20: #e5f4ff;
$secondary-blue: #cdeeff;
$accent-blue: #5ab3f3;
$border-grey: #e4e5e7;
$inactive-grey: #b6b6bb;
$secondary-green: #0d8258;
$body: #535766;

$third-green: #a6f2d7;
$light-green: #e9fcf5;
$salmon: #f9cbbf;
$ghost-white-2: #f5f8fd;
$link: #5ab3f3;
